import { useReducer, createContext, useContext } from "react";
import firestoreDb from "../initializeFirebase";
import { collection, getDocs } from "firebase/firestore";
import _ from "lodash";
import fetchDataAsync from "#utils/fetch-data-async";
import { GET_INTEGRATION_PROVIDERS } from "../queries";
const initialState = {};
const MasterDataContext = createContext({});
import { AppStateContext } from "#contexts/appState";

function masterDataReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "SET_MASTER_DATA": {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
}

function MasterDataProvider(props) {
  const appState = useContext(AppStateContext);
  const [state, dispatch] = useReducer(masterDataReducer, initialState);

  function fetchMasterData() {
    Promise.all([
      fetchHopstackModules,
      fetchBinLocationTypes,
      fetchRoles,
      fetchSortingLocationTypes,
      fetchToteTypes,
      fetchDeviceTypes,
      fetchIntegrationProviders(state),
      fetchInboundVariables,
      fetchOutboundVariables,
      fetchInboundResults,
      fetchOutboundResults,
      fetchAmazonCarriers,
      fetchMiscVariables,
    ])
      .then((values) => {
        const payload = {
          hopstackModules: validateHopstackModules(values[0], appState),
          binLocationTypes: values[1],
          roles: values[2],
          sortingLocationTypes: values[3],
          toteTypes: values[4],
          deviceTypes: values[5],
          integrationProviders: values[6],
          inboundVariables: values[7],
          outboundVariables: values[8],
          inboundResults: values[9],
          outboundResults: values[10],
          amazonCarriers: values[11],
          miscVariables: values[12],
        };
        dispatch({ type: "SET_MASTER_DATA", payload });
      })
      .catch((err) => {});
  }

  function clearMasterData() {
    dispatch({ type: "SET_MASTER_DATA", payload: {} });
  }

  return (
    <MasterDataContext.Provider
      value={{
        ...state,
        fetchMasterData,
        clearMasterData,
      }}
      {...props}
    />
  );
}

export { MasterDataContext, MasterDataProvider };

const fetchHopstackModules = new Promise(async (resolve, reject) => {
  try {
    const col = collection(firestoreDb, "hopstack-modules");
    const snapshot = await getDocs(col);
    resolve(snapshot.docs.map((doc) => doc.data()));
  } catch (err) {
    reject(err);
  }
});

const validateHopstackModules = (hopstackModules, appState) => {
  let modifiedModules = [...hopstackModules];

  const tenant = appState.tenant;
  if (tenant.modules?.length > 0) {
    modifiedModules = modifiedModules.filter((i) =>
      tenant.modules
        .filter((m) => m.enabled)
        .map((m) => m.name)
        .includes(i.name),
    );
  }

  return modifiedModules;
};

const fetchBinLocationTypes = new Promise(async (resolve, reject) => {
  try {
    const col = collection(firestoreDb, "bin-location-types");
    const snapshot = await getDocs(col);
    resolve(snapshot.docs.map((doc) => doc.data()));
  } catch (err) {
    reject(err);
  }
});

const fetchSortingLocationTypes = new Promise(async (resolve, reject) => {
  try {
    const col = collection(firestoreDb, "sorting-location-types");
    const snapshot = await getDocs(col);
    resolve(snapshot.docs.map((doc) => doc.data()));
  } catch (err) {
    reject(err);
  }
});

const fetchRoles = new Promise(async (resolve, reject) => {
  try {
    const col = collection(firestoreDb, "roles");
    const snapshot = await getDocs(col);
    resolve(snapshot.docs.map((doc) => doc.data()));
  } catch (err) {
    reject(err);
  }
});

const fetchToteTypes = new Promise(async (resolve, reject) => {
  try {
    const col = collection(firestoreDb, "tote-types");
    const snapshot = await getDocs(col);
    resolve(snapshot.docs.map((doc) => doc.data()));
  } catch (err) {
    reject(err);
  }
});

const fetchDeviceTypes = new Promise(async (resolve, reject) => {
  try {
    const col = collection(firestoreDb, "device-types");
    const snapshot = await getDocs(col);
    resolve(snapshot.docs.map((doc) => doc.data()));
  } catch (err) {
    reject(err);
  }
});

const fetchIntegrationProviders = (state) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!state?.integrationProviders) {
        const integrationProviders = await fetchDataAsync(
          GET_INTEGRATION_PROVIDERS,
        );
        resolve(integrationProviders.data.data.integrationProviders);
      } else {
        resolve(state?.integrationProviders);
      }
    } catch (err) {
      reject(err);
    }
  });
};

const fetchInboundVariables = new Promise(async (resolve, reject) => {
  resolve([
    {
      name: "Tracking Number",
      field: "trackingNumber",
      dataType: "string",
    },
    {
      name: "Consignment Number",
      field: "consignmentNumber",
      dataType: "string",
    },
    {
      name: "Inbound Order ID",
      field: "orderId",
      dataType: "string",
    },
    {
      name: "Fulfillment Type",
      field: "fulfillmentType",
      dataType: "string",
    },
    {
      name: "Status",
      field: "status",
      dataType: "string",
    },
    {
      name: "Item - SKU",
      field: "sku",
      dataType: "string",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - Seller SKU / MSKU",
      field: "sellerSku",
      dataType: "string",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - ASIN",
      field: "asin",
      dataType: "string",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - Product Name",
      field: "productName",
      dataType: "string",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - UPC",
      field: "upc",
      dataType: "array",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - FN SKU",
      field: "fnSku",
      dataType: "string",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - Part of Bundle?",
      field: "bundledItem",
      dataType: "boolean",
      nestedParent: "workingList",
      source: "self",
    },
  ]);
});

const fetchMiscVariables = new Promise(async (resolve, reject) => {
  resolve([
    {
      name: "Fulfillment Type",
      field: "fulfillmentType",
      dataType: "string",
    },
    {
      name: "Status",
      field: "status",
      dataType: "string",
    },
    {
      name: "Item - SKU",
      field: "sku",
      dataType: "string",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - Product Name",
      field: "productName",
      dataType: "string",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - UPC",
      field: "upc",
      dataType: "array",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - Part of Bundle?",
      field: "bundledItem",
      dataType: "boolean",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Bundle ID",
      field: "bundleId",
      dataType: "string",
      nestedParent: "workingList",
      source: "self",
    },
  ]);
});

const fetchOutboundVariables = new Promise(async (resolve, reject) => {
  resolve([
    {
      name: "Warehouse",
      field: "warehouse",
      dataType: "string",
    },
    {
      name: "Client",
      field: "customer",
      dataType: "string",
    },
    {
      name: "Order ID",
      field: "orderId",
      dataType: "string",
    },
    {
      name: "Status",
      field: "orderStatus",
      dataType: "string",
    },
    {
      name: "Carrier",
      field: "carrier",
      dataType: "string",
    },
    {
      name: "Order Date",
      field: "orderDate",
      dataType: "date",
    },
    {
      name: "Order Value",
      field: "orderValue",
      dataType: "float",
    },
    {
      name: "Shipping Label",
      field: "shippingLabel",
      dataType: "string",
    },
    {
      name: "Tracking Number",
      field: "trackingNumber",
      dataType: "string",
    },
    {
      name: "Shipping Address - Name",
      field: "shippingAddress.name",
      nestedParent: "shippingAddress",
      dataType: "string",
    },
    {
      name: "Shipping Address - Line1",
      field: "shippingAddress.line1",
      nestedParent: "shippingAddress",
      query: "shippingAddress.line1",
      dataType: "string",
    },
    {
      name: "Shipping Address - Line2",
      field: "shippingAddress.line2",
      nestedParent: "shippingAddress",
      dataType: "string",
    },
    {
      name: "Shipping Address - City",
      field: "shippingAddress.city",
      nestedParent: "shippingAddress",
      dataType: "string",
      source: "self",
    },
    {
      name: "Shipping Address - State",
      field: "shippingAddress.state",
      nestedParent: "shippingAddress",
      dataType: "string",
      source: "self",
    },
    {
      name: "Shipping Address - Country",
      field: "shippingAddress.country",
      nestedParent: "shippingAddress",
      dataType: "string",
      source: "self",
    },
    {
      name: "Shipping Address - Pincode",
      field: "shippingAddress.zip",
      dataType: "string",
      nestedParent: "shippingAddress",
      source: "self",
    },
    {
      name: "Shipping Address - State",
      field: "shippingAddress.state",
      nestedParent: "shippingAddress",
      dataType: "string",
      source: "self",
    },
    {
      name: "Item - SKU",
      field: "sku",
      dataType: "string",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - Seller SKU / MSKU",
      field: "sellerSku",
      dataType: "string",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - ASIN",
      field: "sku",
      dataType: "string",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - Product Name",
      field: "productName",
      dataType: "string",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - UPC",
      field: "upc",
      dataType: "array",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - FN SKU",
      field: "fnSku",
      dataType: "string",
      nestedParent: "workingList",
      source: "self",
    },
    {
      name: "Item - Quantity",
      field: "quantity",
      dataType: "integer",
      nestedParent: "workingList",
      source: "self",
    },
  ]);
});

const fetchInboundResults = new Promise(async (resolve, reject) => {
  resolve([
    {
      name: "Mark activity as completed",
      value: "completeActivity",
    },
    {
      name: "Mark activity as error",
      value: "markError",
    },
    {
      name: "Add to Inventory",
      value: "addToInventory",
    },
    {
      name: "Deduct from Inventory",
      value: "deductFromInventory",
    },
    {
      name: "Trigger Notification to admin",
      value: "sendNotification",
    },
    {
      name: "Request approval",
      value: "requestApproval",
    },
  ]);
});

const fetchOutboundResults = new Promise(async (resolve, reject) => {
  resolve([
    {
      name: "Mark activity as completed",
      value: "completeActivity",
    },
    {
      name: "Mark order as COMPLETE",
      value: "completeOrder",
    },
    {
      name: "Mark activity as error",
      value: "markError",
    },
    {
      name: "Mark order as INCOMPLETE",
      value: "incompleteOrder",
    },
    {
      name: "Deduct from Inventory",
      value: "deductFromInventory",
    },
    {
      name: "Put back in Inventory",
      value: "addToInventory",
    },
    {
      name: "Trigger Notification to admin",
      value: "sendNotification",
    },
    {
      name: "Request approval",
      value: "requestApproval",
    },
  ]);
});

const fetchAmazonCarriers = new Promise(async (resolve, reject) =>
  resolve([
    { name: "DHL Express", id: "DHL_EXPRESS_USA_INC" },
    { name: "FedEx", id: "FEDERAL_EXPRESS_CORP" },
    { name: "USPS", id: "UNITED_STATES_POSTAL_SERVICE" },
    { name: "UPS", id: "UNITED_PARCEL_SERVICE_INC" },
    { name: "Other", id: "OTHER" },
  ]),
);
