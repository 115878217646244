export const LOGOUT_USER = `
  mutation Logout {
    logout {
      message
    }
  }
`;

export const SAVE_TENANT_PROFILE = `
  mutation saveTenant($tenantProfileInput: TenantProfileInput!) {
    saveTenant(tenantProfileInput: $tenantProfileInput) {
      message
    }
  }
`;

export const VERIFY_EMAIL = `
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token) {
      message
    }
  }
`;

export const EXPORT_REPORT = `
  mutation ExportReport($exportReportInput: ExportReportInput!) {
    exportReport(exportReportInput: $exportReportInput) {
      message
      error
      reportId
    }
  }
`;

export const SET_ACTIVITY = `
  mutation setActivity($activity: String) {
    setActivity(activity: $activity) {
      message
    }
  }
`;

export const SET_STATION = `
  mutation setStation($station: String, $logoutAll: Boolean) {
    setStation(station: $station, logoutAll: $logoutAll) {
      message
    }
  }
`;

export const SAVE_USER = `
  mutation saveUser(
    $name: String!
    $email: String
    $role: String!
    $hopstackModules: [String!]
    $id: ID
    $password: String
    $customers: [ID!]
    $warehouses: [ID!]
    $vendors:[ID!]
    $permissions: [UserPermissionInput!]
    $pagePreferences: [UserPagePreferenceInput!]
    $activated: Boolean
    $suspended: Boolean
    $tablePreferences: [TablePreferenceInput]
    $termsAndConditionsAccepted: Boolean
    $pageSavedFilters: PageSavedFilters
  ) {
    saveUser(
      userInput: {
        name: $name
        email: $email
        role: $role
        hopstackModules: $hopstackModules
        id: $id
        password: $password
        customers: $customers
        warehouses: $warehouses
        vendors: $vendors
        permissions: $permissions
        pagePreferences: $pagePreferences
        activated: $activated
        suspended: $suspended
        tablePreferences: $tablePreferences
        termsAndConditionsAccepted: $termsAndConditionsAccepted
        pageSavedFilters: $pageSavedFilters
      }
    ) {
      message
    }
  }
`;

export const SET_PASSWORD = `
  mutation setPassword($token: String!, $password: String!) {
    setPassword(token: $token, password: $password) {
      message
    }
  }
`;

export const RESET_PASSWORD = `
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      message
    }
  }
`;

export const SAVE_UOM_LABELS = `
 mutation AddUomLabels($labels: [String]) {
  addUomLabels(labels: $labels) {
    newLabels {
      name
      isActive
      createdAt
    }
  }
}
`;

export const DELETE_USER = `
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      message
    }
  }
`;

export const SAVE_STATION = `
  mutation saveStation(
    $name: String
    $hopstackModule: String
    $id: ID
    $devices: [ID!]
    $customers: [ID!]
    $warehouses: [ID!]
  ) {
    saveStation(
      stationInput: {
        name: $name
        hopstackModule: $hopstackModule
        id: $id
        devices: $devices
        customers: $customers
        warehouses: $warehouses
      }
    ) {
      message
    }
  }
`;

export const DELETE_STATION = `
  mutation deleteStation($id: ID!) {
    deleteStation(id: $id) {
      message
    }
  }
`;

export const SAVE_WORKFLOW = `
  mutation saveWorkflow(
    $workflowType: String!
    $id: ID
    $customers: [ID!]
    $warehouses: [ID!]
    $stages: [WorkflowStationInput!]
    $pickingStrategy: String
    $compliance: String
    $minBatchSize: Int
    $maxBatchSize: Int
    $waveSize: Int
  ) {
    saveWorkflow(
      workflowInput: {
        workflowType: $workflowType
        id: $id
        customers: $customers
        stages: $stages
        warehouses: $warehouses
        pickingStrategy: $pickingStrategy
        compliance: $compliance
        minBatchSize: $minBatchSize
        maxBatchSize: $maxBatchSize
        waveSize: $waveSize
      }
    ) {
      message
    }
  }
`;

export const ADD_TO_WORKFLOW = `
  mutation addToWorkflow(
    $name: String!
    $id: ID
    $actions: [AnyAttributes]
    $displayFields: [AnyAttributes]
    $stageId: ID
    $enabled: Boolean
    $customer: ID
    $warehouse: ID
    $workflowType: String
    $positiveActions: [AnyAttributes]
    $negativeActions: [AnyAttributes]
    $lpnEnabled: Boolean
  ) {
    addToWorkflow(
      addWorkflowInput: {
        name: $name
        id: $id
        actions: $actions
        displayFields: $displayFields
        stageId: $stageId
        enabled: $enabled
        customer: $customer
        warehouse: $warehouse
        workflowType: $workflowType
        positiveActions: $positiveActions
        negativeActions: $negativeActions
        lpnEnabled: $lpnEnabled
      }
    ) {
      message
    }
  }
`;

export const DELETE_WORKFLOW = `
  mutation deleteWorkflow($id: ID!) {
    deleteWorkflow(id: $id) {
      message
    }
  }
`;

export const SAVE_INTEGRATION = `
  mutation SaveIntegration(
    $username: String
    $password: String
    $storeName: String
    $integrationType: String!
    $customer: ID
    $warehouse: ID
    $id: ID
    $marketplaceCountryCode: String
    $marketplaces: [String]
    $region: String
    $authCode: String
    $spOAuthCode: String
    $accessToken: String
    $refreshToken: String
    $commerceHubHost: String
    $amazonSellerId: String
    $netsuiteConnectionDetails: NetSuiteConnectionDetailsInput
    $configurations: IntegrationConfigurationsInput
    $connectionDetails: IntegrationConnectionDetailsInput
  ) {
    saveIntegration(
      integrationInput: {
        username: $username
        password: $password
        id: $id
        customer: $customer
        warehouse: $warehouse
        integrationType: $integrationType
        storeName: $storeName
        marketplaceCountryCode: $marketplaceCountryCode
        marketplaces: $marketplaces
        region: $region
        authCode: $authCode
        spOAuthCode: $spOAuthCode
        accessToken: $accessToken
        refreshToken: $refreshToken
        commerceHubHost: $commerceHubHost
        amazonSellerId: $amazonSellerId
        netsuiteConnectionDetails: $netsuiteConnectionDetails
        configurations: $configurations
        connectionDetails: $connectionDetails
      }
    ) {
      message
    }
  }
`;

export const DELETE_INTEGRATION = `
  mutation deleteIntegration($id: ID!) {
    deleteIntegration(id: $id) {
      message
    }
  }
`;

export const SAVE_DEVICE = `
  mutation saveDevice(
    $name: String!
    $id: ID
    $hardwareId: String!
    $deviceType: String!
    $customers: [ID!]
    $warehouses: [ID!]
  ) {
    saveDevice(
      deviceInput: {
        name: $name
        id: $id
        hardwareId: $hardwareId
        deviceType: $deviceType
        customers: $customers
        warehouses: $warehouses
      }
    ) {
      message
    }
  }
`;

export const DELETE_DEVICE = `
  mutation deleteDevice($id: ID!) {
    deleteDevice(id: $id) {
      message
    }
  }
`;

// export const SAVE_WAREHOUSE = `
//   mutation saveWarehouse(
//     $name: String
//     $id: ID
//     $code: String
//     $location: String
//     $typeOfWarehouse: [String!]
//     $splitOrdersEnabled: Boolean
//     $active: Boolean
//     $storageTypes: [String]
//   ) {
//     saveWarehouse(
//       warehouseInput: {
//         name: $name
//         id: $id
//         code: $code
//         location: $location
//         typeOfWarehouse: $typeOfWarehouse
//         splitOrdersEnabled: $splitOrdersEnabled
//         active: $active
//         storageTypes: $storageTypes
//       }
//     ) {
//       message
//     }
//   }
// `;

export const SAVE_WAREHOUSE = `
  mutation saveWarehouse(
    $name: String
    $id: ID
    $code: String
    $typeOfWarehouse: [String!]
    $splitOrdersEnabled: Boolean
    $active: Boolean
    $storageTypes: [String]
    $address: WarehouseAddressInput
  ) {
    saveWarehouse(
      warehouseInput: {
        name: $name
        id: $id
        code: $code
        typeOfWarehouse: $typeOfWarehouse
        splitOrdersEnabled: $splitOrdersEnabled
        active: $active
        storageTypes: $storageTypes
        address: $address
      }
    ) {
      message
    }
  }
`;

export const DELETE_WAREHOUSE = `
  mutation deleteWarehouse($id: ID!) {
    deleteWarehouse(id: $id) {
      message
    }
  }
`;

export const SAVE_SKU_BUNDLE = `
  mutation saveSkuBundle(
    $id: ID
    $name: String!
    $sku: String!
    $enabled: Boolean!
    $bundle: [SkuBundleContentItemInput!]
    ) {
    saveSkuBundle(skuBundleInput: {
      id: $id
      name: $name
      sku: $sku
      enabled: $enabled
      bundle: $bundle
    }) {
      message
    } 
  }
`;

export const DELETE_SKU_BUNDLE = `
  mutation deleteSkuBundle($id: ID!) {
    deleteSkuBundle(id: $id) {
      message
    } 
  }
`;

export const SAVE_CUSTOMER = `
  mutation saveCustomer(
    $name: String
    $id: ID
    $code: String
    $warehouses: [ID!]
    $currency: String
    $active: Boolean
    $settings: CustomerSettings
  ) {
    saveCustomer(
      customerInput: {
        name: $name
        id: $id
        code: $code
        warehouses: $warehouses
        currency: $currency
        active: $active
        settings: $settings
      }
    ) {
      message
    }
  }
`;

export const DELETE_CUSTOMER = `
  mutation deleteCustomer($id: ID!) {
    deleteCustomer(id: $id) {
      message
    }
  }
`;

export const SAVE_GROUP = `
  mutation saveGroup(
    $name: String!
    $id: ID
    $mainCriteria: String!
    $conditions: [ConditionInput!]!
  ) {
    saveGroup(
      groupInput: {
        name: $name
        id: $id
        mainCriteria: $mainCriteria
        conditions: $conditions
      }
    ) {
      message
    }
  }
`;

export const DELETE_GROUP = `
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      message
    }
  }
`;

// PICKER

export const SCAN_PICKER_BARCODE = `
  mutation ScanPickerBarcode($code: String!,$warehouse:ID) {
    scanPickerBarcode(code: $code,warehouse:$warehouse) {
      message
      batch {
      id
      tote
      currentItem {
        id
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        attributes {
          size
          color
          collection
          pattern
          sizeName
          colorName
          collectionName
          patternName
          height
          weight
          length
          width
        }
        status
        scannedSubTote
        subTote
        order
        scannedBatchId
        batchId
        mfgDate
        expiryDate
        tote
        scannedTote
        formFactor
        bestByDate
        warehouse
        productId
        customAttributes
        productAlias
      }
      workingList {
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        status
        tote
        scannedTote
        bestByDate
        order
        productId
      }
      status
      attributes {
        dropoffStation
        orderIds
        orders {
          id
          orderStatus
          carrier
          orderDate
          shippingAddress {
            line1
            line2
            zip
            country
            state
            name
            email
            phone
            city
          }
          shipmentPlan
          reference
          orderId
          orderValue
          orderLineItems {
            reference
            binLocation
            sku
            fnSku
            asin
            sellerSku
            upc
            quantity
            availableQuantity
            baseUom
            uomConfiguration {
              baseUom
              baseUomQuantity
              targetUom
              isActive
            }
            price
            id
            productId
            productName
            productImage
            uniqueIdentifier
            bestByDate
            status
            formFactor
            serialNumber
            name
            fulfillmentType
            lotId
            nestedFormFactorId
            palletId
            marketplaceAttributes {
              marketplace
              attributes
            }
            typeOfProduct
            products {
              id
              sku
              quantityInBundle
              quantityToFulfill
              availableToShip
            }
            availableToShip
          }
          orderProducts {
            reference
            binLocation
            sku
            fnSku
            asin
            sellerSku
            upc
            quantity
            availableQuantity
            baseUom
            price
            id
            productId
            productName
            productImage
            uniqueIdentifier
            bestByDate
            status
            formFactor
            serialNumber
            name
            fulfillmentType
            lotId
            nestedFormFactorId
            palletId
            typeOfProduct
            availableToShip
          }
          subStatus
          exceptions {
            orderLineItem {
              reference
              binLocation
              sku
              fnSku
              asin
              sellerSku
              upc
              quantity
              availableQuantity
              baseUom
              price
              id
              productId
              productName
              productImage
              uniqueIdentifier
              bestByDate
              status
              formFactor
              serialNumber
              name
              fulfillmentType
              lotId
              nestedFormFactorId
              palletId
              typeOfProduct
              availableToShip
            }
            description
            typeOfException
          }
          shippingLabel
          tote
          subTote
          trackingNumber
          shippingStatus
          cannotFulfil
          formFactor
          trackingUrl
          source
          orderType
          toWarehouse
          email
          bol
          shippingLabels
          trackingNumbers
          invoiceUrls
          boxInformationNeeded
          fbaAudit
          typeOfShipment
          confirmedTypeOfShipment
          boxLabels
          pallets {
            name
            length
            width
            height
            weight
          }
          palletLabels
          transportFailed
          hazmatConfirmed
          dropship
          consignmentId
          verifiedLabelUpload
          shippingRequests
          carrierService
          attributes
          insuranceRequired
          insuranceProvider
          insuredValue
          notes
          completedDate
          bolNumber
          bolSealNumber
          scacCode
          dateOfShipping
          toValidAddress
          warehouse
          customer
          selectedCarrierServiceId
          bypassCarrierSelection
          selectedCarrierSource
          classification
          isCasePack
          customAttributes
          preSelectedCarrierRate {
            id
            type
            carrier
            source
            service
            price
            currency
            estimated_delivery_date
            estimated_delivery_days
          }
          estimatedBoxes {
            length
            width
            height
            totalWeight
            boxCount
          }
          proNumber
          numberOfProducts
          totalQuantity
          multipleLineItems
          marketplaceCountryCode
          region
          sellerId
          childOrders
          splitOrders
          originalOrder
          isPartneredCarrier
          deliveryDate
          thirdPartyFreightChargesBill
          carrierIntegration {
            carrier
            carrierService
            notes
          }
        }
        multipleLineItemBatch
        orderLineItemSubTotes
        orderSubTotes
        humanReadableOrderSubTotes
        humanReadableOrderIds
        subTotes
        subTotePositions
        pickingStrategy
        humanReadableOrderMapping
        humanReadableOrderIdMapping
      }
      completion
      confirmationList {
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        status
        tote
        scannedTote
        bestByDate
        order
        productId
      }
      verificationNeeded
      workflow
      orderSubTotes
      lpn
      customer
      warehouse
      items {
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        status
        tote
        scannedTote
        bestByDate
        order
        productId
      }
      boxes {
        length
        width
        height
        weight
        name
        formFactor
        sku
      }
      weight
      classification
      username
    }
    }
  }
`;

export const CONFIRM_PICK_ITEM = `
  mutation ConfirmPickItem($id: ID!, $item: PickerConfirmItem!, $weight: Float, $boxName: String) {
    confirmPickItem(id: $id, item: $item, weight: $weight, boxName: $boxName) {
      message
      batch {
      id
      tote
      currentItem {
        id
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        attributes {
          size
          color
          collection
          pattern
          sizeName
          colorName
          collectionName
          patternName
          height
          weight
          length
          width
        }
        status
        scannedSubTote
        subTote
        order
        scannedBatchId
        batchId
        mfgDate
        expiryDate
        tote
        scannedTote
        formFactor
        bestByDate
        warehouse
        productId
        customAttributes
        productAlias
      }
      workingList {
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        status
        tote
        scannedTote
        bestByDate
        order
        productId
      }
      status
      attributes {
        dropoffStation
        orderIds
        orders {
          id
          orderStatus
          carrier
          orderDate
          shippingAddress {
            line1
            line2
            zip
            country
            state
            name
            email
            phone
            city
          }
          shipmentPlan
          reference
          orderId
          orderValue
          orderLineItems {
            reference
            binLocation
            sku
            fnSku
            asin
            sellerSku
            upc
            quantity
            availableQuantity
            baseUom
            uomConfiguration {
              baseUom
              baseUomQuantity
              targetUom
              isActive
            }
            price
            id
            productId
            productName
            productImage
            uniqueIdentifier
            bestByDate
            status
            formFactor
            serialNumber
            name
            fulfillmentType
            lotId
            nestedFormFactorId
            palletId
            marketplaceAttributes {
              marketplace
              attributes
            }
            typeOfProduct
            products {
              id
              sku
              quantityInBundle
              quantityToFulfill
              availableToShip
            }
            availableToShip
          }
          orderProducts {
            reference
            binLocation
            sku
            fnSku
            asin
            sellerSku
            upc
            quantity
            availableQuantity
            baseUom
            price
            id
            productId
            productName
            productImage
            uniqueIdentifier
            bestByDate
            status
            formFactor
            serialNumber
            name
            fulfillmentType
            lotId
            nestedFormFactorId
            palletId
            typeOfProduct
            availableToShip
          }
          subStatus
          exceptions {
            orderLineItem {
              reference
              binLocation
              sku
              fnSku
              asin
              sellerSku
              upc
              quantity
              availableQuantity
              baseUom
              price
              id
              productId
              productName
              productImage
              uniqueIdentifier
              bestByDate
              status
              formFactor
              serialNumber
              name
              fulfillmentType
              lotId
              nestedFormFactorId
              palletId
              typeOfProduct
              availableToShip
            }
            description
            typeOfException
          }
          shippingLabel
          tote
          subTote
          trackingNumber
          shippingStatus
          cannotFulfil
          formFactor
          trackingUrl
          source
          orderType
          toWarehouse
          email
          bol
          shippingLabels
          trackingNumbers
          invoiceUrls
          boxInformationNeeded
          fbaAudit
          typeOfShipment
          confirmedTypeOfShipment
          boxLabels
          pallets {
            name
            length
            width
            height
            weight
          }
          palletLabels
          transportFailed
          hazmatConfirmed
          dropship
          consignmentId
          verifiedLabelUpload
          shippingRequests
          carrierService
          attributes
          insuranceRequired
          insuranceProvider
          insuredValue
          notes
          completedDate
          bolNumber
          bolSealNumber
          scacCode
          dateOfShipping
          toValidAddress
          warehouse
          customer
          selectedCarrierServiceId
          bypassCarrierSelection
          selectedCarrierSource
          classification
          isCasePack
          customAttributes
          preSelectedCarrierRate {
            id
            type
            carrier
            source
            service
            price
            currency
            estimated_delivery_date
            estimated_delivery_days
          }
          estimatedBoxes {
            length
            width
            height
            totalWeight
            boxCount
          }
          proNumber
          numberOfProducts
          totalQuantity
          multipleLineItems
          marketplaceCountryCode
          region
          sellerId
          childOrders
          splitOrders
          originalOrder
          isPartneredCarrier
          deliveryDate
          thirdPartyFreightChargesBill
          carrierIntegration {
            carrier
            carrierService
            notes
          }
        }
        multipleLineItemBatch
        orderLineItemSubTotes
        orderSubTotes
        humanReadableOrderSubTotes
        humanReadableOrderIds
        subTotes
        subTotePositions
        pickingStrategy
        humanReadableOrderMapping
        humanReadableOrderIdMapping
      }
      completion
      confirmationList {
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        status
        tote
        scannedTote
        bestByDate
        order
        productId
      }
      verificationNeeded
      workflow
      orderSubTotes
      lpn
      customer
      warehouse
      items {
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        status
        tote
        scannedTote
        bestByDate
        order
        productId
      }
      boxes {
        length
        width
        height
        weight
        name
        formFactor
        sku
      }
      weight
      classification
      username
    }
    }
  }
`;
export const CONFIRM_PICK_ITEMS = `
  mutation ConfirmPickItems($id: ID!, $items: [PickerConfirmItem!]) {
    confirmPickItems(id: $id, items: $items) {
      message
    }
  }
`;

export const SKIP_PICK_ITEM = `
  mutation SkipPickItem($id: ID!) {
    skipPickItem(id: $id) {
      message
       batch {
      id
      tote
      currentItem {
        id
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        attributes {
          size
          color
          collection
          pattern
          sizeName
          colorName
          collectionName
          patternName
          height
          weight
          length
          width
        }
        status
        scannedSubTote
        subTote
        order
        scannedBatchId
        batchId
        mfgDate
        expiryDate
        tote
        scannedTote
        formFactor
        bestByDate
        warehouse
        productId
        customAttributes
        productAlias
      }
      workingList {
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        status
        tote
        scannedTote
        bestByDate
        order
        productId
      }
      status
      attributes {
        dropoffStation
        orderIds
        orders {
          id
          orderStatus
          carrier
          orderDate
          shippingAddress {
            line1
            line2
            zip
            country
            state
            name
            email
            phone
            city
          }
          shipmentPlan
          reference
          orderId
          orderValue
          orderLineItems {
            reference
            binLocation
            sku
            fnSku
            asin
            sellerSku
            upc
            quantity
            availableQuantity
            baseUom
            uomConfiguration {
              baseUom
              baseUomQuantity
              targetUom
              isActive
            }
            price
            id
            productId
            productName
            productImage
            uniqueIdentifier
            bestByDate
            status
            formFactor
            serialNumber
            name
            fulfillmentType
            lotId
            nestedFormFactorId
            palletId
            marketplaceAttributes {
              marketplace
              attributes
            }
            typeOfProduct
            products {
              id
              sku
              quantityInBundle
              quantityToFulfill
              availableToShip
            }
            availableToShip
          }
          orderProducts {
            reference
            binLocation
            sku
            fnSku
            asin
            sellerSku
            upc
            quantity
            availableQuantity
            baseUom
            price
            id
            productId
            productName
            productImage
            uniqueIdentifier
            bestByDate
            status
            formFactor
            serialNumber
            name
            fulfillmentType
            lotId
            nestedFormFactorId
            palletId
            typeOfProduct
            availableToShip
          }
          subStatus
          exceptions {
            orderLineItem {
              reference
              binLocation
              sku
              fnSku
              asin
              sellerSku
              upc
              quantity
              availableQuantity
              baseUom
              price
              id
              productId
              productName
              productImage
              uniqueIdentifier
              bestByDate
              status
              formFactor
              serialNumber
              name
              fulfillmentType
              lotId
              nestedFormFactorId
              palletId
              typeOfProduct
              availableToShip
            }
            description
            typeOfException
          }
          shippingLabel
          tote
          subTote
          trackingNumber
          shippingStatus
          cannotFulfil
          formFactor
          trackingUrl
          source
          orderType
          toWarehouse
          email
          bol
          shippingLabels
          trackingNumbers
          invoiceUrls
          boxInformationNeeded
          fbaAudit
          typeOfShipment
          confirmedTypeOfShipment
          boxLabels
          pallets {
            name
            length
            width
            height
            weight
          }
          palletLabels
          transportFailed
          hazmatConfirmed
          dropship
          consignmentId
          verifiedLabelUpload
          shippingRequests
          carrierService
          attributes
          insuranceRequired
          insuranceProvider
          insuredValue
          notes
          completedDate
          bolNumber
          bolSealNumber
          scacCode
          dateOfShipping
          toValidAddress
          warehouse
          customer
          selectedCarrierServiceId
          bypassCarrierSelection
          selectedCarrierSource
          classification
          isCasePack
          customAttributes
          preSelectedCarrierRate {
            id
            type
            carrier
            source
            service
            price
            currency
            estimated_delivery_date
            estimated_delivery_days
          }
          estimatedBoxes {
            length
            width
            height
            totalWeight
            boxCount
          }
          proNumber
          numberOfProducts
          totalQuantity
          multipleLineItems
          marketplaceCountryCode
          region
          sellerId
          childOrders
          splitOrders
          originalOrder
          isPartneredCarrier
          deliveryDate
          thirdPartyFreightChargesBill
          carrierIntegration {
            carrier
            carrierService
            notes
          }
        }
        multipleLineItemBatch
        orderLineItemSubTotes
        orderSubTotes
        humanReadableOrderSubTotes
        humanReadableOrderIds
        subTotes
        subTotePositions
        pickingStrategy
        humanReadableOrderMapping
        humanReadableOrderIdMapping
      }
      completion
      confirmationList {
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        status
        tote
        scannedTote
        bestByDate
        order
        productId
      }
      verificationNeeded
      workflow
      orderSubTotes
      lpn
      customer
      warehouse
      items {
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        status
        tote
        scannedTote
        bestByDate
        order
        productId
      }
      boxes {
        length
        width
        height
        weight
        name
        formFactor
        sku
      }
      weight
      classification
      username
    }
    }
  }
`;

export const SET_PICKER_BOXES = `
  mutation SetPickerBoxes($id: ID!, $boxes: [BoxTypeInput]) {
    setPickerBoxes(id: $id, boxes: $boxes) {
      message
    }
  }
`;

export const CONFIRM_PICKER_DROPOFF = `
  mutation ConfirmPickerDropoff($id: ID!) {
    confirmPickerDropoff(id: $id) {
      message
    }
  }
`;

export const GET_BUNDLE_PICKING = `
  mutation GetBundlePicking(
    $warehouse: String, 
    $customer: String, 
    $perPage: Int, 
    $pageNumber: Int, 
    $sort: String, 
    $filters: AllBatchFilter
  ) {
    getBundlePicking(
      warehouse: $warehouse, 
      customer: $customer, 
      perPage: $perPage, 
      pageNumber: $pageNumber, 
      sort: $sort, 
      filters: $filters
    ) {
      total
      entities {
        id
        user
        allocatedUser
        createdAt
        updatedAt
        completion
        tote
        status
        totalScannedQuantity
        totalConsumedQuantity
        warehouse
        customer
        tenant
        station
        inventory {
          sku
          productName
          binLocation
          quantity
          scannedQuantity
          status
        }
        overallStatus
      }
    }
  }
`;

export const GET_BUNDLE_ITEMS = `
  mutation GetBundleItems($warehouse: String, $customer: String, $getBundleItemsId: ID) {
    getBundleItems(warehouse: $warehouse, customer: $customer, getBundleItemsId: $getBundleItemsId) {
      bundlePutawayTotalConsumedQuantity
      bundlePutawayStatus
      bundlingResponse {
        bundledInventory {
          productId
          sku
          productName
          quantity
          scannedQuantity
          status
        }
        completion
        status
        totalScannedQuantity
        totalConsumedQuantity
        children {
          quantityConsumed
        }
      }
      bundlePutawayResponse {
        inventory {
          productId
          productName
          sku
          quantity
          scannedQuantity
          status
          binLocation
        }
        completion
        status
        totalScannedQuantity
        totalConsumedQuantity
      }
    }
  }
`;

export const GET_BUNDLE_PUTAWAY_LIST_VIEW = `
  mutation GetBundlePutawayListView($warehouse: String, $customer: String) {
    getBundlePutawayListView(warehouse: $warehouse, customer: $customer) {
      entities {
        id
        user
        warehouse
        tenant
        station
        type
        inventory {
          productId
          productName
          sku
          hopstackSkuId
          fnSku
          sellerSku
          asin
          upc
          lpn
          quantity
          scannedQuantity
          formFactor
          status
          binLocation
          isBinScanned
        }
        bundledInventory {
          productId
          productName
          sku
          hopstackSkuId
          fnSku
          sellerSku
          asin
          upc
          lpn
          quantity
          scannedQuantity
          formFactor
          status
          uniqueIdentifier
          products {
            productId
            productName
            sku
            hopstackSkuId
            fnSku
            sellerSku
            asin
            upc
            lpn
            quantity
            scannedQuantity
            formFactor
            status
            binLocation
            isBinScanned
          }
        }
        currentItem {
          productId
          productName
          sku
          hopstackSkuId
          fnSku
          sellerSku
          asin
          upc
          lpn
          quantity
          scannedQuantity
          formFactor
          status
          binLocation
          isBinScanned
        }
        currentBundledItem {
          productId
          productName
          sku
          hopstackSkuId
          fnSku
          sellerSku
          asin
          upc
          lpn
          quantity
          scannedQuantity
          formFactor
          status
          uniqueIdentifier
        }
        completion
        tote
        status
        details
        totalScannedQuantity
        totalConsumedQuantity
        parents {
          id
          quantityConsumed
        }
        children {
          id
          quantityConsumed
        }
      }
    total
    }
  }
`;

export const FETCH_OR_ASSIGN_BUNDLE_PICKING_TASK = `
  mutation FetchOrAssignBundlePickingTask($warehouse: String) {
    fetchOrAssignBundlePickingTask(warehouse: $warehouse) {
      id
      user
      warehouse
      tenant
      station
      type
      inventory {
        productId
        productName
        sku
        hopstackSkuId
        fnSku
        sellerSku
        asin
        upc
        lpn
        quantity
        scannedQuantity
        formFactor
        status
        binLocation
        isBinScanned
      }
      currentItem {
        productId
        productName
        sku
        hopstackSkuId
        fnSku
        sellerSku
        asin
        upc
        lpn
        quantity
        scannedQuantity
        formFactor
        status
        binLocation
        isBinScanned
      }  
      details
      completion
      tote
      status
    }
  }
`;

export const SCAN_BUNDLE_PICKING_ITEM = `
  mutation ScanBundlePickingItem($task: String!, $barcode: String!) {
    scanBundlePickingItem(task: $task, barcode: $barcode) {
      message
    }
  }
`;

export const CONFIRM_BUNDLE_PICKING_ITEM = `
  mutation ConfirmBundlePickingItem($task: String!, $quantity: Int!) {
    confirmBundlePickingItem(task: $task, quantity: $quantity) {
      message
    }
  }
`;

export const CONFIRM_BUNDLE_PICKING_DROPOFF = `
  mutation ConfirmBundlePickingDropoff($task: String!, $station: String!) {
    confirmBundlePickingDropoff(task: $task, station: $station) {
      message
    }
  }
`;

// BATCH

export const TRY_RESOLVE_EXCEPTION = `
mutation TryResolve($id: ID!) {
  tryResolve(id: $id) {
    message
  }
}
`;

export const TRY_MASS_RESOLVE_EXCEPTIONS = `
mutation TryMassResolveExceptions($ids: [ID]!) {
  tryMassResolveExceptions(ids: $ids) {
    message
  }
}
`;

export const CONFIRM_TYPE_OF_SHIPMENT = `
  mutation ConfirmTypeOfShipment($id: ID!) {
    confirmTypeOfShipment(id: $id) {
      message
    }
  }
`;

export const SWITCH_TYPE_OF_SHIPMENT = `
  mutation SwitchTypeOfShipment($id: ID!, $typeOfShipment: String) {
    switchTypeOfShipment(id: $id, typeOfShipment: $typeOfShipment) {
      message
    }
  }
`;

export const MARK_HAZMAT_CONFIRMED = `
  mutation MarkHazmatConfirmed($id: ID!, $hazmatConfirmed: Boolean) {
    markHazmatConfirmed(id: $id, hazmatConfirmed: $hazmatConfirmed) {
      message
    }
  }
`;

export const CREATE_MANUAL_ORDER_BATCH = `
  mutation CreateManualOrderBatch(
    $ids: [ID!]
    $user: ID
    $preppingStation: ID
    $packingStation: ID
    $combineOrders: Boolean
    $maxOrdersInBatch: Int
  ) {
    createManualOrderBatch(
      ids: $ids
      user: $user
      preppingStation: $preppingStation
      packingStation: $packingStation
      combineOrders: $combineOrders
      maxOrdersInBatch: $maxOrdersInBatch
    ) {
      message
      attemptId
    }
  }
`;

// export const CREATE_AUTO_ORDER_BATCHES = `
//   mutation CreateAutoOrderBatches($startDate: Date, $endDate: Date) {
//     createAutoOrderBatches(startDate: $startDate, endDate: $endDate) {
//       message
//     }
//   }
// `;

export const CREATE_AUTO_ORDER_BATCHES = `
  mutation CreateAutoOrderBatches {
    createAutoOrderBatches {
      message
    }
  }
`;

export const SIMULATE_AUTO_ORDER_BATCHES = `
  mutation SimulateAutoOrderBatches {
    simulateAutoOrderBatches {
      items {
        uniqueIdentifier
        binLocation
        sku
        quantity
        reference
        price
        attributes {
          color
          collection
          size
        }
      }
      rows
    }
  }
`;

export const UPDATE_ORDER_TRACKING_INFO = `
  mutation UpdateTrackingInfo($orderInfo: Random) {
    updateTrackingInfo(orderInfo: $orderInfo) {
      message
    }
  }
`;

export const UPDATE_ORDER_CARRIER_INFO = `
  mutation UpdateCarrierInfo($orderInfo: Random) {
    updateCarrierInfo(orderInfo: $orderInfo) {
      message
    }
  }
`;

// PACKER

export const SCAN_PACKER_BARCODE = `
  mutation ScanPackerBarcode($code: String!, $username: String) {
    scanPackerBarcode(code: $code, username: $username) {
      message
    }
  }
`;

export const SET_PACKER_BOXES = `
  mutation SetPackerBoxes(
    $id: ID!
    $boxes: [BoxTypeInput]
    $confirmedBoxContents: Boolean
    $orderId: ID!
    $commodityContent:[CommodityDetails]
  ) {
    setPackerBoxes(
      id: $id
      boxes: $boxes
      confirmedBoxContents: $confirmedBoxContents
      orderId: $orderId
      commodityContent: $commodityContent
    ) {
      message
    }
  }
`;

export const CONFIRM_PACK_ITEM = `
  mutation ConfirmPackItem($id: ID!, $status: String!, $itemId: String, $boxName: String, $weight: Float, $quantity: Int, $boxWeight: Float) {
    confirmPackItem(id: $id, status: $status, itemId: $itemId, boxName: $boxName, weight: $weight, quantity: $quantity, boxWeight: $boxWeight) {
      message
    }
  }
`;

export const SUBMIT_TRACKING_INFORMATION = `
  mutation SubmitTrackingInformation($id: ID!, $trackingInformation: Random) {
    submitTrackingInformation(id: $id, trackingInformation: $trackingInformation) {
      message
    }
  }
`;

export const CONFIRM_TRANSPORT_INFORMATION = `
  mutation ConfirmTransportInformation($id: ID!) {
    confirmTransportInformation(id: $id) {
      message
    }
  }
`;

export const CONFIRM_PALLET_INFORMATION = `
  mutation ConfirmPalletInformation($id: ID!, $pallets: [AmazonPalletInput], $generateLabels: Boolean) {
    confirmPalletInformation(id: $id, pallets: $pallets, generateLabels: $generateLabels) {
      message
    }
  }
`;

export const CANCEL_PACK_ITEM = `
  mutation CancelPackItem($id: ID!) {
    cancelPackItem(id: $id) {
      message
    }
  }
`;

export const MARK_ORDER_EXCEPTION = `
  mutation MarkOrderException($id: ID!, $notes: String) {
    markOrderException(id: $id, notes: $notes) {
      message
    }
  }
`;

export const RESTART_PACK = `
  mutation RestartPack($id: ID!) {
    restartPack(id: $id) {
      message
    }
  }
`;

export const PAUSE_PACK = `
  mutation PausePack($id: ID!) {
    pausePack(id: $id) {
      message
    }
  }
`;

export const UNPACK_BOX = `
  mutation UnpackBox($id: ID!, $boxName: String) {
    unpackBox(id: $id, boxName: $boxName) {
      message
    }
  }
`;

export const UNPACK_ITEM = `
  mutation UnpackItem($id: ID!, $unPackItems: [Random]) {
    unpackItem(id: $id, unPackItems: $unPackItems) {
      message
    }
  }
`;

export const PACK_ITEMS = `
  mutation PackItems($id: ID!, $items: [Random], $boxName: String) {
    packItems(id: $id, items: $items, boxName: $boxName) {
      message
    }
  }
`;

export const DUPLICATE_BOX = `
  mutation DuplicateBox(
    $id: ID!
    $sourceBoxName: String
    $destinationBoxes: [Random]
  ) {
    duplicateBox(
      id: $id
      sourceBoxName: $sourceBoxName
      destinationBoxes: $destinationBoxes
    ) {
      message
    }
  }
`;

export const CONFIRM_PACKER_DROPOFF = `
  mutation ConfirmPackerDropoff($id: ID!) {
    confirmPackerDropoff(id: $id) {
      message
    }
  }
`;

export const SAVE_SKU_BIN_MAPPING = `
  mutation saveSkuBinMapping(
    $sku: String!
    $id: ID
    $binLocation: String!
    $customers: [ID!]
    $warehouses: [ID!]
    $quantity: Int
    $palletId: String
    $unallocatedQuantity: Int
    $customer: ID
    $warehouse: ID
    $tote: String
    $lotId: Date
    $serialNumber: String
    $bestByDate: Date
    $formFactor: String
    $nestedFormFactorId: String
    $nestedFormFactor: String
    $marketplace: String
    $sellerId: String
  ) {
    saveSkuBinMapping(
      skuBinMappingInput: {
        sku: $sku
        binLocation: $binLocation
        id: $id
        warehouses: $warehouses
        customers: $customers
        quantity: $quantity
        palletId: $palletId
        unallocatedQuantity: $unallocatedQuantity
        customer: $customer
        warehouse: $warehouse
        tote: $tote
        lotId: $lotId
        serialNumber: $serialNumber
        bestByDate: $bestByDate
        formFactor: $formFactor
        nestedFormFactorId: $nestedFormFactorId
        nestedFormFactor: $nestedFormFactor
        marketplace: $marketplace
        sellerId: $sellerId
      }
    ) {
      message
    }
  }
`;

export const SAVE_SKU_BIN_MAPPINGS = `
  mutation saveSkuBinMappings(
    $skuBinMappingsInput: [SkuBinMappingInput]
  ) {
    saveSkuBinMappings(
      skuBinMappingsInput: $skuBinMappingsInput
    ) {
      message
    }
  }
`;

export const DELETE_SKU_BIN_MAPPING = `
  mutation deleteSkuBinMapping($id: ID!) {
    deleteSkuBinMapping(id: $id) {
      message
    }
  }
`;

// Execute instant bin transfer

export const EXECUTE_INSTANT_BIN_TRANSFER = `
  mutation ExecuteInstantBinTransfer(
    $skuBinMappingId: ID!
    $binLocation: String
    $reason: String
    $deleteEmptyMappings: Boolean
    $quantityToMove: Int
  ) {
    executeInstantBinTransfer(
      skuBinMappingId: $skuBinMappingId
      binLocation: $binLocation
      reason: $reason
      deleteEmptyMappings: $deleteEmptyMappings
      quantityToMove: $quantityToMove
    ) {
      message
    }
  }
`;

export const SAVE_STANDALONE_SKU = `
  mutation SaveStandaloneSku($sku: String!) {
    saveStandaloneSku(sku: $sku) {
      message
    }
  }
`;

export const SAVE_STANDALONE_BIN_LOCATION = `
  mutation SaveStandaloneBinLocation($binLocation: String!) {
    saveStandaloneBinLocation(binLocation: $binLocation) {
      message
    }
  }
`;

export const RUN_COMBINED_ORDERS = `
  mutation RunCombinedOrders {
    runCombinedOrders {
      _id {
        name
        city
        state
        street
      }
      Counter
    }
  }
`;

export const MANUALLY_MARK_ORDERS = `
  mutation ManuallyMarkOrders(
    $ids: [ID!]
    $status: String!
    $markCompleteOnMarketplace: Boolean
  ) {
    manuallyMarkOrders(
      ids: $ids
      status: $status
      markCompleteOnMarketplace: $markCompleteOnMarketplace
    ) {
      message
    }
  }
`;

export const MARK_ORDER_SHIPPED_ON_MARKETPLACE = `
  mutation CompleteOrderOnMarketplace($orderId: ID!) {
    completeOrderOnMarketplace(orderId: $orderId) {
      message
    }
  }
`;

// RECEIVER

export const CONFIRM_RECEIVER_ITEM = `
  mutation ConfirmReceiverItem($id: ID!, $status: String!) {
    confirmReceiverItem(id: $id, status: $status) {
      message
    }
  }
  `;

export const START_RECEIVING = `
  mutation StartReceiving($consignmentId: ID!) {
    startReceiving(consignmentId: $consignmentId) {
      message
    }
  }
`;

// QUALITY CHECKING

export const CONFIRM_QUALITY_CHECKING_ITEM = `
  mutation confirmQualityCheckingItem($id: ID!, $status: String!) {
    confirmQualityCheckingItem(id: $id, status: $status) {
      message
    }
  }
`;

// PUTAWAY

export const CONFIRM_PUTAWAY_ITEM = `
  mutation confirmPutawayItem($id: ID!) {
    confirmPutawayItem(id: $id) {
      message
    }
  }
`;

export const GET_BUNDLE_PUTAWAY_TASK = `
  mutation GetBundlePutawayTask($warehouse: ID!, $customer: ID!) {
    getBundlePutawayTask(warehouse: $warehouse, customer: $customer) {
      id
      user
      tenant
      station
      type
      inventory {
        productId
        productName
        sku
        hopstackSkuId
        fnSku
        sellerSku
        asin
        upc
        lpn
        quantity
        scannedQuantity
        formFactor
        status
        binLocation
        isBinScanned
      }
      bundledInventory {
        productId
        productName
        sku
        hopstackSkuId
        fnSku
        sellerSku
        asin
        upc
        lpn
        quantity
        scannedQuantity
        formFactor
        status
        uniqueIdentifier
        products {
          productId
          productName
          sku
          hopstackSkuId
          fnSku
          sellerSku
          asin
          upc
          lpn
          quantity
          scannedQuantity
          formFactor
          status
          binLocation
          isBinScanned
        }
      }
      currentItem {
        productId
        productName
        sku
        hopstackSkuId
        fnSku
        sellerSku
        asin
        upc
        lpn
        quantity
        scannedQuantity
        formFactor
        status
        binLocation
        isBinScanned
      }
      currentBundledItem {
        productId
        productName
        sku
        hopstackSkuId
        fnSku
        sellerSku
        asin
        upc
        lpn
        quantity
        scannedQuantity
        formFactor
        status
        uniqueIdentifier
        products {
          productId
          productName
          sku
          hopstackSkuId
          fnSku
          sellerSku
          asin
          upc
          lpn
          quantity
          scannedQuantity
          formFactor
          status
          binLocation
          isBinScanned
        }
      }
      completion
      tote
      status
      details
      parents {
        id
        quantityConsumed
      }
      children {
        id
        quantityConsumed
      }
      warehouse
    }
  }
`;

export const SCAN_BUNDLE_PUTAWAY_ITEM = `
  mutation ScanBundlePutawayItem($barcode: String!, $warehouse: ID, $customer: ID, $formFactor: String, $childTask: ID) {
    scanBundlePutawayItem(barcode: $barcode, warehouse: $warehouse, customer: $customer, formFactor: $formFactor, childTask: $childTask)
  }
`;

export const COMPLETE_BUNDLE_PUTAWAY_ITEM = `
  mutation CompleteBundlePutawayItem($childTask: ID!, $quantity: Int!) {
    completeBundlePutawayItem(childTask: $childTask, quantity: $quantity) {
      message
    }
  }
`;

export const DELETE_BUNDLE_PUTAWAY_CHILD_TASK = `
  mutation DeleteBundlePutawayChildTask($childTask: ID!) {
    deleteBundlePutawayChildTask(childTask: $childTask) {
      message
    }
  }
`;

export const UPSERT_PARENT_PRODUCT = `
  mutation upsertParentProduct($productInput: ParentProductInput!) {
    upsertParentProduct(productInput: $productInput) {
      message
    }
  }
`;

export const SAVE_PRODUCT = `
  mutation saveProduct(
    $name: String
    $asin: String
    $upc: [String]
    $ean: [String]
    $sku: String
    $category: ID
    $categories: [CategoryInput]
    $tags: [TagInput]
    $description: String
    $type: String
    $fnSku: String
    $customer: ID
    $warehouse: ID
    $id: ID
    $source: String
    $partOfBundle: Boolean
    $sellerSku: String
    $image: String
    $images: [ProductImageInput]
    $lpn: [String]
    $attributes: Random
    $customAttributes: Random
    $active: Boolean
    $baseUom: String
    $uomConfiguration: [UomConfigurationInput]
    $sellerId: String
    $marketplace: String
    $unverified: Boolean
    $parentProduct: ID
  ) {
    saveProduct(
      productInput: {
        name: $name
        asin: $asin
        upc: $upc
        ean: $ean
        sku: $sku
        fnSku: $fnSku
        category: $category
        tags: $tags
        categories: $categories
        description: $description
        type: $type
        warehouse: $warehouse
        customer: $customer
        id: $id
        source: $source
        partOfBundle: $partOfBundle
        sellerSku: $sellerSku
        image: $image
        images: $images
        lpn: $lpn
        attributes: $attributes
        active: $active
        baseUom: $baseUom
        uomConfiguration: $uomConfiguration
        customAttributes: $customAttributes
        sellerId: $sellerId
        marketplace: $marketplace
        unverified: $unverified
        parentProduct: $parentProduct
      }
    ) {
      message
      url
    }
  }
`;

export const SAVE_BUNDLE_AS_PRODUCT = `
  mutation SaveBundleAsProduct(
    $name: String!
    $asin: String
    $upc: [String]
    $ean: [String]
    $sku: String!
    $category: String
    $tags: [TagInput!]
    $description:String
    $type:String
    $fnSku: String
    $customer: ID!
    $warehouse: ID
    $id: ID
    $source: String!
    $sellerSku: String
    $images: [ProductImageInput]
    $lpn: [String]
    $attributes: Random
    $active: Boolean!
    $baseUom: String!
    $uomConfiguration: [BundleAsProductInputUomConiguration!]!
    $listingStatus: String
    $typeOfProduct: String!
    $products: [BundleAsProductBundleInputProduct]!
  ) {
    saveBundleAsProduct(
      bundleInput: {
        name: $name
        asin: $asin
        upc: $upc
        ean: $ean
        sku: $sku
        fnSku: $fnSku
        category: $category
        tags: $tags
        description:$description
        type:$type
        warehouse: $warehouse
        customer: $customer
        id: $id
        source: $source
        sellerSku: $sellerSku
        images: $images
        lpn: $lpn
        attributes: $attributes
        active: $active
        baseUom: $baseUom
        uomConfiguration: $uomConfiguration
        listingStatus: $listingStatus
        typeOfProduct: $typeOfProduct
        products: $products
      }
    ) {
      message
    }
  }
`;

export const ALLOCATE_INVENTORY_FOR_BUNDLE_AS_PRODUCT = `
  mutation AllocateInventoryForBundleAsProduct(
    $bundleId: ID!
    $uom: String!
    $quantity: Int!
    $warehouseId: ID!
    $user: ID!
  ) {
    allocateInventoryForBundleAsProduct(
      bundleId: $bundleId
      uom: $uom
      quantity: $quantity
      warehouseId: $warehouseId
      user: $user
    ) {
      message
    }
  }
`;

export const SAVE_TAG = `
  mutation SaveTag($id: ID, $name: String, $description: String, $productId: String) {
    saveTag(id: $id, name: $name, description: $description, productId: $productId) {
      message
    }
  }
`;

export const DELETE_TAG = `
  mutation DeleteTag($id: ID!) {
    deleteTag(id: $id) {
      message
    }
  }
`;

export const UPSERT_CATEGORY = `
  mutation UpsertCategory($id: ID, $name: String, $description: String) {
    upsertCategory(
      categoryInput: { id: $id, name: $name, description: $description }
    ) {
      message
    }
  }
`;

export const UPDATE_CATEGORIES = `
  mutation UpdateCategories($categories: [CategoryInput]) {
    updateCategories(categories: $categories) {
      message
      url
    }
  }
`;

export const DELETE_CATEGORY = `
  mutation DeleteCategory($ids: [String!]!) {
    deleteCategory(ids: $ids) {
      message
    }
  }
`;

export const UPSERT_CATALOG = `
  mutation UpsertCatalog($id: ID, $name: String, $products: [ID!]) {
    upsertCatalog(catalogInput: { id: $id, name: $name, products: $products }) {
      message
    }
  }
`;

export const DELETE_CATALOG = `
  mutation DeleteCatalog($id: ID!) {
    deleteCatalog(id: $id) {
      message
    }
  }
`;

export const ALLOCATE_INVENTORY_TO_CHANNELS = `
  mutation AllocateInventoryToChannels(
    $sourceProductId: ID
    $channelInventoryAllocations: [ChannelInventoryAllocationInput]
  ) {
    allocateInventoryToChannels(
      sourceProductId: $sourceProductId
      channelInventoryAllocations: $channelInventoryAllocations
    ) {
      message
    }
  }
`;

export const DELETE_PARENT_PRODUCT = `
  mutation deleteParentProduct($id: ID!) {
    deleteParentProduct(id: $id) {
      message
    }
  }
`;

export const DELETE_PRODUCT = `
  mutation deleteProduct($id: ID) {
    deleteProduct(id: $id) {
      message
    }
  }
`;

export const DELETE_BUNDLE_AS_PRODUCT = `
  mutation DeleteBundleAsProduct($id: ID!) {
    deleteBundleAsProduct(id: $id) {
      message
    }
  }
`;

export const SAVE_TOTE = `
  mutation saveTote(
    $barcode: String
    $toteType: String
    $id: ID
    $customers: [ID!]
    $warehouses: [ID!]
    $subTotes: [String!]
  ) {
    saveTote(
      toteInput: {
        barcode: $barcode
        id: $id
        toteType: $toteType
        customers: $customers
        warehouses: $warehouses
        subTotes: $subTotes
      }
    ) {
      message
    }
  }
`;

export const DELETE_TOTE = `
  mutation DeleteTote($id: ID!) {
    deleteTote(id: $id) {
      message
    }
  }
`;

export const SAVE_BOX_TYPE = `
  mutation saveBoxType(
    $name: String
    $id: ID
    $customers: [ID!]
    $warehouses: [ID!]
    $length: Float
    $width: Float
    $height: Float
    $formFactor: String
    $contents: [BoxTypeInputItem!]
  ) {
    saveBoxType(
      boxTypeInput: {
        name: $name
        id: $id
        length: $length
        width: $width
        height: $height
        customers: $customers
        warehouses: $warehouses
        formFactor: $formFactor
        contents: $contents
      }
    ) {
      message
    }
  }
`;

export const DELETE_BOX_TYPE = `
  mutation DeleteBoxType($id: ID!) {
    deleteBoxType(id: $id) {
      message
    }
  }
`;

export const SAVE_BIN_LOCATION = `
  mutation saveBinLocation(
    $binLocationInput: BinLocationInput
  ) {
    saveBinLocation(
      binLocationInput: $binLocationInput
    ) {
      message
    }
  }
`;

export const SAVE_BULK_BIN_LOCATIONS = `
  mutation BulkSaveBinLocations(
    $binLocationsBody: Random
  ) {
    bulkSaveBinLocations(
      binLocationsBody: $binLocationsBody
    ) {
      message
    }
  }
`;

export const DELETE_BIN_LOCATION = `
  mutation DeleteBinLocation($id: ID!) {
    deleteBinLocation(id: $id) {
      message
    }
  }
`;

export const SAVE_SORTING_LOCATION = `
  mutation saveSortingLocation(
    $barcode: String!
    $sortingLocationType: String!
    $id: ID
    $position: Int
    $warehouses: [ID!]
    $customers: [ID!]
  ) {
    saveSortingLocation(
      sortingLocationInput: {
        barcode: $barcode
        id: $id
        sortingLocationType: $sortingLocationType
        position: $position
        customers: $customers
        warehouses: $warehouses
      }
    ) {
      message
    }
  }
`;

export const DELETE_SORTING_LOCATION = `
  mutation DeleteSortingLocation($id: ID!) {
    deleteSortingLocation(id: $id) {
      message
    }
  }
`;

export const SAVE_RECEIVED_SKU = `
  mutation SaveReceivedSku($id: ID, $quantity: Int) {
    saveReceivedSku(receivedSkuInput: { quantity: $quantity, id: $id }) {
      message
    }
  }
`;

// INVENTORY UPDATE

export const UPDATE_INVENTORY = `
  mutation UpdateInventory(
    $id: ID!
    $stock: Int!
    $user: String!
    $reason: String
    $otherReason: String
  ) {
    updateInventory(
      id: $id
      stock: $stock
      user: $user
      reason: $reason
      otherReason: $otherReason
    ) {
      message
    }
  }
`;

export const UOM_BREAKDOWN = `
  mutation UomBreakdown(
    $id: ID!
    $quantity: Int!
    $user: String!
    $adjustmentUom: String!
  ) {
    uomBreakdown(
      id: $id
      quantity: $quantity
      user: $user
      adjustmentUom: $adjustmentUom
    ) {
      message
    }
  }
`;

export const UPDATE_PROBLEM_INVENTORY = `
  mutation UpdateProblemInventory(
    $stock: Int!
    $user: String!
    $id: ID!
    $reason: String!
    $action: String
    $binLocation: String
    $warehouse: ID
  ) {
    updateProblemInventory(
      stock: $stock
      user: $user
      id: $id
      reason: $reason
      binLocation: $binLocation
      action: $action
      warehouse: $warehouse
    ) {
      message
    }
  }
`;

// export const RELEASE_INVENTORY_SHIPMENT_PLAN_CREATION = `
//   mutation ReleaseInventoryForShipmentPlan($id: ID!) {
//     releaseInventoryForShipmentPlan(id: $id) {
//       message
//     }
//   }
// `;
export const RELEASE_INVENTORY_SHIPMENT_PLAN_CREATION = `
  mutation ReleaseInventoryForShipmentPlan(
    $customer: ID
    $warehouse: ID
    $products: [Random]
    $typeOfShipment: String
    $intendedBoxContentsSource: String
    $retry: Boolean
    $minItemLen: Int
    $isCasePack: Boolean
    $sellerId: String
  ) {
    releaseInventoryForShipmentPlan(
      customer: $customer
      warehouse: $warehouse
      products: $products
      typeOfShipment: $typeOfShipment
      intendedBoxContentsSource: $intendedBoxContentsSource
      retry: $retry
      minItemLen: $minItemLen
      isCasePack: $isCasePack
      sellerId: $sellerId
    ) {
      message
    }
  }
`;

// INWARD RECEIVER

export const SCAN_INWARD_RECEIVER_BARCODE = `
  mutation ScanInwardReceiverBarcode(
    $code: String!
    $trackingNumber: [String]
    $customer: String
    $consignmentId: ID
    $consignmentIds: [ID]
  ) {
    scanInwardReceiverBarcode(
      code: $code
      trackingNumber: $trackingNumber
      customer: $customer
      consignmentId: $consignmentId
      consignmentIds: $consignmentIds
    ) {
      message
    }
  }
`;

export const CHECK_FOR_CONSIGNMENT = `
  mutation InwardReceiverCheckForConsignment($trackingNumber: [String], $customer: String, $consignmentId: ID) {
    checkForConsignment(trackingNumber: $trackingNumber, customer: $customer, consignmentId: $consignmentId) {
      message
      consignments {
        items {
          productName
          fnSku
          upc
          sellerSku
          asin
          quantity
          sku
          formFactor
          availableQuantity
          productId
        }
        workingList {
          productName
          fnSku
          upc
          sellerSku
          asin
          quantity
          sku
          formFactor
          availableQuantity
          productId
        }
        status
        createdAt
        consignmentNumber
        trackingNumber
        orderId
        id
        consignmentDate
        supplier
      }
    }
  }
`;

export const CHECK_FOR_CONSIGNMENT_PUTAWAY = `
  mutation CheckForConsignmentPutaway($trackingNumber: [String], $customer: String, $consignmentId: ID) {
    checkForConsignmentPutaway(trackingNumber: $trackingNumber, customer: $customer, consignmentId: $consignmentId) {
      message
      consignments {
        items {
          productName
          fnSku
          upc
          sellerSku
          asin
          quantity
          sku
          formFactor
          availableQuantity
          productId
        }
        workingList {
          productName
          fnSku
          upc
          sellerSku
          asin
          quantity
          sku
          formFactor
          availableQuantity
          putawayQuantity
          productId
        }
        status
        createdAt
        consignmentNumber
        trackingNumber
        orderId
        id
        consignmentDate
        supplier
      }
    }
  }
`;

export const CONFIRM_INWARD_ITEM = `
  mutation ConfirmInwardItem($item: InwardItemInput!, $items: [InwardItemInput!]) {
    confirmInwardItem(item: $item, items: $items) {
      message
    }
  }
`;

export const SUBMIT_NUMBER_OF_CARTONS = `
  mutation SubmitNumberOfCartons($consignmentId: ID, $numberOfCartons: Int, $bestByDate: Date) {
    submitNumberOfCartons(consignmentId: $consignmentId, numberOfCartons: $numberOfCartons, bestByDate: $bestByDate) {
      message
    }
  }
`;

export const ADD_LPN_TO_PRODUCT = `
  mutation addLpnToProduct($lpn: String, $productId: ID) {
    addLpnToProduct(lpn: $lpn, productId: $productId) {
      message
    }
  }
`;

export const ADD_TAGS_TO_PRODUCTS = `
  mutation addTagsToProducts($productIds: [ID!]!, $tags: [TagInput!]!) {
    addTagsToProducts(productIds: $productIds, tags: $tags) {
      message
    }
  }
`;

export const GENERATE_LPN = `
  mutation GenerateLpn($formFactor: String, $products: [Random], $quantity: Int, $trackingNumber: [String], $customer: ID) {
    generateLpn(formFactor: $formFactor, products: $products, quantity: $quantity, customer: $customer, trackingNumber: $trackingNumber) {
      message
      generatedCodes
    }
  }
`;

// STANDALONE PUTAWAY

export const SCAN_STANDALONE_PUTAWAY_BARCODE = `
  mutation ScanStandalonePutawayBarcode($code: String!, $customer: String, $consignmentId: String, $receivingBatchId: String) {
    scanStandalonePutawayBarcode(code: $code, customer: $customer, consignmentId: $consignmentId, receivingBatchId: $receivingBatchId) {
      message
    }
  }
`;

export const CONFIRM_STANDALONE_PUTAWAY_ITEM = `
  mutation ConfirmStandalonePutawayItem($item: StandalonePutawayItemInput!) {
    confirmStandalonePutawayItem(item: $item) {
      message
    }
  }
`;

export const CANCEL_STANDALONE_PUTAWAY_ITEM = `
  mutation CanceltandalonePutawayItem($id: ID!) {
    cancelStandalonePutawayItem(id: $id) {
      message
    }
  }
`;

// CONSIGNMENT

export const SAVE_CONSIGNMENT = `
mutation saveConsignment(
  $trackingNumber: [String]
  $items: [ConsignmentInputItem!]
  $customer: ID
  $warehouse: ID
  $consignmentNumber: String
  $orderId: String
  $id: ID
  $supplier: String
  $carrier: String
  $consignmentDate: Date
  $notes: String
  $isReturnOrder: Boolean
  $linkedOrders: [Random]
  $status: String
  $dropship: Boolean
  $dropshipType: String
  $typeOfShipment: String
  $shippingAddress: ShippingAddressInput
  $dropshipData: Random
  $workingList: [Random]
  $isCasePack: Boolean
  $sellerId: String
) {
  saveConsignment(
    consignmentInput: {
      trackingNumber: $trackingNumber
      items: $items
      customer: $customer
      warehouse: $warehouse
      consignmentNumber: $consignmentNumber
      orderId: $orderId
      id: $id
      supplier: $supplier
      carrier: $carrier
      consignmentDate: $consignmentDate
      notes: $notes
      isReturnOrder: $isReturnOrder
      linkedOrders: $linkedOrders
      status: $status
      dropship: $dropship
      dropshipType: $dropshipType
      shippingAddress: $shippingAddress
      typeOfShipment: $typeOfShipment
      dropshipData: $dropshipData
      workingList: $workingList
      isCasePack: $isCasePack
      sellerId: $sellerId
    }
  ) {
    message
  }
}
`;

export const SAVE_CONSIGNMENT_NOTES = `
  mutation saveConsignment(
    $id: ID
    $workingList: [Random]
  ) {
    saveConsignmentNotes(
      consignmentInput: {
        id: $id
        workingList: $workingList
      }
    ) {
      message
    }
  }
`;

export const RECEIVE_CONSIGNMENT = `
  mutation ReceivingConsignment(
    $id: ID!
    $lotId: String
    $palletId: String
    $bestByDate: Date
    $binLocation: String
    $items: [ManualInwardItem!]
  ) {
    receiveConsignment(
      id: $id
      lotId: $lotId
      palletId: $palletId
      bestByDate: $bestByDate
      binLocation: $binLocation
      items: $items
    ) {
      message
    }
  }
`;

export const RECEIVE_CONSIGNMENT_CURRENT = `
  mutation ReceivingConsignmentCurrent(
    $id: ID!
    $isPartiallyReceived: Boolean!
  ) {
    receiveConsignmentCurrent(
      id: $id
      isPartiallyReceived: $isPartiallyReceived
    ) {
      message
    }
  }
`;

export const DELETE_CONSIGNMENT = `
  mutation DeleteConsignment($id: ID!) {
    deleteConsignment(id: $id) {
      message
    }
  }
`;

export const UNDO_CONSIGNMENT_RECEIVE = `
  mutation UndoConsignmentReceive($consignmentId : ID!) {
    undoConsignmentReceive(consignmentId : $consignmentId) {
      message
    }
  }
`;

// PREPPER

export const SCAN_PREPPER_BARCODE = `
  mutation ScanPrepperBarcode(
    $code: String!
    $username: String
    $warehouse: String
  ) {
    scanPrepperBarcode(
      code: $code
      username: $username
      warehouse: $warehouse
    ) {
      message
      batch {
        id
        status
        workingList {
          uniqueIdentifier
          quantity
          sku
          fnSku
          asin
          sellerSku
          upc
          status
          productName
          order
          id
          bestByDate
          boxName
          bundleId
          bundle
          prepInstructions {
            PrepInstruction
            PrepOwner
          }
          additionalPrepInstructions
          productId
          mainEntity
          availableQuantity
          warehouse
          binLocation
        }
        completion
        currentItem {
          binLocation
          uniqueIdentifier
          productName
          productImage
          sku
          fnSku
          asin
          sellerSku
          upc
          quantity
          orderQuantityText
          itemQuantityText
          boxCode
          id
          attributes {
            size
            color
            collection
            pattern
            sizeName
            colorName
            collectionName
            patternName
            height
            weight
            length
            width
            commodityCode
          }
          order
          bestByDate
          boxName
          bundleId
          bundle
          validScans {
            scannedItem
            productName
            productImage
            sku
            fnSku
            asin
            sellerSku
            upc
            id
          }
          additionalPrepInstructions
          productId
          mainEntity
          availableQuantity
          customAttributes
          warehouse
          productAlias
        }
        attributes {
          dropoffStation
          orderIds
          orders {
            id
            incoterms
            orderStatus
            carrier
            orderDate
            shippingAddress {
              line1
              line2
              zip
              country
              state
              name
              email
              phone
              city
            }
            billingAddress {
              line1
              line2
              zip
              country
              state
              name
              email
              phone
              city
            }
            shipmentPlan
            reference
            orderId
            orderValue
            orderLineItems {
              reference
              binLocation
              sku
              fnSku
              asin
              sellerSku
              upc
              quantity
              availableQuantity
              baseUom
              uomConfiguration {
                baseUom
                baseUomQuantity
                targetUom
                isActive
              }
              price
              id
              productId
              productName
              productImage
              uniqueIdentifier
              bestByDate
              status
              formFactor
              serialNumber
              name
              fulfillmentType
              lotId
              nestedFormFactorId
              palletId
              marketplaceAttributes {
                marketplace
                attributes
              }
              typeOfProduct
              products {
                id
                sku
                quantityInBundle
                quantityToFulfill
                availableToShip
              }
              availableToShip
            }
            orderProducts {
              reference
              binLocation
              sku
              fnSku
              asin
              sellerSku
              upc
              quantity
              availableQuantity
              baseUom
              price
              id
              productId
              productName
              productImage
              uniqueIdentifier
              bestByDate
              status
              formFactor
              serialNumber
              name
              fulfillmentType
              lotId
              nestedFormFactorId
              palletId
              typeOfProduct
              availableToShip
            }
            subStatus
            exceptions {
              orderLineItem {
                reference
                binLocation
                sku
                fnSku
                asin
                sellerSku
                upc
                quantity
                availableQuantity
                baseUom
                price
                id
                productId
                productName
                productImage
                uniqueIdentifier
                bestByDate
                status
                formFactor
                serialNumber
                name
                fulfillmentType
                lotId
                nestedFormFactorId
                palletId
                typeOfProduct
                availableToShip
              }
              description
              typeOfException
            }
            shippingLabel
            tote
            subTote
            trackingNumber
            shippingStatus
            cannotFulfil
            formFactor
            trackingUrl
            source
            orderType
            toWarehouse
            email
            bol
            shippingLabels
            trackingNumbers
            invoiceUrls
            boxInformationNeeded
            fbaAudit
            typeOfShipment
            confirmedTypeOfShipment
            boxLabels
            pallets {
              name
              length
              width
              height
              weight
            }
            palletLabels
            transportFailed
            hazmatConfirmed
            dropship
            consignmentId
            verifiedLabelUpload
            shippingRequests
            carrierService
            attributes
            insuranceRequired
            insuranceProvider
            insuredValue
            notes
            completedDate
            bolData {
              bolSealNumber
              temperature
              recorder
            }
            bolNumber
            bolSealNumber
            scacCode
            dateOfShipping
            toValidAddress
            warehouse
            customer
            selectedCarrierServiceId
            bypassCarrierSelection
            selectedCarrierSource
            classification
            isCasePack
            customAttributes
            preSelectedCarrierRate {
              id
              type
              carrier
              source
              service
              price
              currency
              estimated_delivery_date
              estimated_delivery_days
            }
            estimatedBoxes {
              length
              width
              height
              totalWeight
              boxCount
            }
            proNumber
            numberOfProducts
            totalQuantity
            multipleLineItems
            marketplaceCountryCode
            region
            sellerId
            childOrders
            splitOrders
            originalOrder
            isPartneredCarrier
            deliveryDate
            thirdPartyFreightChargesBill
            carrierIntegration {
              carrier
              carrierService
              notes
            }
          }
          multipleLineItemBatch
          orderLineItemSubTotes
          orderSubTotes
          humanReadableOrderSubTotes
          humanReadableOrderIds
          subTotes
          subTotePositions
          pickingStrategy
          humanReadableOrderMapping
          humanReadableOrderIdMapping
        }
        customer
        warehouse
        station
        assignedStationId
        tote
        username
      }
    }
  }
`;

export const CONFIRM_PREP_ITEM = `
  mutation ConfirmPrepItem(
    $id: ID!
    $status: String!
    $itemId: String
    $quantity: Int
    $bestByDate: Date
    $additionalPrepInstructions: [String]
  ) {
    confirmPrepItem(
      id: $id
      status: $status
      itemId: $itemId
      quantity: $quantity
      bestByDate: $bestByDate
      additionalPrepInstructions: $additionalPrepInstructions
    ) {
      message
      batch {
        id
        status
        workingList {
          uniqueIdentifier
          quantity
          sku
          fnSku
          asin
          sellerSku
          upc
          status
          productName
          order
          id
          bestByDate
          boxName
          bundleId
          bundle
          prepInstructions {
            PrepInstruction
            PrepOwner
          }
          additionalPrepInstructions
          productId
          mainEntity
          availableQuantity
          warehouse
          binLocation
        }
        completion
        currentItem {
          binLocation
          uniqueIdentifier
          productName
          productImage
          sku
          fnSku
          asin
          sellerSku
          upc
          quantity
          orderQuantityText
          itemQuantityText
          boxCode
          id
          attributes {
            size
            color
            collection
            pattern
            sizeName
            colorName
            collectionName
            patternName
            height
            weight
            length
            width
            commodityCode
          }
          order
          bestByDate
          boxName
          bundleId
          bundle
          validScans {
            scannedItem
            productName
            productImage
            sku
            fnSku
            asin
            sellerSku
            upc
            id
          }
          additionalPrepInstructions
          productId
          mainEntity
          availableQuantity
          customAttributes
          warehouse
          productAlias
        }
        attributes {
          dropoffStation
          orderIds
          orders {
            id
            incoterms
            orderStatus
            carrier
            orderDate
            shippingAddress {
              line1
              line2
              zip
              country
              state
              name
              email
              phone
              city
            }
            billingAddress {
              line1
              line2
              zip
              country
              state
              name
              email
              phone
              city
            }
            shipmentPlan
            reference
            orderId
            orderValue
            orderLineItems {
              reference
              binLocation
              sku
              fnSku
              asin
              sellerSku
              upc
              quantity
              availableQuantity
              baseUom
              uomConfiguration {
                baseUom
                baseUomQuantity
                targetUom
                isActive
              }
              price
              id
              productId
              productName
              productImage
              uniqueIdentifier
              bestByDate
              status
              formFactor
              serialNumber
              name
              fulfillmentType
              lotId
              nestedFormFactorId
              palletId
              marketplaceAttributes {
                marketplace
                attributes
              }
              typeOfProduct
              products {
                id
                sku
                quantityInBundle
                quantityToFulfill
                availableToShip
              }
              availableToShip
            }
            orderProducts {
              reference
              binLocation
              sku
              fnSku
              asin
              sellerSku
              upc
              quantity
              availableQuantity
              baseUom
              price
              id
              productId
              productName
              productImage
              uniqueIdentifier
              bestByDate
              status
              formFactor
              serialNumber
              name
              fulfillmentType
              lotId
              nestedFormFactorId
              palletId
              typeOfProduct
              availableToShip
            }
            subStatus
            exceptions {
              orderLineItem {
                reference
                binLocation
                sku
                fnSku
                asin
                sellerSku
                upc
                quantity
                availableQuantity
                baseUom
                price
                id
                productId
                productName
                productImage
                uniqueIdentifier
                bestByDate
                status
                formFactor
                serialNumber
                name
                fulfillmentType
                lotId
                nestedFormFactorId
                palletId
                typeOfProduct
                availableToShip
              }
              description
              typeOfException
            }
            shippingLabel
            tote
            subTote
            trackingNumber
            shippingStatus
            cannotFulfil
            formFactor
            trackingUrl
            source
            orderType
            toWarehouse
            email
            bol
            shippingLabels
            trackingNumbers
            invoiceUrls
            boxInformationNeeded
            fbaAudit
            typeOfShipment
            confirmedTypeOfShipment
            boxLabels
            pallets {
              name
              length
              width
              height
              weight
            }
            palletLabels
            transportFailed
            hazmatConfirmed
            dropship
            consignmentId
            verifiedLabelUpload
            shippingRequests
            carrierService
            attributes
            insuranceRequired
            insuranceProvider
            insuredValue
            notes
            completedDate
            bolData {
              bolSealNumber
              temperature
              recorder
            }
            bolNumber
            bolSealNumber
            scacCode
            dateOfShipping
            toValidAddress
            warehouse
            customer
            selectedCarrierServiceId
            bypassCarrierSelection
            selectedCarrierSource
            classification
            isCasePack
            customAttributes
            preSelectedCarrierRate {
              id
              type
              carrier
              source
              service
              price
              currency
              estimated_delivery_date
              estimated_delivery_days
            }
            estimatedBoxes {
              length
              width
              height
              totalWeight
              boxCount
            }
            proNumber
            numberOfProducts
            totalQuantity
            multipleLineItems
            marketplaceCountryCode
            region
            sellerId
            childOrders
            splitOrders
            originalOrder
            isPartneredCarrier
            deliveryDate
            thirdPartyFreightChargesBill
            carrierIntegration {
              carrier
              carrierService
              notes
            }
          }
          multipleLineItemBatch
          orderLineItemSubTotes
          orderSubTotes
          humanReadableOrderSubTotes
          humanReadableOrderIds
          subTotes
          subTotePositions
          pickingStrategy
          humanReadableOrderMapping
          humanReadableOrderIdMapping
        }
        customer
        warehouse
        station
        assignedStationId
        tote
        username
      }
    }
  }
`;

export const CANCEL_PREP_ITEM = `
  mutation CancelPrepItem($id: ID!) {
    cancelPrepItem(id: $id) {
      message
    }
  }
`;

export const UNPREP_ITEM = `
  mutation UnprepItem($id: ID!, $itemIds: [String]) {
    unprepItem(id: $id, itemIds: $itemIds) {
      message
      batch {
        id
        status
        workingList {
          uniqueIdentifier
          quantity
          sku
          fnSku
          asin
          sellerSku
          upc
          status
          productName
          order
          id
          bestByDate
          boxName
          bundleId
          bundle
          prepInstructions {
            PrepInstruction
            PrepOwner
          }
          additionalPrepInstructions
          productId
          mainEntity
          availableQuantity
          warehouse
          binLocation
        }
        completion
        currentItem {
          binLocation
          uniqueIdentifier
          productName
          productImage
          sku
          fnSku
          asin
          sellerSku
          upc
          quantity
          orderQuantityText
          itemQuantityText
          boxCode
          id
          attributes {
            size
            color
            collection
            pattern
            sizeName
            colorName
            collectionName
            patternName
            height
            weight
            length
            width
            commodityCode
          }
          order
          bestByDate
          boxName
          bundleId
          bundle
          validScans {
            scannedItem
            productName
            productImage
            sku
            fnSku
            asin
            sellerSku
            upc
            id
          }
          additionalPrepInstructions
          productId
          mainEntity
          availableQuantity
          customAttributes
          warehouse
          productAlias
        }
        attributes {
          dropoffStation
          orderIds
          orders {
            id
            incoterms
            orderStatus
            carrier
            orderDate
            shippingAddress {
              line1
              line2
              zip
              country
              state
              name
              email
              phone
              city
            }
            billingAddress {
              line1
              line2
              zip
              country
              state
              name
              email
              phone
              city
            }
            shipmentPlan
            reference
            orderId
            orderValue
            orderLineItems {
              reference
              binLocation
              sku
              fnSku
              asin
              sellerSku
              upc
              quantity
              availableQuantity
              baseUom
              uomConfiguration {
                baseUom
                baseUomQuantity
                targetUom
                isActive
              }
              price
              id
              productId
              productName
              productImage
              uniqueIdentifier
              bestByDate
              status
              formFactor
              serialNumber
              name
              fulfillmentType
              lotId
              nestedFormFactorId
              palletId
              marketplaceAttributes {
                marketplace
                attributes
              }
              typeOfProduct
              products {
                id
                sku
                quantityInBundle
                quantityToFulfill
                availableToShip
              }
              availableToShip
            }
            orderProducts {
              reference
              binLocation
              sku
              fnSku
              asin
              sellerSku
              upc
              quantity
              availableQuantity
              baseUom
              price
              id
              productId
              productName
              productImage
              uniqueIdentifier
              bestByDate
              status
              formFactor
              serialNumber
              name
              fulfillmentType
              lotId
              nestedFormFactorId
              palletId
              typeOfProduct
              availableToShip
            }
            subStatus
            exceptions {
              orderLineItem {
                reference
                binLocation
                sku
                fnSku
                asin
                sellerSku
                upc
                quantity
                availableQuantity
                baseUom
                price
                id
                productId
                productName
                productImage
                uniqueIdentifier
                bestByDate
                status
                formFactor
                serialNumber
                name
                fulfillmentType
                lotId
                nestedFormFactorId
                palletId
                typeOfProduct
                availableToShip
              }
              description
              typeOfException
            }
            shippingLabel
            tote
            subTote
            trackingNumber
            shippingStatus
            cannotFulfil
            formFactor
            trackingUrl
            source
            orderType
            toWarehouse
            email
            bol
            shippingLabels
            trackingNumbers
            invoiceUrls
            boxInformationNeeded
            fbaAudit
            typeOfShipment
            confirmedTypeOfShipment
            boxLabels
            pallets {
              name
              length
              width
              height
              weight
            }
            palletLabels
            transportFailed
            hazmatConfirmed
            dropship
            consignmentId
            verifiedLabelUpload
            shippingRequests
            carrierService
            attributes
            insuranceRequired
            insuranceProvider
            insuredValue
            notes
            completedDate
            bolData {
              bolSealNumber
              temperature
              recorder
            }
            bolNumber
            bolSealNumber
            scacCode
            dateOfShipping
            toValidAddress
            warehouse
            customer
            selectedCarrierServiceId
            bypassCarrierSelection
            selectedCarrierSource
            classification
            isCasePack
            customAttributes
            preSelectedCarrierRate {
              id
              type
              carrier
              source
              service
              price
              currency
              estimated_delivery_date
              estimated_delivery_days
            }
            estimatedBoxes {
              length
              width
              height
              totalWeight
              boxCount
            }
            proNumber
            numberOfProducts
            totalQuantity
            multipleLineItems
            marketplaceCountryCode
            region
            sellerId
            childOrders
            splitOrders
            originalOrder
            isPartneredCarrier
            deliveryDate
            thirdPartyFreightChargesBill
            carrierIntegration {
              carrier
              carrierService
              notes
            }
          }
          multipleLineItemBatch
          orderLineItemSubTotes
          orderSubTotes
          humanReadableOrderSubTotes
          humanReadableOrderIds
          subTotes
          subTotePositions
          pickingStrategy
          humanReadableOrderMapping
          humanReadableOrderIdMapping
        }
        customer
        warehouse
        station
        assignedStationId
        tote
        username
      }
    }
  }
`;

export const RESTART_PREP = `
  mutation RestartPrep($id: ID!) {
    restartPrep(id: $id) {
      message
      batch {
        id
        status
        workingList {
          uniqueIdentifier
          quantity
          sku
          fnSku
          asin
          sellerSku
          upc
          status
          productName
          order
          id
          bestByDate
          boxName
          bundleId
          bundle
          prepInstructions {
            PrepInstruction
            PrepOwner
          }
          additionalPrepInstructions
          productId
          mainEntity
          availableQuantity
          warehouse
          binLocation
        }
        completion
        currentItem {
          binLocation
          uniqueIdentifier
          productName
          productImage
          sku
          fnSku
          asin
          sellerSku
          upc
          quantity
          orderQuantityText
          itemQuantityText
          boxCode
          id
          attributes {
            size
            color
            collection
            pattern
            sizeName
            colorName
            collectionName
            patternName
            height
            weight
            length
            width
            commodityCode
          }
          order
          bestByDate
          boxName
          bundleId
          bundle
          validScans {
            scannedItem
            productName
            productImage
            sku
            fnSku
            asin
            sellerSku
            upc
            id
          }
          additionalPrepInstructions
          productId
          mainEntity
          availableQuantity
          customAttributes
          warehouse
          productAlias
        }
        attributes {
          dropoffStation
          orderIds
          orders {
            id
            incoterms
            orderStatus
            carrier
            orderDate
            shippingAddress {
              line1
              line2
              zip
              country
              state
              name
              email
              phone
              city
            }
            billingAddress {
              line1
              line2
              zip
              country
              state
              name
              email
              phone
              city
            }
            shipmentPlan
            reference
            orderId
            orderValue
            orderLineItems {
              reference
              binLocation
              sku
              fnSku
              asin
              sellerSku
              upc
              quantity
              availableQuantity
              baseUom
              uomConfiguration {
                baseUom
                baseUomQuantity
                targetUom
                isActive
              }
              price
              id
              productId
              productName
              productImage
              uniqueIdentifier
              bestByDate
              status
              formFactor
              serialNumber
              name
              fulfillmentType
              lotId
              nestedFormFactorId
              palletId
              marketplaceAttributes {
                marketplace
                attributes
              }
              typeOfProduct
              products {
                id
                sku
                quantityInBundle
                quantityToFulfill
                availableToShip
              }
              availableToShip
            }
            orderProducts {
              reference
              binLocation
              sku
              fnSku
              asin
              sellerSku
              upc
              quantity
              availableQuantity
              baseUom
              price
              id
              productId
              productName
              productImage
              uniqueIdentifier
              bestByDate
              status
              formFactor
              serialNumber
              name
              fulfillmentType
              lotId
              nestedFormFactorId
              palletId
              typeOfProduct
              availableToShip
            }
            subStatus
            exceptions {
              orderLineItem {
                reference
                binLocation
                sku
                fnSku
                asin
                sellerSku
                upc
                quantity
                availableQuantity
                baseUom
                price
                id
                productId
                productName
                productImage
                uniqueIdentifier
                bestByDate
                status
                formFactor
                serialNumber
                name
                fulfillmentType
                lotId
                nestedFormFactorId
                palletId
                typeOfProduct
                availableToShip
              }
              description
              typeOfException
            }
            shippingLabel
            tote
            subTote
            trackingNumber
            shippingStatus
            cannotFulfil
            formFactor
            trackingUrl
            source
            orderType
            toWarehouse
            email
            bol
            shippingLabels
            trackingNumbers
            invoiceUrls
            boxInformationNeeded
            fbaAudit
            typeOfShipment
            confirmedTypeOfShipment
            boxLabels
            pallets {
              name
              length
              width
              height
              weight
            }
            palletLabels
            transportFailed
            hazmatConfirmed
            dropship
            consignmentId
            verifiedLabelUpload
            shippingRequests
            carrierService
            attributes
            insuranceRequired
            insuranceProvider
            insuredValue
            notes
            completedDate
            bolData {
              bolSealNumber
              temperature
              recorder
            }
            bolNumber
            bolSealNumber
            scacCode
            dateOfShipping
            toValidAddress
            warehouse
            customer
            selectedCarrierServiceId
            bypassCarrierSelection
            selectedCarrierSource
            classification
            isCasePack
            customAttributes
            preSelectedCarrierRate {
              id
              type
              carrier
              source
              service
              price
              currency
              estimated_delivery_date
              estimated_delivery_days
            }
            estimatedBoxes {
              length
              width
              height
              totalWeight
              boxCount
            }
            proNumber
            numberOfProducts
            totalQuantity
            multipleLineItems
            marketplaceCountryCode
            region
            sellerId
            childOrders
            splitOrders
            originalOrder
            isPartneredCarrier
            deliveryDate
            thirdPartyFreightChargesBill
            carrierIntegration {
              carrier
              carrierService
              notes
            }
          }
          multipleLineItemBatch
          orderLineItemSubTotes
          orderSubTotes
          humanReadableOrderSubTotes
          humanReadableOrderIds
          subTotes
          subTotePositions
          pickingStrategy
          humanReadableOrderMapping
          humanReadableOrderIdMapping
        }
        customer
        warehouse
        station
        assignedStationId
        tote
        username
      }
    }
  }
`;

export const PAUSE_PREP = `
  mutation PausePrep($id: ID!) {
    pausePrep(id: $id) {
      message
    }
  }
`;

export const CONFIRM_PREPPER_DROPOFF = `
  mutation ConfirmPrepperDropoff($id: ID!) {
    confirmPrepperDropoff(id: $id) {
      message
    }
  }
`;

// BUNDLER

export const GET_BUNDLING_TASKS = `
  mutation GetBundlingTasks($warehouse: ID!, $tote: String) {
    getBundlingTasks(warehouse: $warehouse, tote: $tote) {
      id
      user
      warehouse
      tenant
      station
      type
      inventory {
        productId
        productName
        sku
        hopstackSkuId
        fnSku
        sellerSku
        asin
        upc
        lpn
        quantity
        scannedQuantity
        formFactor
        status
        binLocation
        isBinScanned
      }
      bundledInventory {
        productId
        productName
        sku
        hopstackSkuId
        fnSku
        sellerSku
        asin
        upc
        lpn
        quantity
        scannedQuantity
        formFactor
        status
        uniqueIdentifier
        products {
          productId
          productName
          sku
          hopstackSkuId
          fnSku
          sellerSku
          asin
          upc
          lpn
          quantity
          scannedQuantity
          formFactor
          status
          binLocation
          isBinScanned
        }
      }
      currentItem {
        productId
        productName
        sku
        hopstackSkuId
        fnSku
        sellerSku
        asin
        upc
        lpn
        quantity
        scannedQuantity
        formFactor
        status
        binLocation
        isBinScanned
      }
      currentBundledItem {
        productId
        productName
        sku
        hopstackSkuId
        fnSku
        sellerSku
        asin
        upc
        lpn
        quantity
        scannedQuantity
        formFactor
        status
        uniqueIdentifier
        products {
          productId
          productName
          sku
          hopstackSkuId
          fnSku
          sellerSku
          asin
          upc
          lpn
          quantity
          scannedQuantity
          formFactor
          status
          binLocation
          isBinScanned
        }
      }
      completion
      tote
      status
      details
      totalConsumedQuantity
      totalScannedQuantity
      parents {
        id
        quantityConsumed
      }
      children {
        id
        quantityConsumed
      }
    }
  }
`;

export const SCAN_BUNDLE = `
  mutation ScanBundle($task: ID!, $barcode: String!, $station: ID) {
    scanBundle(task: $task, barcode: $barcode, station: $station) {
      id
      user
      warehouse
      tenant
      station
      type
      inventory {
        productId
        productName
        sku
        hopstackSkuId
        fnSku
        sellerSku
        asin
        upc
        lpn
        quantity
        scannedQuantity
        formFactor
        status
        binLocation
        isBinScanned
      }
      bundledInventory {
        productId
        productName
        sku
        hopstackSkuId
        fnSku
        sellerSku
        asin
        upc
        lpn
        quantity
        scannedQuantity
        formFactor
        status
        uniqueIdentifier
        products {
          productId
          productName
          sku
          hopstackSkuId
          fnSku
          sellerSku
          asin
          upc
          lpn
          quantity
          scannedQuantity
          formFactor
          status
          binLocation
          isBinScanned
        }
      }
      currentItem {
        productId
        productName
        sku
        hopstackSkuId
        fnSku
        sellerSku
        asin
        upc
        lpn
        quantity
        scannedQuantity
        formFactor
        status
        binLocation
        isBinScanned
      }
      currentBundledItem {
        productId
        productName
        sku
        hopstackSkuId
        fnSku
        sellerSku
        asin
        upc
        lpn
        quantity
        scannedQuantity
        formFactor
        status
        uniqueIdentifier
        products {
          productId
          productName
          sku
          hopstackSkuId
          fnSku
          sellerSku
          asin
          upc
          lpn
          quantity
          scannedQuantity
          formFactor
          status
          binLocation
          isBinScanned
        }
      }
      completion
      tote
      status
      details
      parents {
        id
        quantityConsumed
      }
      children {
        id
        quantityConsumed
      }
    }
  }
`;

export const LABEL_EVENT = `
  mutation LabelEvent($customer: ID!, $warehouse: ID!, $order: ID, $quantity: Int!, $labelType: String!, $dropship: Boolean) {
    labelEvent(customer: $customer, warehouse: $warehouse, order: $order, quantity: $quantity, labelType: $labelType, dropship: $dropship) {
      message
    }
  }`;

export const SCAN_BUNDLING_ITEM = `
  mutation ScanItemInBundle($task: ID!, $childTask: ID!, $barcode: String!) {
    scanItemInBundle(task: $task, childTask: $childTask, barcode: $barcode) {
      message
    }
  }
`;

export const COMPLETE_BUNDLING_ITEM = `
  mutation CompleteItemInBundle($task: ID!, $childTask: ID!, $quantity: Int!) {
    completeItemInBundle(task: $task, childTask: $childTask, quantity: $quantity) {
      message
    }
  }
`;

export const COMPLETE_BUNDLE = `
  mutation CompleteBundle($task: ID!, $childTask: ID!, $barcode: String!, $quantity: Int!) {
    completeBundle(task: $task, childTask: $childTask, barcode: $barcode, quantity: $quantity) {
      message
    }
  }
`;

export const DELETE_BUNDLING_CHILD_TASK = `
  mutation DeleteBundlingChildTask($task: ID!, $childTask: ID!) {
    deleteBundlingChildTask(task: $task, childTask: $childTask) {
      message
    }
  }
`;

// ENTITY TYPES

export const SAVE_ENTITY_TYPE = `
  mutation SaveEntityType($entityTypeInput: EntityTypeInput) {
    saveEntityType(
      entityTypeInput: $entityTypeInput
    ) {
      message
    }
  }
`;

export const DELETE_ENTITY_TYPE = `
  mutation DeleteEntityType($deleteEntityTypeId: ID!) {
    deleteEntityType(id: $deleteEntityTypeId) {
      message
    }
  }
`;

// RULE ENGINE

export const SAVE_CONFIGURATION = `
  mutation saveConfiguration($configurationInput: ConfigurationInput) {
    saveConfiguration(configurationInput: $configurationInput) {
      message
    }
  }
`;

// NOTIFICATIONS
export const STRIPE_PAY = `
  mutation StripePay($name: String, $amount: String) {
    pay(name: $name, amount: $amount) {
      message
      url
    }
  }
`;

export const APPROVE_NOTIFICATION = `
  mutation ApproveNotification($id: ID!, $body: Random, $remarks: String) {
    approveNotification(id: $id, body: $body, remarks: $remarks) {
      message
    }
  }`;

// BILLING

export const SAVE_BILLING_PROFILE = `
  mutation SaveBillingProfile($name: String, $currency: String, $metricSystem: String, $status: String, $tariffs: [TariffInput], $id: ID) {
    saveBillingProfile(name: $name, currency: $currency, metricSystem: $metricSystem, status: $status, tariffs: $tariffs, id: $id) {
      message
    }
  }
`;

export const ASSIGN_BILLING_PROFILE = `
  mutation AssignBillingProfile($customerId: ID!, $profileId: ID) {
    assignBillingProfile(customerId: $customerId, profileId: $profileId) {
      message
      url
    }
  }
`;

export const DELETE_BILLING_PROFILE = `
  mutation DeleteBillingProfile ($id: String) {
    deleteBillingProfile (id: $id) {
      message
    }
  }
`;

export const REJECT_NOTIFICATION = `
  mutation RejectNotification($id: ID!, $remarks: String) {
    rejectNotification(id: $id, remarks: $remarks) {
      message
    }
  }
`;

export const SAVE_INVOICE = `
  mutation SaveInvoice($invoiceInput: InvoiceInput!) {
    saveInvoice (invoiceInput: $invoiceInput) {
      message
    }
  }
`;
// ORDER

export const DELETE_INVOICE = `
  mutation DeleteInvoice($id: String) {
    deleteInvoice(id: $id) {
      message
    }
  }
`;

export const SAVE_ORDER = `
  mutation saveOrder(
    $trackingNumber: String
    $orderLineItems: [OrderLineItemInput]
    $customer: ID
    $warehouse: ID
    $orderId: String
    $id: ID
    $carrier: String
    $orderDate: Date
    $workflowType: String
    $shippingAddress: ShippingAddressInput
    $source: String
    $orderType: String
    $toWarehouse: ID
    $carrierService: String
    $insuranceRequired: Boolean
    $insuranceProvider: String
    $insuredValue: Float
    $toValidAddress: Boolean
    $preSelectedCarrierRate: SelectedCarrierRateInput
    $typeOfShipment: String
    $estimatedBoxes: [OrderEstimatedBoxInput]
    $inboundPlanId: String
    $reference: String
  ) {
    saveOrder(
      orderInput: {
        trackingNumber: $trackingNumber
        orderLineItems: $orderLineItems
        customer: $customer
        warehouse: $warehouse
        orderId: $orderId
        id: $id
        carrier: $carrier
        orderDate: $orderDate
        workflowType: $workflowType
        shippingAddress: $shippingAddress
        source: $source
        orderType: $orderType
        toWarehouse: $toWarehouse
        carrierService: $carrierService
        insuranceRequired: $insuranceRequired
        insuranceProvider: $insuranceProvider
        insuredValue: $insuredValue
        toValidAddress: $toValidAddress
        preSelectedCarrierRate: $preSelectedCarrierRate
        typeOfShipment: $typeOfShipment
        estimatedBoxes: $estimatedBoxes
        inboundPlanId: $inboundPlanId
        reference: $reference
      }
    ) {
      message
      id
      orderId
    }
  }
`;

export const SAVE_BULK_ORDER = `
  mutation saveBulkOrder(
    $bulkOrderInput: BulkOrderInput
  ) {
    saveBulkOrder(
      bulkOrderInput: $bulkOrderInput
    ) {
      message
    }
  }
`;

export const UPDATE_ORDER = `
  mutation UpdateOrder($orderInput: EditOrderInput, $user: ID) {
    updateOrder(orderInput: $orderInput, user: $user) {
      message
    }
  } 
`;

export const DELETE_ORDER = `
  mutation DeleteOrder($id: ID) {
    deleteOrder(id: $id) {
      message
    }
  }
`;

export const CANCEL_ORDERS = `
  mutation CancelOrders($ids: [ID], $reason: String, $markCancelOnMarketplace: Boolean) {
    cancelOrders(ids: $ids, reason: $reason, markCancelOnMarketplace: $markCancelOnMarketplace) {
      message
      url
    }
  }
`;

export const UPDATE_ORDER_LINE_ITEM = `
  mutation updateOrderLineItem($orderLineItemInput: Random) {
    updateOrderLineItem(orderLineItemInput: $orderLineItemInput) {
      message
    }
  }
`;

// MATERIALS

export const SAVE_MATERIAL = `
  mutation SaveMaterial($materialInput: MaterialInput) {
    saveMaterial(materialInput: $materialInput) {
      message
    }
  }
`;

export const DELETE_MATERIAL = `
  mutation DeleteMaterial($id: ID!) {
    deleteMaterial(id: $id) {
      message
    }
  }
`;

export const IMMEDIATE_SCAN = `
  mutation ImmediateScan($barcode: String!) {
    immediateScan(barcode: $barcode) {
      barcode
    }
  }
`;

export const CANCEL_RECEIVING_ITEM = `
  mutation CancelReceivingItem($id: ID!) {
    cancelReceivingItem(id: $id) {
      message
    }
  }
`;

export const DELETE_STOCK_TRANSFER = `
  mutation DeleteStockTransfer($id: ID!) {
    deleteStockTransfer(id: $id) {
        message
      url
    }
  }
`;

export const SAVE_CYCLE_COUNT_PLAN = `
  mutation SaveCycleCountPlan($cycleCountPlanInput: CycleCountPlanInput) {
    saveCycleCountPlan(cycleCountPlanInput: $cycleCountPlanInput) {
      message
    }
  }
`;

export const DELETE_CYCLE_COUNT_PLAN = `
  mutation DeleteCycleCountPlan($id: ID!) {
    deleteCycleCountPlan(id: $id) {
      message
    }
  }
`;

export const MOVE_STOCK = `
mutation MoveStock($sourceProductId: ID) {
  moveStock(sourceProductId: $sourceProductId) {
    message
  }
}`;

export const SAVE_STOCK_TRANSFER = `
  mutation SaveStockTransfer($stockTransferInput: StockTransferInput) {
    saveStockTransfer(stockTransferInput: $stockTransferInput) {
      message
      url
    }
  }
`;

export const SCAN_STOCK_TRANSFER_COUNTER_BARCODE = `
  mutation ScanCounterBarcodeStockTransfer($code: String!) {
    scanCounterBarcodeStockTransfer(code: $code) {
      message
      url
    }
  }
`;

export const CONFIRM_STOCK_TRANSFER_COUNT_ITEM = `
  mutation ConfirmCounterItemStockTransfer(
    $id: ID!
    $item: StockTransferConfirmItem!
  ) {
    confirmCounterItemStockTransfer(id: $id, item: $item) {
      message
      url
    }
  }
`;

export const SCAN_CYCLE_COUNTER_BARCODE = `
  mutation ScanCounterBarcode($code: String!) {
    scanCounterBarcode(code: $code) {
      message
    }
  }
`;

export const CONFIRM_CYCLE_COUNT_ITEM = `
  mutation ConfirmCycleCountItem($id: ID!, $item: CycleCounterConfirmItem!) {
    confirmCounterItem(id: $id, item: $item) {
      message
    }
  }
`;

export const EXECUTE_STOCK_TRANSFER_COUNT_PLAN = `
  mutation ExecuteStockTransfer($executeTransferInput: ExecuteTransferInput!) {
    executeStockTransfer(executeTransferInput: $executeTransferInput) {
      message
    }
  }
`;

export const EXECUTE_CYCLE_COUNT_PLAN = `
  mutation ExecuteCycleCountPlan($executePlanInput: ExecutePlanInput!) {
    executeCycleCountPlan(executePlanInput: $executePlanInput) {
      message
    }
  }
`;

export const CANCEL_CURRENT_STOCK_ITEM = `
  mutation CancelCurrentItemStockTransfer {
    cancelCurrentItemStockTransfer {
      message
      url
    }
  }
`;

export const SAVE_VENDOR = `
  mutation SaveVendor($vendorInput: VendorInput) {
    saveVendor(vendorInput: $vendorInput) {
      message
    }
  }
`;

export const DELETE_VENDOR = `
  mutation DeleteVendor($id: ID!) {
    deleteVendor(id: $id) {
      message
    }
  }
`;
export const BULK_UPLOAD_WAREHOUSE = `
 mutation BulkUploadWarehouses($rows: BulkUploadInput!) {
  bulkUploadWarehouses(rows: $rows) {
    error
    errors
    message
  }
}
 `;

export const BULK_UPLOAD_CUSTOMER = `
 mutation BulkUploadCustomers($rows: BulkUploadInput!) {
  bulkUploadCustomers(rows: $rows) {
    error
    errors
    message
  }
}
 `;

export const BULK_UPLOAD_ORDER = `
 mutation bulkUploadOrders($rows: BulkUploadInput!, $timezone: String!) {
  bulkUploadOrders(rows: $rows, timezone: $timezone) {
    error
    errors
    message
  }
}
 `;

export const BULK_UPLOAD_USER = `
 mutation BulkUploadUsers($rows: BulkUploadInput!) {
  bulkUploadUsers(rows: $rows) {
    error
    errors
    message
  }
}
 `;

export const BULK_UPLOAD_CONSIGNMENTS = `
 mutation BulkUploadConsignments($rows: BulkUploadInput!) {
  bulkUploadConsignments(rows: $rows) {
    error
    errors
    message
  }
}
 `;

export const BULK_UPLOAD_PRODUCTS = `
 mutation Mutation($rows: BulkUploadInput!) {
  bulkUploadProducts(rows: $rows) {
    error
    errors
    message
  }
}
 `;

export const BULK_UPLOAD_SKU_BIN_MAPPINGS = `
 mutation Mutation($rows: BulkUploadInput!) {
  bulkUploadSkuBinMappings(rows: $rows) {
    error
    errors
    message
  }
}
 `;

export const BULK_UPLOAD_BIN_LOCATIONS = `
 mutation BulkUploadBinLocations($rows: BulkUploadInput!) {
  bulkUploadBinLocations(rows: $rows) {
    error
    errors
    message
  }
}
 `;

export const BULK_UPLOAD_TOTES = `
 mutation BulkUploadTotes($rows: BulkUploadInput!) {
  bulkUploadTotes(rows: $rows) {
    error
    errors
    message
  }
}
 `;

export const BULK_UPLOAD_STATION_FIELDS = `
 mutation BulkUploadStations($rows: BulkUploadInput!) {
  bulkUploadStations(rows: $rows) {
    error
    errors
    message
  }
}
 `;

export const BULK_UPLOAD_VENDOR_FIELDS = `
  mutation BulkUploadVendors($rows: BulkUploadInput!) {
    bulkUploadVendors(rows: $rows) {
      error
      errors
      message
    }
  }
`;

export const CHANGE_CONSIGNMENT_STATUS = `
  mutation ChangeConsignmentStatus(
    $id: ID!
    $status: String!
    $reason: String
  ) {
    changeConsignmentStatus(id: $id, status: $status, reason: $reason) {
      message
      url
    }
  }
`;
export const LOG_LABEL_PRINT = `
  mutation LogLabelPrint($type: String, $customer: String, $quantity: Int, $code: String) {
    logLabelPrint(type: $type, customer: $customer, quantity: $quantity, code: $code) {
      message
    }
  }
}
`;

export const SAVE_REPORT = `
mutation CreateReport($createReportInput: CreateReportInput!) {
  createReport(createReportInput: $createReportInput) {
    message
    error
  }
}
`;

export const START_REPORT_GENERATION = `
mutation GenerateReport($generateReportId: ID!, $reportType: String) {
  generateReport(id: $generateReportId, reportType: $reportType) {
    message
    error
  }
}
`;

export const DELETE_REPORT = `
mutation DeleteReport($deleteReportId: ID!, $module: String) {
  deleteReport(id: $deleteReportId, module: $module) {
    message
    error
  }
}
`;

export const UPDATE_REPORT = `
mutation UpdateReport($updateReportInput: UpdateReportInput!) {
  updateReport(updateReportInput: $updateReportInput) {
    message
    error
  }
}
`;

export const UPDATE_FBA_SHIPMENT = `
mutation UpdateFbaShipment($customer: ID!, $warehouse: ID!, $shipmentId: String!, $shipmentName: String!, $senderAddress: ShippingAddressInput!, $labelPrepPreference: String!, $destinationFulfillmentCenterId: String!, $shipmentStatus: String!, $items: [UpdateFbaShipmentItem!]!) {
  updateFbaShipment(customer: $customer, warehouse: $warehouse, shipmentId: $shipmentId, shipmentName: $shipmentName, senderAddress: $senderAddress, labelPrepPreference: $labelPrepPreference, destinationFulfillmentCenterId: $destinationFulfillmentCenterId, shipmentStatus: $shipmentStatus, items: $items) {
    message
    url
  }
}`;

export const ASSIGN_WAREHOUSE_TO_ORDER = `
mutation AssignWarehouseToOrder($orderId: ID!, $warehouseId: ID!) {
  assignWarehouseToOrder(orderId: $orderId, warehouseId: $warehouseId) {
    message
  }
}
`;

export const ASSIGN_BATCH_PICKER = `
  mutation AssignBatchPicker($batchId: ID!, $userId: ID) {
    updateBatchUser (batchId: $batchId, userId: $userId) {
      message
    }
  }
`;

export const PRIORITIZE_BATCH = `
  mutation PrioritizeBatch($batchId: ID!) {
    updateBatchPriority (batchId: $batchId) {
      message
    }
  }
`;

export const DELETE_BATCH = `
  mutation DeleteBatch($batchId: ID!) {
    deleteBatch(batchId: $batchId) {
      message
    }
  }
`;

// SHIPPERS

export const SAVE_SHIPPER = `
  mutation saveShipper(
    $name: String
    $id: ID
    $warehouses: [ID!]
  ) {
    saveShipper(
      shipperInput: {
        name: $name
        id: $id
        warehouses: $warehouses
      }
    ) {
      message
    }
  }
`;

export const DELETE_SHIPPER = `
  mutation DeleteShipper($id: ID!) {
    deleteShipper(id: $id) {
      message
    }
  }
`;

export const GENERATE_TRUCK_ID = `
  mutation GenerateTruckId($filters: TruckFilter) {
    generateTruckId(filters: $filters) {
      name
    }
  }
`;

export const GENERATE_BARCODE = `
  mutation GenerateBarcode($id: ID, $filters: TruckFilter) {
    generateBarcode(id: $id, filters: $filters) {
      name
    }
  }
`;

export const RESET_ALL_BARCODES = `
  mutation ResetAllBarcodes($filters: TruckFilter) {
    resetAllBarcodes(filters: $filters) {
      message
    }
  }
`;

export const RESET_BARCODE = `
  mutation ResetBarcode(id: ID, $filters: TruckFilter) {
    resetBarcode(id: $id, filters: $filters) {
      name
    }
  }
`;

export const IMPORT_ORDERS_FROM_MARKETPLACE = `
  mutation ImportOrdersFromMarketplace(
    $warehouse: ID!
    $customer: ID!
    $marketplace: String!
    $shipmentIds: [String!]!
    $sellerId: String
    $marketplaceCountryCode: String
    $region: String
  ) {
    importOrdersFromMarketplace(
      warehouse: $warehouse
      customer: $customer
      marketplace: $marketplace
      shipmentIds: $shipmentIds
      sellerId: $sellerId
      marketplaceCountryCode: $marketplaceCountryCode
      region: $region
    ) {
      message
      shipmentId
      isSavedSuccessfully
    }
  }
`;

export const SAVE_BATCH_SETTINGS = `
  mutation SaveBatchSettings($batchSettingsInput: BatchSettingsInput) {
    saveBatchSettings(batchSettingsInput: $batchSettingsInput) {
      message
    }
  }
`;

export const CREATE_LOCATION = `
mutation CreateLocation($code: String, $name: String, $parentLocation: ID, $type: ID, $storageType: String, $storageSection: ID, $topLevelLocation: Boolean, $warehouse: ID, $capacity: LocationCapacityInput, $hierarchicalPath: String) {
  createLocation(code: $code, name: $name, parentLocation: $parentLocation, type: $type, storageType: $storageType, storageSection: $storageSection, topLevelLocation: $topLevelLocation, warehouse: $warehouse, capacity: $capacity, hierarchicalPath: $hierarchicalPath) {
    data {
      id
      code
      name
      tenant
      warehouse
      isDeleted
      inactiveReason {
        inactiveType
        referenceId
        reason
      }
      type
      typeName
      storageType
      parentLocation
      reservations
      activityLocks {
        activityId
        type
        products
        startTimestamp
        endTimestamp
      }
      v
      createdAt
      updatedAt
    }
    error
  }
}
`;

export const DELETE_LOCATION = `
mutation DeleteLocation($locationId: ID!) {
  deleteLocation(id: $locationId) {
    data 
    error
  }
}
`;

export const UPDATE_LOCATION = `
mutation UpdateLocation($locationId: ID!, $code: String, $name: String, $locationType: ID, $parentLocation: ID, $storageType: String, $activationStatus: Boolean, $inactiveReason: InactiveReasonInput, $warehouse: ID, $capacity: LocationCapacityInput) {
  updateLocation(id: $locationId, code: $code, name: $name, locationType: $locationType, parentLocation: $parentLocation, storageType: $storageType, activationStatus: $activationStatus, inactiveReason: $inactiveReason, warehouse: $warehouse, capacity: $capacity) {
    data {
      id
      code
      name
      tenant
      warehouse
      isDeleted
      inactiveReason {
        inactiveType
        referenceId
        reason
      }
      type
      typeName
      storageType
      parentLocation
      storageSection
      topLevelLocation
      reservations
      activityLocks {
        activityId
        type
        products
        startTimestamp
        endTimestamp
      }
      v
      createdAt
      updatedAt
      capacity {
        amount
        unit
      }
    }
    error
  }
}
`;

export const UPDATE_MANY_LOCATIONS = `
mutation UpdateManyLocations($ids: [ID], $locationType: ID, $storageType: String, $warehouse: ID) {
  updateManyLocations(ids: $ids, locationType: $locationType, storageType: $storageType, warehouse: $warehouse) {
    data {
      id
      code
      name
      tenant
      warehouse
      isDeleted
      inactiveReason {
        inactiveType
        referenceId
        reason
      }
      type
      storageType
      parentLocation
      reservations
      activityLocks {
        activityId
        type
        products
        startTimestamp
        endTimestamp
      }
      v
      createdAt
      updatedAt
    }
    error
  }
}
`;

export const CREATE_LOCATION_TYPE = `
mutation CreateLocationType($code: String, $name: String) {
  createLocationType(code: $code, name: $name) {
    data {
      id
      name
      code
      tenant
      isDeleted
      createdAt
      updatedAt
      v
    }
    error
  }
}
`;

export const DELETE_LOCATION_TYPE = `
  mutation DeleteLocationType($deleteLocationTypeId: ID) {
  deleteLocationType(id: $deleteLocationTypeId) {
    data
    error
  }
}
`;

export const UPDATE_LOCATION_TYPE = `
mutation UpdateLocationType($updateLocationTypeId: ID, $name: String, $code: String) {
  updateLocationType(id: $updateLocationTypeId, name: $name, code: $code) {
    data {
      id
      name
      code
      tenant
      isDeleted
      createdAt
      updatedAt
      v
    }
    error
  }
}
`;

export const HANDLE_FBA_LABEL_GENERATION = `
  mutation HandleFbaLabelGeneration(
    $batchId: ID!
    $orderId: ID!
    $operation: Int!
    $isPartneredCarrier: Boolean
    $typeOfShipment: String
    $pallets: [AmazonPalletInput!]
    $proNumber: String
    $trackingNumbers: [String!]
    $carrierName: String
    $terminationReason: String
  ) {
    handleFbaLabelGeneration(
      batchId: $batchId
      orderId: $orderId
      operation: $operation
      isPartneredCarrier: $isPartneredCarrier
      typeOfShipment: $typeOfShipment
      pallets: $pallets
      proNumber: $proNumber
      trackingNumbers: $trackingNumbers
      carrierName: $carrierName
      terminationReason: $terminationReason
    ) {
      message
    }
  }
`;

export const CREATE_STORAGE_SECTION = `
mutation CreateStorageSection($code: String, $name: String, $warehouse: String, $hierarchy: [String], $storageType: String, $status: String, $locations: [StorageSectionLocationInput]) {
  createStorageSection(code: $code, name: $name, warehouse: $warehouse, hierarchy: $hierarchy, storageType: $storageType, status: $status, locations: $locations) {
    data {
      name
      code
      id
      storageType
      hierarchy
      status
      tenant
      warehouse
      isDeleted
    }
    error
  }
}
`;

export const DELETE_STORAGE_SECTION = `
mutation DeleteStorageSection($deleteStorageSectionId: ID) {
  deleteStorageSection(id: $deleteStorageSectionId) {
    data {
      message
      url
    }
    error
  }
}`;

export const UPDATE_STORAGE_SECTION = `
mutation UpdateStorageSection($updateStorageSectionId: ID, $status: String, $name: String, $code: String, $storageType: String, $blockLocationsUpdate: Boolean, $sectionInactiveReason: String) {
  updateStorageSection(id: $updateStorageSectionId, status: $status, name: $name, code: $code, storageType: $storageType, blockLocationsUpdate: $blockLocationsUpdate, sectionInactiveReason: $sectionInactiveReason) {
    data {
      name
      code
      id
      storageType
      hierarchy
      status
      tenant
      warehouse
      isDeleted
    }
    error
  }
}
`;

export const CREATE_PRODUCT_LINKAGES = `mutation CreateProductLinkage($productId: String!, $linkedProducts: [LinkedProductsInput]) {
  createProductLinkage(productId: $productId, linkedProducts: $linkedProducts) {
    error
  }
}`;

export const CREATE_PRODUCT_ALIAS = `
mutation CreateProductAlias($entityName: String!, $entityId: String!, $productId: String!, $alias: String!) {
  createProductAlias(entityName: $entityName, entityId: $entityId, productId: $productId, alias: $alias) {
    error
  }
}`;

export const DELETE_PRODUCT_ALIAS = `
mutation DeleteProductAlias($productAliasId: String!) {
  deleteProductAlias(productAliasId: $productAliasId) {
    error
  }
}`;

export const ADD_PURCHASE_ORDER = `
mutation HandlePoOps($purchaseOrderInput: PurchaseOrderInput!) {
  handlePoOps(purchaseOrderInput: $purchaseOrderInput) {
    message
    poId
  }
}`;

export const DELETE_PURCHASE_ORDER = `
mutation DeletePurchaseOrder($poId: ID!) {
  deletePurchaseOrder(poId: $poId) {
    message
    poId
  }
}
`;

export const CANCEL_PURCHASE_ORDER = `
mutation CancelPurchaseOrder($poId: ID!, $cancellationReason: String) {
  cancelPurchaseOrder(poId: $poId, cancellationReason: $cancellationReason) {
    message
    poId
  }
}
`;

export const CREATE_SALES_ORDER = `
mutation CreateSalesOrder($salesOrderInput: SalesOrderInput) {
  createSalesOrder(salesOrderInput: $salesOrderInput) {
    message
  }
}
`;
export const CREATE_CRM_CUSTOMER = `
mutation CreateCRMCustomer($crmCustomerInput: CrmCustomerInput!) {
  createCrmCustomer(crmCustomerInput: $crmCustomerInput) {
    message
  }
}
`;

export const CREATE_CRM_SHIPPING_ADDRESS = `
mutation CreateCrmShippingAddress($createCrmShippingAddressInput: CreateCrmShippingAddressInput!) {
  createCrmShippingAddress(createCrmShippingAddressInput: $createCrmShippingAddressInput) {
    message
  }
}
`;

export const EDIT_CRM_SHIPPING_ADDRESS = `
mutation EditCrmShippingAddress($editCrmShippingAddressInput: CrmAddressInput!, $oldCrmShippingAddress: CrmAddressInput, $crmCustomerId: String) {
  editCrmShippingAddress(editCrmShippingAddressInput: $editCrmShippingAddressInput, oldCrmShippingAddress: $oldCrmShippingAddress, crmCustomerId: $crmCustomerId) {
    message
  }
}
`;

export const DELETE_CRM_SHIPPING_ADDRESS = `
mutation DeleteCrmShippingAddress($deleteCrmShippingAddressId: ID!, $oldCrmShippingAddress: CrmAddressInput, $crmCustomerId: String) {
  deleteCrmShippingAddress(id: $deleteCrmShippingAddressId, oldCrmShippingAddress: $oldCrmShippingAddress, crmCustomerId: $crmCustomerId) {
    message
  }
}`;

export const EDIT_CRM_ACCOUNT_INFO = `
mutation EditCrmCustomer($editCrmCustomerInput: EditCrmCustomerInput!, $oldCrmCustomer: EditCrmCustomerInput, $crmCustomerId: String) {
  editCrmCustomer(editCrmCustomerInput: $editCrmCustomerInput, oldCrmCustomer: $oldCrmCustomer, crmCustomerId: $crmCustomerId) {
    message
  }
}
`;

export const ADD_PAYMENT_GATEWAY_CUSTOMER_PAYMENT_METHOD = `
mutation AddPaymentGatewayCustomerPaymentMethod($uniqueRequestId: String!, $provider: String!, $payload: AddPaymentMethodInput!) {
  addPaymentGatewayCustomerPaymentMethod(uniqueRequestId: $uniqueRequestId, provider: $provider, payload: $payload) {
    customerId
    status
    checkoutUrl
    checkoutSessionDetails {
      id
      clientSecret
      createdAt
      currency
      email
      expiresAt
      paymentIntent
      paymentMethodTypes
      returnUrl
      setupIntent
      successUrl
    }
  }
}
`;

export const CREATE_PAYMENT_GATEWAY_CUSTOMER = `
mutation CreatePaymentGatewayCustomer($uniqueRequestId: String!, $provider: String!, $payload: PaymentGatewayCustomerInput!, $crmCustomerId: String) {
  createPaymentGatewayCustomer(uniqueRequestId: $uniqueRequestId, provider: $provider, payload: $payload, crmCustomerId: $crmCustomerId) {
    message
    data {
      status
      crmCustomerId
      paymentGatewayCustomerId
      paymentGatewayCustomer {
        id
        email
        fullName
        phone
        address {
          line1
          line2
          city
          state
          country
          postalCode
        }
      }
    }
  }
}
`;

export const UPDATE_PAYMENT_GATEWAY_CUSTOMER = `
mutation UpdatePaymentGatewayCustomer($uniqueRequestId: String!, $id: String!, $provider: String!, $payload: PaymentGatewayCustomerInput!) {
  updatePaymentGatewayCustomer(uniqueRequestId: $uniqueRequestId, id: $id, provider: $provider, payload: $payload) {
    id
    email
    fullName
    phone
    address {
      line1
      line2
      city
      state
      country
      postalCode
    }
  }
}
`;

export const BULK_UPDATE_LOCATION = `
mutation BulkUpdateLocations($locations: [BulkUpdateLocationsInput]!) {
  bulkUpdateLocations(locations: $locations) {
    message
    error
  }
}
`;

export const SAVE_SCHEDULE_REPORT = `
mutation CreateJob($createJobInput: CreateJobInput!) {
  createJob(createJobInput: $createJobInput) {
    message
    error
  }
}
`;

export const DELETE_SCHEDULE_REPORT = `
mutation DeleteJob($deleteJobId: ID!) {
  deleteJob(id: $deleteJobId) {
    message
    error
  }
}
`;

export const UPDATE_SCHEDULE_REPORT = `
mutation UpdateJob($updateJobInput: UpdateJobInput!) {
  updateJob(updateJobInput: $updateJobInput) {
    message
    error
  }
}
`;

export const RESERVE_LOCATION = `
mutation ReserveLocation($locationId: ID!, $parentLocation: ID, $reservations: [LocationReservationInput]) {
  reserveLocation(locationId: $locationId, parentLocation: $parentLocation, reservations: $reservations) {
    message
    error
  }
}
`;

export const BULK_UPLOAD_PRESIGNED_URL = `
mutation CreateBulkUpload($createBulkUploadInput: CreateBulkUploadInput!) {
  createBulkUpload(createBulkUploadInput: $createBulkUploadInput) {
    url
    message
    bulkUploadId
    error
  }
}
`;

export const BULK_UPLOAD_UPDATE = `
mutation UpdateBulkUpload($updateBulkUploadInput: UpdateBulkUploadInput!) {
  updateBulkUpload(updateBulkUploadInput: $updateBulkUploadInput) {
    message
    bulkUploadId
    error
  }
}
`;

export const GET_PAST_BULK_UPLOADS = `
  mutation ListBulkUpload($listBulkUploadInput: ListBulkUploadInput!) {
    listBulkUpload(listBulkUploadInput: $listBulkUploadInput) {
      entities {
        id
        fileName
        s3Key
        s3keyError
        fileUploadStatus
        processingStatus
        dateOfUpload
      }
      error
    }
  }
`;

export const GET_SO_PDF_PRESIGNED_URL = `mutation GetCrmSalesOrderPdfPresignedUrl($crmSalesOrderId: String!) {
    getCrmSalesOrderPdfPresignedUrl(crmSalesOrderId: $crmSalesOrderId) {
      downloadStatus
      presignedUrl
    }
  }
`;

export const CREATE_INBOUND_PLAN = `mutation CreateInboundPlan($createInboundPlanInput: CreateInboundPlanInput!) {
  createInboundPlan(createInboundPlanInput: $createInboundPlanInput) {
    inboundPlanId
    message
  }
}
`;

export const START_ELIGIBILITY_CHECK = `mutation StartEligibilityCheck($input: StartEligibilityCheckInput!) {
  startEligibilityCheck(input: $input) {
    workflowId
    errors {
      code
      details
      message
      severity
    }
  }
}
`;

export const ACTIVATE_INBOUND_PLAN = `
  mutation ActivateInboundPlan(
    $activateInboundPlanInput: ActivateInboundPlanInput
  ) {
    activateInboundPlan(activateInboundPlanInput: $activateInboundPlanInput) {
      message
    }
  }
`;

export const START_AMAZON_INBOUND_PLAN_CREATION = `
  mutation StartAmazonInboundPlanCreation(
    $input: StartAmazonInboundPlanCreationInput!
  ) {
    startAmazonInboundPlanCreation(input: $input) {
      status
      errors {
        code
        details
        message
        severity
      }
    }
  }
`;

export const CONFIRM_PACKING_OPTIONS = `mutation ConfirmPackingOption($inboundId: String!, $packingOptionId: String!, $customerId: String!, $warehouseId: String!) {
  confirmPackingOption(inboundId: $inboundId, packingOptionId: $packingOptionId, customerId: $customerId, warehouseId: $warehouseId) {
    status
    errors {
      code
      details
      message
      severity
    }
  }
}`;

export const SET_PACKING_INFORMATION = `mutation SetPackingInformation($inboundId: String!, $packageGroupings: [PackageGroupingInput]!, $customerId: String!, $warehouseId: String!) {
  setPackingInformation(inboundId: $inboundId, packageGroupings: $packageGroupings, customerId: $customerId, warehouseId: $warehouseId) {
    errors {
      code
      details
      message
      severity
    }
    status
  }
}`;

export const CANCEL_AMAZON_INBOUND_PLAN = `mutation CancelAmazonInboundPlan($inboundId: String!, $customerId: String!) {
  cancelAmazonInboundPlan(inboundId: $inboundId, customerId: $customerId) {
    status
    errors {
      code
      details
      message
      severity
    }
  }
}`;

export const GET_PLANTATION_ZONE_CODE = `
mutation GetZipPlantationZoneMapping($zipcode: String!) {
  getZipPlantationZoneMapping(zipcode: $zipcode) {
    mapping {
      id
      zip
      hz
    }
  }
}
`;

export const CONFIRM_PLACEMENT_OPTIONS = `
mutation ConfirmPlacementOption($inboundId: String!, $placementOptionId: String!, $customerId: String!, $warehouseId: String!) {
  confirmPlacementOption(inboundId: $inboundId, placementOptionId: $placementOptionId, customerId: $customerId, warehouseId: $warehouseId) {
    status
    errors {
      code
      details
      message
      severity
    }
  }
}
`;

export const CONFIRM_DELIVERY_WINDOW_OPTIONS = `mutation ConfirmDeliveryWindowOptions($inboundId: String!, $deliveryWindowOptions: [DeliveryWindowOptionInput]!, $customerId: String!, $warehouseId: String!) {
  confirmDeliveryWindowOptions(inboundId: $inboundId, deliveryWindowOptions: $deliveryWindowOptions, customerId: $customerId, warehouseId: $warehouseId) {
    status
    errors {
      code
      details
      message
      severity
    }
  }
}
`;

export const CONFIRM_TRANSPORTATION_OPTIONS = `mutation ConfirmTransportationOptions($inboundId: String!, $transportationOptions: [TransportationOptionInput]!, $customerId: String!, $warehouseId: String!) {
  confirmTransportationOptions(inboundId: $inboundId, transportationOptions: $transportationOptions, customerId: $customerId, warehouseId: $warehouseId) {
    errors {
      code
      details
      message
      severity
    }
    status
  }
}
`;

export const REGENERATE_TRANSPORTATION_OPTIONS = `mutation RegenerateTransportationOptions($inboundId: String!, $customerId: String!, $palletsPerShipment: [PalletsPerShipment]!) {
  regenerateTransportationOptions(inboundId: $inboundId, customerId: $customerId, palletsPerShipment: $palletsPerShipment) {
    status
    errors {
      code
      details
      message
      severity
    }
  }
}`;

export const CONFIRM_PACKING_COMPLETED = `mutation ConfirmPackingCompleted($inboundId: String!, $customerId: String!, $isPackingCompleted: Boolean!, $warehouseId: String!) {
  confirmPackingCompleted(inboundId: $inboundId, customerId: $customerId, isPackingCompleted: $isPackingCompleted, warehouseId: $warehouseId) {
    status
    errors {
      code
      details
      message
      severity
    }
  }
}`;

export const UPDATE_ORDER_FOR_INBOUND = `mutation UpdateOrder($orderInput: EditOrderInput, $user: ID) {
  updateOrder(orderInput: $orderInput, user: $user) {
    attemptId
    message
    url
  }
}`;

export const UPDATE_SHIPMENT_TRACKING_INFORMATION = `
  mutation UpdateShipmentTrackingInformation(
    $carrier: String
    $trackingNumbers: [TrackingNumberInput]
    $shippingLabel: String
    $customer: String!
    $shipmentId: String!
  ) {
    updateShipmentTrackingInformation(
      carrier: $carrier
      trackingNumbers: $trackingNumbers
      shippingLabel: $shippingLabel
      customer: $customer
      shipmentId: $shipmentId
    ) {
      message
    }
  }
`;
