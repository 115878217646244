import { Fragment, useState, useContext, useEffect } from "react";
import { usePresignedURL } from "#hooks/usePresignedURL";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { Dialog, Menu, Transition, Disclosure } from "@headlessui/react";
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  CogIcon,
  SwitchHorizontalIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/outline";
import { UserCircleIcon } from "@heroicons/react/solid";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import { NotificationContext } from "#contexts/notification";
import { MasterDataContext } from "#contexts/masterData";
import { GET_USER, GET_NOTIFICATIONS } from "#queries";
import { LOGOUT_USER, SAVE_USER, SET_ACTIVITY, SET_STATION } from "#mutations";
import { useQuery } from "#hooks/useQuery";
import UserPreferenceForm from "./UserPreferenceForm";
import moment from "moment-timezone";
import Warning from "#components/common/Warning";
import { gitSha } from "../../gitSha";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "../../constants/feature-flags";
import { useFeatureFlags } from "#contexts/featureFlags";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
// const ldClient = useLDClient();

export const getDefaultNavigationRoutes = ({
  ldClient,
  subdomain,
  isBranded,
  storageManagementEnabled,
  salesPortalEnabled,
}) => {
  const isFbaV1Enabled = ldClient.variation(featureFlags.FBA_V1_ENABLED, false);
  let navigation = [
    {
      name: "Dashboard",
      href: "/dashboard",
      isCurrent: (pathname) => pathname === "/dashboard",
      selectedImage: "warehouse_selected.png",
      unselectedImage: "warehouse_unselected.png",
      type: "INTRINSIC",
      children: [
        {
          name: "Orders",
          href: "/dashboard/orders",
          selectedImage: "orders_selected.png",
          unselectedImage: "orders_unselected.png",
        },
        {
          name: "Inventory",
          href: "/dashboard/inventory",
          selectedImage: "orders_selected.png",
          unselectedImage: "orders_unselected.png",
        },
        {
          name: "Space Management",
          href: "/dashboard/spaceManagement",
          selectedImage: "orders_selected.png",
          unselectedImage: "orders_unselected.png",
        },
      ],
    },
    {
      name: "Notifications",
      href: "/notifications",
      isCurrent: (pathname) => pathname === "/notifications",
      selectedImage: "integrations_selected.png",
      unselectedImage: "integrations_unselected.png",
    },
    {
      name: "Outbound Logistics",
      selectedImage: "warehouse_selected.png",
      unselectedImage: "warehouse_unselected.png",
      isCurrent: (pathname) => {
        let paths = [
          "/orders",
          "/outbound",
          "/outboundExceptions",
          "/fbaInbound",
        ];

        if (salesPortalEnabled) {
          paths.push("/salesManagement");
        }

        return paths.includes(pathname);
      },
      children: [
        {
          name: "Order management",
          href: "/orders",
          selectedImage: "orders_selected.png",
          unselectedImage: "orders_unselected.png",
          isCurrent: (pathname) => pathname === "/orders",
        },
        {
          name: "Exceptions",
          href: "/outboundExceptions",
          selectedImage: "orders_selected.png",
          unselectedImage: "orders_unselected.png",
          isCurrent: (pathname) => pathname === "/outboundExceptions",
        },
        {
          name: "Operations",
          href: "/outbound",
          selectedImage: "orders_selected.png",
          unselectedImage: "orders_unselected.png",
          isCurrent: (pathname) => pathname === "/outbound",
        },
        ...(isFbaV1Enabled
          ? [
              {
                name: "FBA Inbound",
                href: "/fbaInbound",
                selectedImage: "orders_selected.png",
                unselectedImage: "orders_unselected.png",
                isCurrent: (pathname) => pathname === "/fbaInbound",
              },
            ]
          : []),
        ...(salesPortalEnabled
          ? [
              {
                name: "Sales Management",
                href: "/salesManagement",
                selectedImage: "orders_selected.png",
                unselectedImage: "orders_unselected.png",
                isCurrent: (pathname) => pathname === "/salesManagement",
              },
            ]
          : []),
      ],
    },
    {
      name: "Inbound Logistics",
      selectedImage: "warehouse_selected.png",
      unselectedImage: "warehouse_unselected.png",
      isCurrent: (pathname) =>
        [
          "/consignments",
          "/receivedSkus",
          "/inbound",
          "/purchaseOrders",
        ].includes(pathname),
      children: [
        {
          name: "Consignments",
          href: "/consignments",
          selectedImage: "orders_selected.png",
          unselectedImage: "orders_unselected.png",
          isCurrent: (pathname) => pathname === "/consignments",
        },
        {
          name: "Operations",
          href: "/inbound",
          selectedImage: "orders_selected.png",
          unselectedImage: "orders_unselected.png",
          isCurrent: (pathname) => pathname === "/inbound",
        },
        {
          name: "Purchase Orders",
          href: "/purchaseOrders",
          selectedImage: "orders_selected.png",
          unselectedImage: "orders_unselected.png",
          isCurrent: (pathname) => pathname === "/purchaseOrders",
        },
      ],
    },
    {
      name: "Inventory",
      selectedImage: "integrations_selected.png",
      unselectedImage: "integrations_unselected.png",
      isCurrent: (pathname) =>
        [
          "/inventoryOverview",
          "/inventoryMeasurement",
          "/forecasts",
          "/cycle-count-plans",
          "/inventoryLedger",
          "/binToBinTransfer",
        ].includes(pathname),
      children: [
        {
          name: "Stock Ledger",
          href: "/inventoryLedger",
          isCurrent: (pathname) => pathname === "/inventoryLedger",
          selectedImage: "integrations_selected.png",
          unselectedImage: "integrations_unselected.png",
        },
        {
          name: "Cycle Count",
          href: "/cycle-count-plans",
          selectedImage: "users_selected.png",
          unselectedImage: "users_unselected.png",
          isCurrent: (pathname) => pathname === "/cycle-count-plans",
        },
        {
          name: "Bin to Bin Transfer",
          href: "/binToBinTransfer",
          selectedImage: "users_selected.png",
          unselectedImage: "users_unselected.png",
          isCurrent: (pathname) => pathname === "/binToBinTransfer",
        },
        {
          name: "Operations",
          href: "/inventoryOperations",
          selectedImage: "users_selected.png",
          unselectedImage: "users_unselected.png",
          isCurrent: (pathname) => pathname === "/inventoryOperations",
        },
      ],
    },
    {
      name: "Billing",
      selectedImage: "integrations_selected.png",
      unselectedImage: "integrations_unselected.png",
      isCurrent: (pathname) => ["/usage", "/invoicing"].includes(pathname),
      children: [
        {
          name: "Usage",
          href: "/usage",
          isCurrent: (pathname) => pathname === "/usage",
          selectedImage: "integrations_selected.png",
          unselectedImage: "integrations_unselected.png",
          isBeta: true,
        },
        {
          name: "Invoicing",
          href: "/invoicing",
          isCurrent: (pathname) => pathname === "/invoicing",
          selectedImage: "integrations_selected.png",
          unselectedImage: "integrations_unselected.png",
          isBeta: true,
        },
      ],
    },
  ];

  const setupNavigation = {
    name: "Setup",
    selectedImage: "stations_selected.png",
    unselectedImage: "stations_unselected.png",
    isCurrent: (pathname) => {
      const paths = [
        "/users",
        "/devices",
        "/stations",
        "/workflow",
        "/vendors",
        "/rules",
        "/integrations",
        "/rule",
        "/group",
        "/skuBinMappings",
        "/warehouses",
        "/customers",
        "/uom",
        "/totes",
        "/boxTypes",
        "/locations",
        "/skuBoxMappings",
        "/materials",
        "/products",
      ];

      if (isBranded) {
        paths.push("/catalogs", "/shippers");
      }

      return paths.includes(pathname);
    },
    children: [
      {
        name: "Warehouse Management",
        href: "/warehouses",
        selectedImage: "users_selected.png",
        unselectedImage: "users_unselected.png",
        isCurrent: (pathname) => pathname === "/warehouses",
      },
      {
        name: "Packing Materials",
        href: "/materials",
        selectedImage: "users_selected.png",
        unselectedImage: "users_unselected.png",
        isCurrent: (pathname) => pathname === "/materials",
      },
      {
        name: "Billing Profiles",
        href: "/billing-profiles",
        isCurrent: (pathname) => pathname === "/billing-profiles",
        selectedImage: "integrations_selected.png",
        unselectedImage: "integrations_unselected.png",
        isBeta: true,
      },
      {
        name: "Client Management",
        href: "/customers",
        selectedImage: "users_selected.png",
        unselectedImage: "users_unselected.png",
        isCurrent: (pathname) => pathname === "/customers",
      },
      {
        name: "User Management",
        href: "/users",
        selectedImage: "users_selected.png",
        unselectedImage: "users_unselected.png",
        isCurrent: (pathname) => pathname === "/users",
      },
      {
        name: "Report Management",
        href: "/reports",
        selectedImage: "integrations_selected.png",
        unselectedImage: "integrations_unselected.png",
        isCurrent: (pathname) => pathname === "/reports",
        isBeta: true,
      },
      {
        name: "Integration Management",
        href: "/integrations",
        selectedImage: "integrations_selected.png",
        unselectedImage: "integrations_unselected.png",
        isCurrent: (pathname) => pathname === "/integrations",
      },
      {
        name: "Workflow Management",
        href: "/workflows",
        selectedImage: "workflow_selected.png",
        unselectedImage: "workflow_unselected.png",
        isCurrent: (pathname) => pathname === "/workflows",
      },
      {
        name: "Vendor Management",
        href: "/vendors",
        selectedImage: "users_selected.png",
        unselectedImage: "users_unselected.png",
        isCurrent: (pathname) => pathname === "/vendors",
      },
      {
        name: "Station Management",
        href: "/stations",
        selectedImage: "stations_selected.png",
        unselectedImage: "stations_unselected.png",
        isCurrent: (pathname) => pathname === "/stations",
      },
      {
        name: "Tote Management",
        href: "/totes",
        selectedImage: "devices_selected.png",
        unselectedImage: "devices_unselected.png",
        isCurrent: (pathname) => pathname === "/totes",
      },
      {
        name: "Box/Pallet Management",
        href: "/boxTypes",
        selectedImage: "devices_selected.png",
        unselectedImage: "devices_unselected.png",
        isCurrent: (pathname) => pathname === "/boxTypes",
      },
      {
        name: "Location Management",
        href: "/locations",
        selectedImage: "devices_selected.png",
        unselectedImage: "devices_unselected.png",
        isCurrent: (pathname) => pathname === "/locations",
      },
      {
        name: "UoM Management",
        href: "/uom",
        selectedImage: "devices_selected.png",
        unselectedImage: "devices_unselected.png",
        isCurrent: (pathname) => pathname === "/uom",
      },
      {
        name: "Product Management",
        href: "/products",
        isCurrent: (pathname) => pathname === "/products",
        selectedImage: "integrations_selected.png",
        unselectedImage: "integrations_unselected.png",
      },
      {
        name: "SKU-Bin Management",
        href: "/skuBinMappings",
        selectedImage: "rules_selected.png",
        unselectedImage: "rules_unselected.png",
        isCurrent: (pathname) => pathname === "/skuBinMappings",
      },
      {
        name: "Rule Engine",
        href: "/rules",
        selectedImage: "rules_selected.png",
        unselectedImage: "rules_unselected.png",
        isCurrent: (pathname) =>
          ["/rules", "/rule", "/group"].includes(pathname),
      },
    ],
  };
  if (storageManagementEnabled) {
    setupNavigation.children.push({
      name: "Storage Management",
      href: "/storage-management",
      selectedImage: "rules_selected.png",
      unselectedImage: "rules_unselected.png",
      isCurrent: (pathname) => pathname === "/storage-management",
    });
  }

  if (isBranded) {
    setupNavigation.children.push(
      {
        name: "Catalog Management",
        href: "/catalogs",
        isCurrent: (pathname) => pathname === "/catalogs",
        selectedImage: "integrations_selected.png",
        unselectedImage: "integrations_unselected.png",
      },
      {
        name: "Shipper Management",
        href: "/shippers",
        isCurrent: (pathname) => pathname === "/shippers",
        selectedImage: "integrations_selected.png",
        unselectedImage: "integrations_unselected.png",
      },
    );
  }

  return [...navigation, setupNavigation];
};

const checkCollapseSidebar = (path) => {
  const list = [
    "receiver",
    "picker",
    "packer",
    "prepper",
    "bundler",
    "putaway",
  ];
  let collapse = false;
  list.forEach((el) => {
    if (path.includes(el)) collapse = true;
  });
  return collapse;
};

const AdminLayout = ({ children }) => {
  let history = useHistory();
  const masterData = useContext(MasterDataContext);
  const notificationsQuery = useQuery(GET_NOTIFICATIONS);
  const logoutQuery = useQuery(LOGOUT_USER);
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const notification = useContext(NotificationContext);
  const ldClient = useLDClient();
  const saveUserQuery = useQuery(SAVE_USER);
  const getUserQuery = useQuery(GET_USER);
  const setStationQuery = useQuery(SET_STATION);
  const setActivityQuery = useQuery(SET_ACTIVITY);
  const [currentUser, setCurrentUser] = useState(auth.user);
  const [navigationRoutes, setNavigationRoutes] = useState([]);
  const [preferenceFormVisible, setPreferenceFormVisible] = useState(false);
  const [preferredNavigationRoutes, setPreferredNavigationRoutes] = useState(
    [],
  );
  const location = useLocation();

  const restrictedCollapsibleSidebar = checkCollapseSidebar(location.pathname);
  const [restrictedSidebarOpen, setRestrictedSidebarOpen] = useState(false);
  const {
    isBranded,
    storageManagementEnabled,
    salesPortalEnabled,
    isBundlingWorkflowEnabled,
  } = useFeatureFlags();

  useEffect(() => {
    if (
      isBranded !== undefined &&
      storageManagementEnabled !== undefined &&
      salesPortalEnabled !== undefined
    ) {
      const defaultRoutes = getDefaultNavigationRoutes({
        ldClient,
        subdomain: appState.subdomain,
        isBranded,
        storageManagementEnabled,
        salesPortalEnabled,
      });
      const routes = makeDefaultPagePreference(
        ldClient,
        currentUser?.permissions || null,
        appState.subdomain,
        isBranded,
        storageManagementEnabled,
        salesPortalEnabled,
      );
      setNavigationRoutes(defaultRoutes);
      setPreferredNavigationRoutes(routes);
    }
  }, [
    isBranded,
    storageManagementEnabled,
    appState.subdomain,
    salesPortalEnabled,
  ]);

  useEffect(() => {
    if (!restrictedCollapsibleSidebar) {
      setRestrictedSidebarOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const filteredPrefferedNavigationRoutes = preferredNavigationRoutes.filter(
      (item) =>
        navigationRoutes.findIndex((route) => route.name === item.name) !== -1,
    );
    setPreferredNavigationRoutes(filteredPrefferedNavigationRoutes);
  }, [navigationRoutes]);

  useEffect(() => {
    if (setStationQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [setStationQuery.loading, setStationQuery.data, setStationQuery.error]);

  const renderWarning = () => {
    // Retrieve tenant and currentDate
    const tenant = JSON.parse(localStorage.getItem("tenant"));
    const currentDate = new Date();

    if (
      tenant &&
      tenant.paymentDetails &&
      tenant.paymentDetails.isPaymentDue === true &&
      auth &&
      auth.user &&
      auth.user.role &&
      (auth.user.role.toLowerCase() === "admin" ||
        auth.user.role.toLowerCase() === "associate" ||
        (auth.user.permissions &&
          auth.user.permissions.findIndex(
            (item) => item.route === "/warehouses" && item.writable,
          ) !== -1 &&
          auth.user.permissions.findIndex(
            (item) => item.route === "/users" && item.writable,
          ) !== -1 &&
          auth.user.permissions.findIndex(
            (item) => item.route === "/customers" && item.writable,
          ) !== -1))
    ) {
      const earliestUnpaidInvoiceDate = new Date(
        tenant.paymentDetails.earliestUnpaidInvoiceDate,
      );
      const warningStartDate = new Date(
        earliestUnpaidInvoiceDate.getTime() +
          tenant.paymentDetails.warningThresholdInDays * 24 * 60 * 60 * 1000,
      );
      const disableDate = new Date(
        earliestUnpaidInvoiceDate.getTime() +
          tenant.paymentDetails.disableThresholdInDays * 24 * 60 * 60 * 1000,
      );
      // Check if the current date is within the warning threshold
      if (currentDate > warningStartDate && currentDate < disableDate) {
        return <Warning />;
      }
    }
    // If conditions are not met, do not show the banner
    return null;
  };

  useEffect(() => {
    if (logoutQuery.data) {
      appState.setAlert(logoutQuery.data.logout.message);
      auth.logout();
    }

    if (logoutQuery.error) {
      auth.logout();
    }
  }, [logoutQuery.data, logoutQuery.loading, logoutQuery.error]);

  useEffect(() => {
    if (notificationsQuery.data && notificationsQuery.data.notifications) {
      notification.setEntities({
        ...notificationsQuery.data.notifications,
        ...notificationsQuery.variables,
      });
      if (notificationsQuery.data.notifications.unreadTotal > 0) {
        appState.setActionableAlert(
          `Hello ${auth.user.name}`,
          `You have ${notificationsQuery.data.notifications.unreadTotal} unread notification(s).`,
          () => history.push("/notifications"),
        );
      }
    }
  }, [
    notificationsQuery.data,
    notificationsQuery.error,
    notificationsQuery.loading,
  ]);

  useEffect(() => {
    if (
      auth &&
      auth.user &&
      auth.user.role &&
      auth.user.role.toLowerCase() === "admin" &&
      auth.user.permissions &&
      auth.user.permissions.findIndex(
        (item) => item.route === "/notifications" && item.readable,
      ) !== -1
    ) {
      notificationsQuery.fetchData({
        perPage: 25,
        pageNumber: 1,
        sort: "-createdAt",
        filters: { status: ["PENDING"] },
      });
    }
  }, []);

  useEffect(() => {
    if (saveUserQuery.data) {
      appState.setAlert(saveUserQuery.data.saveUser.message);
      setPreferenceFormVisible(false);
    }

    if (saveUserQuery.error) {
      appState.setAlert(saveUserQuery.error.message, "error", 5000);
    }
  }, [saveUserQuery.data, saveUserQuery.loading, saveUserQuery.error]);

  useEffect(() => {
    let allowedNavigation = [
      ...getDefaultNavigationRoutes({
        ldClient,
        subdomain: appState.subdomain,
        isBranded,
        storageManagementEnabled,
        salesPortalEnabled,
      }),
    ];
    if (auth && currentUser?.role?.toLowerCase() !== "admin") {
      if (allowedNavigation) {
        allowedNavigation = allowedNavigation.map((route) => {
          if (route.name === "Setup") {
            route.children = route.children.filter(
              (child) => child.href !== "/reports",
            );
          }
          return route;
        });
      }
    }
    if (
      auth &&
      !currentUser?.permissions?.length &&
      !currentUser?.pagePreferences?.length
    ) {
      const defaultRoutes = makeDefaultPagePreference(
        ldClient,
        null,
        appState.subdomain,
        isBranded,
        storageManagementEnabled,
        salesPortalEnabled,
      );
      setPreferredNavigationRoutes(defaultRoutes);
      setNavigationRoutes(defaultRoutes);
      return;
    }
    if (auth && currentUser?.permissions?.length) {
      allowedNavigation = allowedNavigation
        .map(parseUserPermissions(auth.user.permissions))
        .filter((item) => item && item.name);
      setNavigationRoutes(allowedNavigation);
    }
    if (currentUser?.pagePreferences?.length) {
      // Add 'visible' field in naviagtion routes
      allowedNavigation = allowedNavigation.map((item) => {
        if (item.href && !item.children?.length) {
          // Dashboard route
          const currentVisible = currentUser.pagePreferences.find(
            (preferredItem) => preferredItem.route === item.href,
          )?.visible;
          return {
            ...item,
            visible: currentVisible === undefined ? true : currentVisible,
          };
        }
        if (item.children.length) {
          return {
            ...item,
            children: item.children.map((child) => {
              const currentVisible = currentUser.pagePreferences.find(
                (preferredItem) => preferredItem.route === child.href,
              )?.visible;
              return {
                ...child,
                visible: currentVisible === undefined ? true : currentVisible,
              };
            }),
          };
        }
        return item;
      });

      // Filter out hidden routes
      setPreferredNavigationRoutes([...allowedNavigation]);
    } else {
      setPreferredNavigationRoutes(
        makeDefaultPagePreference(
          ldClient,
          currentUser?.permissions,
          appState.subdomain,
          isBranded,
          storageManagementEnabled,
          salesPortalEnabled,
        ),
      );
    }
  }, [currentUser]);

  useEffect(() => {
    if (auth.user.id) getUserQuery.fetchData({ id: auth.user.id });
  }, []);

  useEffect(() => {
    if (getUserQuery.data) {
      setCurrentUser(getUserQuery.data.user);
    }
  }, [getUserQuery.loading, getUserQuery.data, getUserQuery.error]);

  useEffect(() => {
    appState?.setSidebarPreference(appState?.sidebarOpen);
  }, [appState?.sidebarOpen]);

  const onChangePagePreference = (route, visible) => {
    setPreferredNavigationRoutes(
      preferredNavigationRoutes.map((item) => {
        if ((!item.href || item.type === "INTRINSIC") && route === item) {
          return {
            ...item,
            children: [...item.children].map((child) => ({
              ...child,
              visible,
            })),
          };
        }
        if (item.children?.length) {
          return {
            ...item,
            children: item.children.map((child) => {
              if (child.href === route.href) {
                return { ...child, visible };
              }
              return child;
            }),
          };
        }
        if (item.href === route.href) {
          return { ...item, visible };
        }
        return item;
      }),
    );
  };
  const isShippingDesktopWorkflowEnabled =
    ldClient?.variation(
      featureFlags.SHIPPING_DESKTOP_WORKFLOW_ENABLED,
      false,
    ) ?? false;

  const activatedModule = (name) => {
    return masterData?.hopstackModules?.find(
      (i) => i.name?.toLowerCase() === name?.toLowerCase(),
    );
  };

  return (
    <>
      <div>
        <Transition.Root show={restrictedSidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex"
            onClose={setRestrictedSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setRestrictedSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <LeftSidebarInner
                  navigation={navigationRoutes}
                  tenant={appState.tenant}
                  restrictedCollapsibleSidebar={restrictedCollapsibleSidebar}
                />
              </div>
            </Transition.Child>
            <div className="w-14 shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        {!restrictedCollapsibleSidebar && (
          <div
            className={`z-10 hidden md:fixed md:inset-y-0 md:flex ${appState?.sidebarOpen ? "md:w-72" : "md:w-20"} transition-all duration-500 md:flex-col`}>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <LeftSidebarInner
              navigation={preferredNavigationRoutes
                .map((item) => {
                  if (!item.children?.length && !item.visible) {
                    return null;
                  }
                  if (item.children?.length) {
                    const children = item.children.filter(
                      (child) => child.visible,
                    );
                    if (children.length) {
                      return { ...item, children };
                    }
                    return null;
                  }
                  return item;
                })
                .filter((item) => item && item.name)}
              setPreferenceFormVisible={setPreferenceFormVisible}
              tenant={appState.tenant}
              restrictedCollapsibleSidebar={restrictedCollapsibleSidebar}
            />
            {preferenceFormVisible && (
              <UserPreferenceForm
                title="Select pages you want to see"
                onClose={() => setPreferenceFormVisible(false)}
                onSubmit={() =>
                  saveUserQuery.fetchData({
                    ...currentUser,
                    pagePreferences: preferredNavigationRoutes
                      .reduce((prev, curr) => {
                        if (curr.children?.length) {
                          return [...prev, ...curr.children, curr];
                        }
                        return [...prev, curr];
                      }, [])
                      .filter((item) => item.href?.length)
                      .map(
                        (item) =>
                          item.href && {
                            route: item.href,
                            visible: item.visible,
                          },
                      )
                      .filter((item) => item?.visible !== undefined),
                  })
                }
                onChangePreference={onChangePagePreference}
                options={preferredNavigationRoutes}
              />
            )}
          </div>
        )}
        <div
          className={`${
            !appState?.sidebarOpen && !restrictedCollapsibleSidebar
              ? "md:pl-20"
              : restrictedCollapsibleSidebar
                ? ""
                : "md:pl-72"
          } flex flex-col bg-white transition-all duration-500`}>
          <div className="sticky top-0 z-10 flex h-16 shrink-0 bg-white shadow">
            <button
              type="button"
              className={`border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 ${
                restrictedCollapsibleSidebar ? "" : "md:hidden"
              }`}
              onClick={() => {
                setRestrictedSidebarOpen(true);
                if (!appState?.sidebarOpen) {
                  appState.toggleSidebar();
                }
              }}>
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1"></div>
              <div className="ml-4 flex items-center md:ml-6">
                {(auth?.user?.role?.toLowerCase() === "admin" ||
                  auth?.user?.role?.toLowerCase().includes("manager")) && (
                  <Menu as="div" className="relative z-50 ml-3 mr-3">
                    <div>
                      <Menu.Button className="flex max-w-xs items-center gap-1 rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <SwitchHorizontalIcon
                          className="h-8 w-8 rounded-full"
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute right-0 z-auto mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {activatedModule("receiving") && (
                          <Link to="/receiver">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => {
                                    setStationQuery.fetchData({
                                      station: null,
                                    });
                                    setActivityQuery.fetchData({
                                      activity: "Receiving",
                                    });
                                  }}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700",
                                  )}>
                                  Receiving
                                </div>
                              )}
                            </Menu.Item>
                          </Link>
                        )}

                        {isBundlingWorkflowEnabled &&
                          activatedModule("bundling") && (
                            <Link to="/bundler">
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    onClick={() => {
                                      setStationQuery.fetchData({
                                        station: null,
                                      });
                                      setActivityQuery.fetchData({
                                        activity: "Bundling",
                                      });
                                    }}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700",
                                    )}>
                                    Bundling
                                  </div>
                                )}
                              </Menu.Item>
                            </Link>
                          )}
                        {activatedModule("putaway") && (
                          <Link to="/putaway">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => {
                                    setStationQuery.fetchData({
                                      station: null,
                                    });
                                    setActivityQuery.fetchData({
                                      activity: "Putaway",
                                    });
                                  }}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700",
                                  )}>
                                  Putaway
                                </div>
                              )}
                            </Menu.Item>
                          </Link>
                        )}
                        {activatedModule("picking") && (
                          <Link to="/picker">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => {
                                    setStationQuery.fetchData({
                                      station: null,
                                    });
                                    setActivityQuery.fetchData({
                                      activity: "Picking",
                                    });
                                  }}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700",
                                  )}>
                                  Picking
                                </div>
                              )}
                            </Menu.Item>
                          </Link>
                        )}
                        {activatedModule("prepping") && (
                          <Link to="/prepper">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => {
                                    setStationQuery.fetchData({
                                      station: null,
                                    });
                                    setActivityQuery.fetchData({
                                      activity: "Prepping",
                                    });
                                  }}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700",
                                  )}>
                                  Prepping
                                </div>
                              )}
                            </Menu.Item>
                          </Link>
                        )}
                        {activatedModule("packing") && (
                          <Link to="/packer">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => {
                                    setStationQuery.fetchData({
                                      station: null,
                                    });
                                    setActivityQuery.fetchData({
                                      activity: "Packing",
                                    });
                                  }}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700",
                                  )}>
                                  Packing
                                </div>
                              )}
                            </Menu.Item>
                          </Link>
                        )}

                        {isShippingDesktopWorkflowEnabled &&
                          activatedModule("shipping") && (
                            <Link to="/shipper">
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    onClick={() => {
                                      setStationQuery.fetchData({
                                        station: null,
                                      });
                                      setActivityQuery.fetchData({
                                        activity: "Shipping",
                                      });
                                    }}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700",
                                    )}>
                                    Shipping
                                  </div>
                                )}
                              </Menu.Item>
                            </Link>
                          )}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}

                {auth?.user?.role?.toLowerCase() === "admin" &&
                  auth?.user?.permissions?.findIndex(
                    (i) => i.route === "/notifications" && i.readable,
                  ) > -1 && (
                    <Link to="/notifications">
                      <div className="flex max-w-xs cursor-pointer items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        <BellIcon className="h-8 w-8 rounded-full" alt="" />
                        {notification.unreadTotal &&
                        notification.unreadTotal > 0 ? (
                          <div className="-ml-4 -mt-4 inline-flex items-center rounded-full bg-red-800 px-2 py-0.5 text-sm font-medium text-white">
                            {notification.unreadTotal}
                          </div>
                        ) : null}
                      </div>
                    </Link>
                  )}

                {/* !!! Hidden because not implemented */}

                {/* <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <GlobeAltIcon className="h-8 w-8" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}>
                            English(en)
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}>
                            Spanish(es)
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}>
                            Simplified Chinese(zh)
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu> */}

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center gap-1 rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <UserCircleIcon className="h-8 w-8 rounded-full" alt="" />
                      <div className="flex flex-col items-start">
                        {auth.user.name}
                        <span className="text-[10px]">{auth.user.role}</span>
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {auth?.user?.role?.toLowerCase() === "admin" && (
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={() => {
                                history.push("/my-profile");
                              }}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700",
                              )}>
                              My Profile
                            </div>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              logoutQuery.fetchData();
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}>
                            Logout
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main
            className={
              "relative min-h-screen flex-1 overflow-y-auto focus:outline-none " +
              appState.adminLayoutBg
            }>
            {renderWarning()}
            {children}
          </main>
        </div>
      </div>
    </>
  );
};

const Logo = ({ tenant }) => {
  const { presignedURL, getPresignedURL } = usePresignedURL();
  const appState = useContext(AppStateContext);

  useEffect(() => {
    if (tenant?.profile?.logo) {
      getPresignedURL({ key: tenant.profile.logo, method: "GET" });
    }
  }, [tenant]);

  return (
    <div className="flex items-center justify-center space-x-2 py-2">
      {tenant && (tenant?.logo || tenant?.profile?.logo) ? (
        <img
          src={tenant?.logo || presignedURL}
          alt="Custom Logo"
          className="h-20"
        />
      ) : (
        <div className="flex items-center justify-center space-x-2 py-2">
          <img
            src="https://hopstack-pub.s3.amazonaws.com/logo.png"
            alt="Custom Logo"
            className="h-10 w-10"
          />
          {appState?.sidebarOpen && (
            <span className="font-hammersmith text-xl text-white">
              Hopstack Inc
            </span>
          )}
        </div>
      )}
    </div>
  );
};

const LeftSidebarInner = ({
  navigation,
  setPreferenceFormVisible,
  tenant,
  restrictedCollapsibleSidebar,
}) => {
  const history = useHistory();
  const appState = useContext(AppStateContext);

  return (
    <div className="flex h-full flex-col justify-between overflow-auto whitespace-nowrap bg-primaryAccent">
      <div>
        <div
          className={`${restrictedCollapsibleSidebar ? "mb-8" : ""} mt-2 flex items-center justify-between px-4`}>
          <Logo tenant={tenant} />
          {appState?.sidebarOpen && (
            <CogIcon
              onClick={setPreferenceFormVisible}
              className="h-6 w-6 cursor-pointer text-white"
            />
          )}
        </div>

        {!restrictedCollapsibleSidebar && (
          <div
            className={`hidden w-full md:flex ${appState?.sidebarOpen ? "justify-end" : "justify-center"} p-4`}>
            {!appState?.sidebarOpen && (
              <button onClick={appState.toggleSidebar}>
                <ChevronDoubleRightIcon className="h-5 w-5" color="#fff" />
              </button>
            )}
            {appState?.sidebarOpen && (
              <button onClick={appState.toggleSidebar}>
                <ChevronDoubleLeftIcon className="h-5 w-5" color="#fff" />
              </button>
            )}
          </div>
        )}

        <div className="max-w-full overflow-x-hidden">
          <nav className="space-y-4 pl-2">
            {navigation.map((item) =>
              !item.children ||
              item.type === "INTRINSIC" ||
              !appState?.sidebarOpen ? (
                <TopLevelItem
                  item={{
                    ...item,
                    current: item.isCurrent(history.location.pathname),
                  }}
                  key={item.name}
                />
              ) : (
                <InnerMostDisclosure
                  item={{
                    ...item,
                    current: item.isCurrent(history.location.pathname),
                    children: item.children.map((child) => ({
                      ...child,
                      current: child.isCurrent(history.location.pathname),
                    })),
                  }}
                  key={item.name}
                />
              ),
            )}
          </nav>
        </div>
      </div>
      {appState?.sidebarOpen && (
        <div
          className={`mx-auto mt-10 w-11/12 truncate px-2 text-textWhite hover:absolute hover:bottom-0 hover:w-full hover:overflow-visible hover:bg-white hover:text-black`}>
          {gitSha && "version : " + gitSha}
        </div>
      )}
    </div>
  );
};

const TopLevelItem = ({ item }) => {
  const appState = useContext(AppStateContext);

  return (
    <div>
      <Link
        to={item.href ? item.href : null}
        onClick={() => {
          if (
            !appState?.sidebarOpen &&
            item?.children?.length > 0 &&
            !item.href
          ) {
            appState.toggleSidebar();
          }
        }}>
        <div
          className={`flex w-full items-center rounded-full rounded-br-none rounded-tr-none border-0 border-transparent p-0 outline-0 ring-0 ${
            item.current ? "bg-EBEBEB text-1D3752" : "bg-1D3752 text-textWhite"
          }`}>
          <RenderIcon item={item} />
        </div>
      </Link>
    </div>
  );
};

const NestedItem = ({ item }) =>
  item.current ? (
    <div className="ml-8 flex w-full items-center rounded-full border-0 border-transparent bg-EBEBEB p-0 text-1D3752 outline-0 ring-0">
      {" "}
      <RenderIcon item={item} />
    </div>
  ) : (
    <div className="ml-8 flex w-full cursor-pointer items-center rounded-full border-0 border-transparent bg-1D3752 p-0 text-textWhite outline-0 ring-0">
      <Link to={item.href ? item.href : item.name}>
        <RenderIcon item={item} current={item.current} />
      </Link>
    </div>
  );

const InnerMostDisclosure = ({ item }) => (
  <Disclosure as="div" defaultOpen={item.current}>
    <div className="w-full rounded-3xl bg-1D3752">
      <Disclosure.Button
        as="div"
        className={`flex w-full items-center rounded-full rounded-br-none rounded-tr-none border-0 border-transparent p-0 outline-0 ring-0 ${
          item.current ? "bg-EBEBEB text-1D3752" : "bg-1D3752 text-textWhite"
        }`}>
        <RenderIcon item={item} />
      </Disclosure.Button>
      <Disclosure.Panel className="mt-2 space-y-2 text-gray-500">
        {item.children.map((inner, idx) => (
          <Link to={inner.href} key={idx}>
            <div
              className={`ml-12 cursor-pointer items-center bg-1D3752 py-3 text-textWhite outline-0 ring-0 ${
                idx != item.children.length - 1 ? "border-b" : "pb-4"
              } border-2C7695 ${inner.current && "!text-F4C261"}`}>
              <span>{inner.name}</span>
              {inner.isBeta && (
                <span className="ml-2 rounded-sm bg-white bg-opacity-60 pb-0 pl-1 pr-1 pt-0 text-xs font-bold text-primaryAccent">
                  BETA
                </span>
              )}
            </div>
          </Link>
        ))}
      </Disclosure.Panel>
    </div>
  </Disclosure>
);

const RenderIcon = ({ item }) =>
  item.href ? (
    <div className="flex cursor-pointer items-center">{iconInner(item)}</div>
  ) : (
    <div className="flex cursor-pointer items-center">{iconInner(item)}</div>
  );

const iconInner = (item) => {
  const appState = useContext(AppStateContext);

  return item.current ? (
    <>
      <div className="flex h-12 w-12 items-center justify-center rounded-full border border-F4C261 bg-F4C261 py-2.5 font-light">
        <img
          src={`https://hopstack-pub.s3.amazonaws.com/icons/${item.selectedImage}`}
          className="w-8"
        />
      </div>
      {appState?.sidebarOpen ? <span className="px-4">{item.name}</span> : null}
    </>
  ) : (
    <>
      <div className="flex h-12 w-12 items-center justify-center rounded-full border border-textWhite bg-1D3752 py-2.5 font-light">
        <img
          src={`https://hopstack-pub.s3.amazonaws.com/icons/${item.unselectedImage}`}
          className="w-7"
        />
      </div>
      {appState?.sidebarOpen ? <span className="px-4">{item.name}</span> : null}
    </>
  );
};

export default AdminLayout;

const makeDefaultPagePreference = (
  ldClient,
  userPermission,
  subdomain,
  isBranded,
  storageManagementEnabled,
  salesPortalEnabled,
) =>
  [
    ...getDefaultNavigationRoutes({
      ldClient,
      subdomain,
      isBranded,
      storageManagementEnabled,
      salesPortalEnabled,
    }),
  ].map((route) => {
    if (route.children?.length) {
      return {
        ...route,
        children: route.children
          .map((child) => {
            let foundRoute = userPermission?.find(
              (pagePermission) => pagePermission.route === child.href,
            );

            if (!foundRoute) return null;
            else foundRoute = foundRoute.readable;
            return {
              ...child,
              visible: userPermission?.length ? foundRoute : true,
            };
          })
          .filter((item) => item !== null),
      };
    }
    return { ...route, visible: true };
  });

const parseUserPermissions = (permissions) => (item) => {
  let allowedSubRoutes = item.children || [];
  if (item.children?.length) {
    allowedSubRoutes = item.children.filter((child) => {
      if (!permissions) return item.children;
      const allowedChild = permissions?.find((el) => el.route === child.href);

      // if not found in permissions array then a new route must have been added
      // in this case return default access (true/false)
      if (!allowedChild) return false;
      return allowedChild && allowedChild.readable;
    });
  }
  if (item.href) {
    const route = permissions?.find((el) => el.route === item.href);
    if (route && route.readable) {
      return item;
    }
  }
  if (!allowedSubRoutes.length) {
    return null;
  }
  return { ...item, children: allowedSubRoutes };
};
