import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { Country, State } from "country-state-city";

import { AppStateContext } from "#contexts/appState";
import { CREATE_CRM_CUSTOMER, GET_PLANTATION_ZONE_CODE } from "#mutations";

import { InformationCircleIcon, XIcon } from "@heroicons/react/outline";
import { Tooltip } from "antd";

import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import { Navbar } from "./NavBar";
import { AddUserFooter } from "./AddUserFooter";
import DatePicker from "#newUiComponents/commons/DatePicker";
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import moment from "moment-timezone";

const ALERT_VISIBILITY_IN_MS = 5000;

export const CreateCustomer = ({ onCancel }) => {
  const appState = useContext(AppStateContext);
  const [steps, setSteps] = useState([
    {
      tag: "accountInfo",
      name: "Account Info",
      href: "#",
      id: "01",
      status: "current",
      visible: true,
    },
    {
      tag: "accountPreferences",
      name: "Account Preferences",
      href: "#",
      id: "02",
      status: "upcoming",
      visible: true,
    },
    {
      tag: "shippingInfo",
      name: "Shipping Info",
      href: "#",
      id: "03",
      status: "upcoming",
      visible: true,
    },
  ]);
  const [selectedStep, setSelectedStep] = useState(steps[0]);
  const [newCustomer, setNewCustomer] = useState({
    accountAddress: { country: "US" },
    isVIP: "no",
  });
  const [altEmails, setAltEmails] = useState([]);
  const [altPhones, setAltPhones] = useState([]);
  const [useAccountAddress, setUseAccountAddress] = useState(true);
  const [addNewAddress, setAddNewAddress] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(
    newCustomer?.accountAddress?.country,
  );
  const [filteredStates, setFilteredStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [showSubmit, setShowSubmit] = useState(false);
  const createCRMCustomerQuery = useQuery(CREATE_CRM_CUSTOMER);
  const getPlantationZoneCodeQuery = useQuery(GET_PLANTATION_ZONE_CODE);
  const [catalogSuppressionList, setCatalogSuppressionList] = useState([
    {
      label: "Dead",
      value: "Dead",
    },
    {
      label: "Bad Customer",
      value: "Bad Customer",
    },
    {
      label: "Don't Send",
      value: "Don't Send",
    },
  ]);
  const [paymentTermsList, setPaymentTermsList] = useState([
    {
      label: "Prepaid",
      value: "Prepaid",
    },
    {
      label: "Net 30",
      value: "Net 30",
    },
  ]);
  const [notesValidateError, setNotesValidateError] = useState(false);

  useEffect(() => {
    const updateStates = () => {
      if (selectedCountry) {
        const states = State.getStatesOfCountry(selectedCountry);
        setFilteredStates(states);
        const stateIsValid = states.some(
          (state) => state.isoCode === selectedState,
        );
        if (!stateIsValid) {
          setSelectedState("");
        }
      } else {
        setFilteredStates([]);
        setSelectedState("");
      }
    };

    updateStates();
  }, [selectedCountry]);

  const setNextStep = () => {
    if (selectedStep.tag !== "shippingInfo") {
      if (shouldDisplayAccountAddress()) {
        setNewCustomer((prevCustomer) => {
          const accountAddress = prevCustomer?.accountAddress;
          const shippingAddresses = prevCustomer?.shippingAddresses || [];

          const accountAddressExists = shippingAddresses.some((addr) =>
            isSameAddress(addr, accountAddress),
          );

          return {
            ...prevCustomer,
            shippingAddresses: accountAddressExists
              ? [...shippingAddresses]
              : [accountAddress, ...shippingAddresses],
          };
        });
      }
    } else {
      setNewCustomer((prevCustomer) => ({ ...prevCustomer }));
    }
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const nextStep = copiedSteps.find((_, idx) => idx === foundIdx + 1);
    if (!nextStep.visible) {
      foundIdx += 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== copiedSteps.length - 1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx + 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx + 1]);
      setShowSubmit(foundIdx === copiedSteps.length - 2);
    }
  };

  const setPrevStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const prevStep = copiedSteps.find((_, idx) => idx === foundIdx - 1);
    if (!prevStep.visible) {
      foundIdx -= 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== 0) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx - 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setShowSubmit(false);
      setSelectedStep(copiedSteps[foundIdx - 1]);
    }
  };

  const addAltEmail = () => {
    setAltEmails([...altEmails, ""]);
  };

  const removeAltEmail = (index) => {
    setAltEmails(altEmails.filter((_, i) => i !== index));
  };

  const handleAltEmailChange = (value, index) => {
    const updated = [...altEmails];
    updated[index] = value;
    setAltEmails(updated);
  };

  const addAltPhone = () => {
    setAltPhones([...altPhones, ""]);
  };

  const removeAltPhone = (index) => {
    setAltPhones(altPhones.filter((_, i) => i !== index));
  };

  const handleAltPhoneChange = (value, index) => {
    const updated = [...altPhones];
    updated[index] = value;
    setAltPhones(updated);
  };

  const handleCountryChange = (newCountry) => {
    setSelectedCountry(newCountry);
    setSelectedState("");
    setFilteredStates(State.getStatesOfCountry(newCountry));

    setNewCustomer((prev) => ({
      ...prev,
      accountAddress: {
        ...prev?.accountAddress,
        country: newCountry,
        state: "",
      },
    }));
  };

  const handleStateChange = (newState) => {
    setSelectedState(newState);

    setNewCustomer((prev) => ({
      ...prev,
      accountAddress: {
        ...prev?.accountAddress,
        state: newState,
      },
    }));
  };

  // Handler to add a new shipping address
  const handleAddNewAddress = () => {
    const isShippingAddressDuplicate = newCustomer?.shippingAddresses?.some(
      (addr) => {
        if (!addr || !addNewAddress) return false;
        return (
          addr?.line1 === addNewAddress.line1 &&
          addr?.zip === addNewAddress?.zip &&
          addr?.firstName === addNewAddress?.firstName &&
          addr?.lastName === addNewAddress?.lastName
        );
      },
    );
    if (isShippingAddressDuplicate) {
      appState.showNewConfirmation(
        "Add New Shipping Address",
        <span>
          The shipping address you are attempting to add is already present in
          the system. Would you like to proceed with adding this address again?
        </span>,
        () => {
          addNewShippingAddressDetails();
        },
        () => {},
        "Cancel",
        "Proceed",
      );
    } else {
      addNewShippingAddressDetails();
    }
  };

  const addNewShippingAddressDetails = () => {
    setNewCustomer((prevCustomer) => ({
      ...prevCustomer,
      shippingAddresses: [
        ...(prevCustomer?.shippingAddresses || []),
        addNewAddress,
      ],
    }));
    setAddNewAddress(null);
  };

  const shouldDisplayAccountAddress = () => {
    if (!newCustomer || !newCustomer.accountAddress) {
      return false;
    }

    const { firstName, lastName, accountAddress } = newCustomer;

    const result =
      Boolean(firstName) &&
      Boolean(lastName) &&
      Boolean(accountAddress.line1) &&
      Boolean(accountAddress.state) &&
      Boolean(accountAddress.country) &&
      Boolean(accountAddress.city) &&
      Boolean(accountAddress.zip);

    return result;
  };

  function isSameAddress(addr1, addr2) {
    if (!addr1 || !addr2) return false;
    return (
      addr1?.line1 === addr2.line1 &&
      addr1?.line2 === addr2.line2 &&
      addr1?.city === addr2.city &&
      addr1?.zip === addr2?.zip &&
      addr1?.state === addr2?.state &&
      addr1?.country === addr2?.country
    );
  }

  const validateCreateCRMCustomer = () => {
    if (!newCustomer) {
      return appState.setAlert(
        "Please enter customer details",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
    if (!newCustomer.firstName) {
      return appState.setAlert(
        "Please enter first name",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
    if (!newCustomer.lastName) {
      return appState.setAlert(
        "Please enter last name",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
    if (!newCustomer?.accountAddress) {
      return appState.setAlert(
        "Please enter account address",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
    if (
      !newCustomer?.shippingAddresses ||
      newCustomer?.shippingAddresses?.length === 0
    ) {
      return appState.setAlert(
        "Please enter shipping address",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
    return true;
  };

  const createCRMCustomer = async () => {
    if (!validateCreateCRMCustomer()) {
      return;
    }
    const {
      firstName,
      lastName,
      catalogRequestedVE,
      catalogRequestedJS,
      catalogRequestedKGS,
      catalogSuppressionVE,
      catalogSuppressionJS,
      catalogSuppressionKGS,
      lastCatalogSentDateVE,
      lastCatalogSentDateJS,
      lastCatalogSentDateKGS,
      shippingAddresses,
      accountAddress,
      ...otherDetails
    } = newCustomer;

    const updatedAccountAddress = {
      ...accountAddress,
      firstName,
      lastName,
    };
    const updatedShippingAddresses =
      shippingAddresses?.map((address) => ({
        ...address,
        city: address?.city ? address?.city : null,
        contactNumber: address?.contactNumber ? address?.contactNumber : null,
        country: address?.country ? address?.country : null,
        email: address?.email ? address?.email : null,
        firstName: address?.firstName ? address?.firstName : null,
        isPlantingZoneAddress: address?.isPlantingZoneAddress
          ? address?.isPlantingZoneAddress
          : false,
        lastName: address?.lastName ? address?.lastName : null,
        line1: address?.line1 ? address?.line1 : null,
        line2: address?.line2 ? address?.line2 : null,
        plantingZoneCode: address?.plantingZoneCode
          ? address?.plantingZoneCode
          : null,
        state: address?.state ? address?.state : null,
        zip: address?.zip ? address?.zip : null,
      })) || [];
    const updatedCatalogMetadata = {
      catalogRequestedVE,
      catalogSuppressionVE,
      lastCatalogSentDateVE,
      catalogRequestedJS,
      catalogSuppressionJS,
      lastCatalogSentDateJS,
      catalogRequestedKGS,
      catalogSuppressionKGS,
      lastCatalogSentDateKGS,
    };
    const crmCustomerInput = {
      ...otherDetails,
      firstName,
      lastName,
      accountAddress: updatedAccountAddress,
      shippingAddresses: updatedShippingAddresses,
      catalogMetadata: updatedCatalogMetadata,
      notesDateTimestamp: otherDetails?.notes
        ? moment().utc().unix().toString()
        : null,
    };
    createCRMCustomerQuery.fetchData({ crmCustomerInput });
  };

  useEffect(() => {
    if (createCRMCustomerQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (createCRMCustomerQuery.error) {
      appState.setAlert(
        createCRMCustomerQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    } else if (createCRMCustomerQuery.data) {
      appState.setAlert(
        createCRMCustomerQuery.data.createCrmCustomer.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      setNewCustomer(null);
      onCancel(false);
    }
  }, [
    createCRMCustomerQuery.error,
    createCRMCustomerQuery.data,
    createCRMCustomerQuery.loading,
  ]);

  const getPlantationZoneCode = async (
    isPlantingZoneAddress,
    zipCode,
    isShippingAddress,
  ) => {
    if (zipCode && isPlantingZoneAddress) {
      try {
        const getPlantationZoneCodeResponse =
          await getPlantationZoneCodeQuery.fetchData({
            zipcode: zipCode,
          });
        if (getPlantationZoneCodeResponse.error) {
        } else if (getPlantationZoneCodeResponse?.data) {
          if (
            getPlantationZoneCodeResponse?.data?.getZipPlantationZoneMapping
              ?.mapping &&
            getPlantationZoneCodeResponse?.data?.getZipPlantationZoneMapping
              ?.mapping?.length !== 0
          ) {
            const zipPlantationCode =
              getPlantationZoneCodeResponse?.data?.getZipPlantationZoneMapping
                ?.mapping[0]["hz"];
            if (isShippingAddress) {
              setAddNewAddress((prevAddress) => ({
                ...prevAddress,
                plantingZoneCode: zipPlantationCode
                  ? zipPlantationCode.toString()
                  : "",
              }));
            } else {
              setNewCustomer((prevCustomer) => ({
                ...prevCustomer,
                accountAddress: {
                  ...prevCustomer?.accountAddress,
                  plantingZoneCode: zipPlantationCode
                    ? zipPlantationCode.toString()
                    : "",
                },
              }));
            }
          } else {
            if (isShippingAddress) {
              setAddNewAddress((prevAddress) => ({
                ...prevAddress,
                plantingZoneCode: "",
              }));
            } else {
              setNewCustomer((prevCustomer) => ({
                ...prevCustomer,
                accountAddress: {
                  ...prevCustomer?.accountAddress,
                  plantingZoneCode: "",
                },
              }));
            }
          }
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    setNewCustomer((prev) => ({
      ...prev,
      paymentTerms: "Prepaid",
      dataSharing: "no",
    }));
  }, []);

  const FooterActionForm = ({
    newCustomer,
    steps,
    selectedStep,
    setPrevStep,
    setNextStep,
    showSubmit,
    onSubmit,
    onCancel,
    isShippingSelected,
    notesValidateError,
  }) => {
    return (
      <div className="mt-4 flex w-full items-center justify-between space-x-4 bg-white">
        {isShippingSelected}
        <button
          className="p-2 text-base font-semibold underline"
          onClick={onCancel}>
          Cancel
        </button>

        <div className="flex gap-4">
          {selectedStep.id !== steps[0].id && (
            <button
              onClick={setPrevStep}
              className={`rounded-md px-4 py-2 text-base font-semibold ${
                selectedStep?.id === steps[0]?.id
                  ? "cursor-not-allowed border border-mediumGray text-mediumGray"
                  : "cursor-pointer border border-primaryAccent text-primaryAccent"
              }`}>
              Previous
            </button>
          )}

          {selectedStep.id === steps[steps.length - 3].id && (
            <button
              onClick={setNextStep}
              disabled={
                notesValidateError ||
                !(
                  newCustomer?.firstName &&
                  newCustomer?.lastName &&
                  newCustomer?.accountAddress?.line1 &&
                  newCustomer?.accountAddress?.state &&
                  newCustomer?.accountAddress?.country &&
                  newCustomer?.accountAddress?.zip &&
                  newCustomer?.accountAddress?.city &&
                  newCustomer?.email &&
                  newCustomer?.contactNumber
                )
              }
              className={`rounded-md px-4 py-2 text-base font-semibold text-white ${
                notesValidateError ||
                !(
                  newCustomer?.firstName &&
                  newCustomer?.lastName &&
                  newCustomer?.accountAddress?.line1 &&
                  newCustomer?.accountAddress?.state &&
                  newCustomer?.accountAddress?.country &&
                  newCustomer?.accountAddress?.zip &&
                  newCustomer?.accountAddress?.city &&
                  newCustomer?.email &&
                  newCustomer?.contactNumber
                )
                  ? "cursor-not-allowed border bg-gray-400"
                  : "cursor-pointer bg-primaryAccent"
              }`}>
              Next
            </button>
          )}

          {selectedStep.id === steps[steps.length - 2].id && (
            <button
              onClick={setNextStep}
              disabled={
                newCustomer?.taxExemption === "yes" &&
                !newCustomer?.taxExemptCertificateExpiryDate
              }
              className={`rounded-md px-4 py-2 text-base font-semibold text-white ${
                newCustomer?.taxExemption === "yes" &&
                !newCustomer?.taxExemptCertificateExpiryDate
                  ? "cursor-not-allowed border bg-gray-400"
                  : "cursor-pointer bg-primaryAccent"
              }`}>
              Next
            </button>
          )}

          {showSubmit && selectedStep.id !== steps[0].id && (
            <button
              onClick={onSubmit}
              disabled={
                !isShippingSelected || !newCustomer?.shippingAddresses?.length
              }
              className={`rounded-md px-4 py-2 text-base font-semibold ${
                isShippingSelected
                  ? "cursor-pointer bg-primaryAccent text-white"
                  : "cursor-not-allowed border border-mediumGray text-mediumGray"
              }`}>
              Add User
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="relative flex h-full flex-col overflow-y-auto overflow-x-hidden bg-white p-4 font-inter">
      <header className="space-y-2 py-4">
        <Navbar steps={steps} />
      </header>
      <main>
        <div className="mt-2 h-full space-y-1">
          {selectedStep?.tag === "accountInfo" && (
            <div className="flex h-full flex-col">
              <div className="flex gap-4 space-y-1 py-2">
                <div className="w-1/2 space-y-1">
                  <label
                    htmlFor="businessName"
                    className="block text-sm font-medium text-gray-700">
                    Business Name
                  </label>
                  <input
                    type="text"
                    name="businessName"
                    id="businessName"
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    value={newCustomer?.businessName}
                    onChange={(e) => {
                      setNewCustomer((prevCustomer) => ({
                        ...prevCustomer,
                        businessName: e.target.value || null,
                      }));
                    }}
                  />
                </div>
                <div className="w-1/2 space-y-1"></div>
              </div>

              <div className="flex gap-4 py-2">
                <div className="w-1/4 space-y-1">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700">
                    First Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    required
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    placeholder="Doe"
                    value={newCustomer?.firstName}
                    onChange={(e) => {
                      setNewCustomer((prevCustomer) => ({
                        ...prevCustomer,
                        firstName: e.target.value,
                        accountAddress: {
                          ...prevCustomer?.accountAddress,
                          firstName: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="w-1/4 space-y-1">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700">
                    Last Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    required
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    placeholder="John"
                    value={newCustomer?.lastName}
                    onChange={(e) => {
                      setNewCustomer((prevCustomer) => ({
                        ...prevCustomer,
                        lastName: e.target.value,
                        accountAddress: {
                          ...prevCustomer?.accountAddress,
                          lastName: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="w-1/4 space-y-1">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700">
                    Email Address<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    placeholder="XYZ123@gmail.com"
                    value={newCustomer?.email}
                    onChange={(e) => {
                      setNewCustomer((prevCustomer) => ({
                        ...prevCustomer,
                        email: e.target.value,
                        accountAddress: {
                          ...prevCustomer?.accountAddress,
                          email: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="w-1/4 space-y-1">
                  <label
                    htmlFor="contact-number"
                    className="block text-sm font-medium text-gray-700">
                    Contact Number<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="tel"
                    name="contact-number"
                    id="contact-number"
                    value={newCustomer?.contactNumber}
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    placeholder="9876543210"
                    onChange={(e) => {
                      const inputValue = e?.target?.value
                        ? e?.target?.value.replace(/[^0-9]/g, "").slice(0, 10)
                        : "";
                      setNewCustomer((prevCustomer) => ({
                        ...prevCustomer,
                        contactNumber: inputValue,
                        accountAddress: {
                          ...prevCustomer?.accountAddress,
                          contactNumber: inputValue,
                        },
                      }));
                    }}
                    onKeyDown={(e) => {
                      if (["e", "E", "."].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>

              <div className="flex gap-4 space-y-1 py-2">
                <div className="w-1/2 space-y-1">
                  <label
                    htmlFor="address-line1"
                    className="block text-sm font-medium text-gray-700">
                    Address Line 1<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="address-line1"
                    id="address-line1"
                    required
                    value={newCustomer?.accountAddress?.line1}
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    onChange={(e) => {
                      setNewCustomer((prevCustomer) => ({
                        ...prevCustomer,
                        accountAddress: {
                          ...prevCustomer?.accountAddress,
                          line1: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="w-1/2 space-y-1">
                  <label
                    htmlFor="address-line2"
                    className="block text-sm font-medium text-gray-700">
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    name="address-line2"
                    id="address-line2"
                    value={newCustomer?.accountAddress?.line2}
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    onChange={(e) => {
                      setNewCustomer((prevCustomer) => ({
                        ...prevCustomer,
                        accountAddress: {
                          ...prevCustomer?.accountAddress,
                          line2: e.target.value || null,
                        },
                      }));
                    }}
                  />
                </div>
              </div>

              <div className="flex gap-4 py-2">
                <div className="w-1/4 space-y-1">
                  <label
                    htmlFor="address-city"
                    className="block text-sm font-medium text-gray-700">
                    City<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="address-city"
                    id="address-city"
                    value={newCustomer?.accountAddress?.city}
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    onChange={(e) => {
                      setNewCustomer((prevCustomer) => ({
                        ...prevCustomer,
                        accountAddress: {
                          ...prevCustomer?.accountAddress,
                          city: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="w-1/4 space-y-1">
                  <label
                    htmlFor="zip-code"
                    className="block text-sm font-medium text-gray-700">
                    Zip Code<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="zip-code"
                    id="zip-code"
                    required
                    value={newCustomer?.accountAddress?.zip}
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    placeholder="560075"
                    onChange={(e) => {
                      setNewCustomer((prevCustomer) => ({
                        ...prevCustomer,
                        accountAddress: {
                          ...prevCustomer?.accountAddress,
                          zip: e.target.value,
                        },
                      }));
                    }}
                    onBlur={() =>
                      getPlantationZoneCode(
                        newCustomer?.accountAddress?.isPlantingZoneAddress,
                        newCustomer?.accountAddress?.zip,
                        false,
                      )
                    }
                  />
                </div>
                <div className="w-1/4 space-y-1">
                  <div>
                    <Tooltip
                      title={
                        !selectedCountry ? "Please select a country first" : ""
                      }
                      placement="top">
                      <div style={{ display: "inline-block", width: "100%" }}>
                        <label
                          className="block text-sm font-medium text-gray-700"
                          htmlFor="state">
                          State<span className="text-red-500">*</span>
                        </label>
                        <AutocompleteDropdownV2
                          options={filteredStates}
                          labelKey="name"
                          valueKey="isoCode"
                          onChange={handleStateChange}
                          value={newCustomer?.accountAddress?.state || ""}
                          placeholder="Select a State"
                          id="name"
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="w-1/4 space-y-1">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="country">
                    Country<span className="text-red-500">*</span>
                  </label>
                  <AutocompleteDropdownV2
                    options={Country.getAllCountries()}
                    id="name"
                    labelKey="name"
                    valueKey="isoCode"
                    onChange={handleCountryChange}
                    value={newCustomer?.accountAddress?.country || ""}
                    placeholder="Select a Country"
                  />
                </div>
              </div>

              <div className="flex gap-4 space-y-1 py-2">
                <div className="w-1/2 space-y-1">
                  <div className="flex h-5 items-center space-x-2">
                    <input
                      id="planting-zone"
                      name="planting-zone"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-[#224E73] focus:ring-[#224E73]"
                      value={newCustomer?.accountAddress?.isPlantingZoneAddress}
                      onChange={(e) => {
                        setNewCustomer((prevCustomer) => ({
                          ...prevCustomer,
                          accountAddress: {
                            ...prevCustomer?.accountAddress,
                            isPlantingZoneAddress: e.target.checked,
                          },
                        }));
                        if (e.target.checked) {
                          getPlantationZoneCode(
                            e.target.checked,
                            newCustomer?.accountAddress?.zip,
                            false,
                          );
                        }
                      }}
                    />
                    <label
                      htmlFor="isPlantingZoneAddress"
                      className="font-medium text-gray-700">
                      Mark the address as a planting zone
                    </label>
                  </div>
                </div>
                <div className="w-1/2 space-y-1">
                  <label
                    htmlFor="isVIP"
                    className="block text-sm font-medium text-gray-700">
                    Is the customer a VIP ?
                  </label>
                  <div className="mb-4 flex items-center">
                    <input
                      type="radio"
                      id="VIPCustomerIndicatorYes"
                      name="isVIP"
                      className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                      value={"yes"}
                      checked={newCustomer?.isVIP === "yes"}
                      onChange={(e) => {
                        setNewCustomer((prevCustomer) => ({
                          ...prevCustomer,
                          isVIP: "yes",
                        }));
                      }}
                    />
                    <label
                      htmlFor="VIPCustomerIndicatorYes"
                      className="ml-2 mr-4">
                      <span className="inline-block p-2 text-sm font-medium text-gray-700">
                        Yes
                      </span>
                    </label>
                    <input
                      type="radio"
                      id="VIPCustomerIndicatorNo"
                      name="isVIP"
                      className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                      value={"no"}
                      checked={newCustomer?.isVIP === "no"}
                      onChange={(e) => {
                        setNewCustomer((prevCustomer) => ({
                          ...prevCustomer,
                          isVIP: "no",
                        }));
                      }}
                    />
                    <label htmlFor="VIPCustomerIndicatorNo" className="ml-2">
                      <span className="inline-block p-2 text-sm font-medium text-gray-700">
                        No
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="flex gap-4 space-y-1 py-2">
                <div className="w-1/2 space-y-1">
                  <label
                    htmlFor="planting-zone"
                    className="block text-sm font-medium text-gray-700">
                    Planting Zone Code
                  </label>
                  <input
                    type="text"
                    name="planting-zone"
                    id="planting-zone"
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    placeholder=""
                    value={newCustomer?.accountAddress?.plantingZoneCode}
                    onChange={(e) => {
                      setNewCustomer((prevCustomer) => ({
                        ...prevCustomer,
                        accountAddress: {
                          ...prevCustomer?.accountAddress,
                          plantingZoneCode: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="w-1/2 space-y-1"></div>
              </div>

              <div className="flex gap-4 space-y-1 py-2">
                <div className="w-full space-y-1">
                  <label
                    htmlFor="notes"
                    className="block text-sm font-medium text-gray-700">
                    Notes
                  </label>
                  <textarea
                    name="notes"
                    id="notes"
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                    placeholder="Enter any custom notes here"
                    value={newCustomer?.notes}
                    onChange={(e) => {
                      if (e?.target?.value?.includes("|")) {
                        setNotesValidateError(true);
                      } else {
                        setNotesValidateError(false);
                      }
                      setNewCustomer((prevAddress) => ({
                        ...prevAddress,
                        notes: e.target.value,
                      }));
                    }}
                  />
                  {notesValidateError && (
                    <div className="mt-1 text-sm text-red-600">
                      The '|' character is not allowed in notes. Please remove
                      it and try again.
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {selectedStep?.tag === "accountPreferences" && (
            <div className="flex h-full flex-col">
              <div className="mb-4 space-y-2">
                <div className="flex flex-col">
                  <div className="flex w-full flex-row items-center">
                    <label
                      htmlFor="Catalog-Request-Options"
                      className="!w-[16rem] text-base font-semibold text-primaryAccent">
                      Catalog Request Options
                    </label>
                    <hr className="w-full border border-solid bg-gray-200" />
                  </div>
                  <div className="flex flex-row items-start">
                    <label
                      htmlFor="Catalog-Request-Options-sub-txt"
                      className="text-sm font-normal text-gray-400">
                      Records whether the customer has requested a catalog from
                      each Business Unit.
                    </label>
                  </div>
                </div>
                <div className="flex gap-4 space-y-1 py-2">
                  <div className="w-1/3 space-y-1">
                    <label
                      htmlFor="askForCatalogVE"
                      className="block text-sm font-medium text-gray-700">
                      Catalog Requested from VE?
                    </label>
                    <div className="mb-4 flex items-center">
                      <input
                        type="radio"
                        id="askForCatalogVEYes"
                        name="catalogRequestedVE"
                        className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                        value={"yes"}
                        checked={newCustomer?.catalogRequestedVE === "yes"}
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            catalogRequestedVE: "yes",
                          }));
                        }}
                      />
                      <label htmlFor="askForCatalogVEYes" className="">
                        <span className="inline-block p-2 text-sm font-normal text-gray-500">
                          Requested
                        </span>
                      </label>
                      <input
                        type="radio"
                        id="askForCatalogVENo"
                        name="catalogRequestedVE"
                        className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                        value={"no"}
                        checked={newCustomer?.catalogRequestedVE === "no"}
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            catalogRequestedVE: "no",
                          }));
                        }}
                      />
                      <label htmlFor="askForCatalogVENo" className="">
                        <span className="inline-block p-2 text-sm font-normal text-gray-500">
                          Not Requested
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="w-1/3 space-y-1">
                    <label
                      htmlFor="askForCatalogJS"
                      className="block text-sm font-medium text-gray-700">
                      Catalog Requested from JS?
                    </label>
                    <div className="mb-4 flex items-center">
                      <input
                        type="radio"
                        id="askForCatalogJSYes"
                        name="catalogRequestedJS"
                        className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                        value={"yes"}
                        checked={newCustomer?.catalogRequestedJS === "yes"}
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            catalogRequestedJS: "yes",
                          }));
                        }}
                      />
                      <label htmlFor="askForCatalogJSYes" className="">
                        <span className="inline-block p-2 text-sm font-normal text-gray-500">
                          Requested
                        </span>
                      </label>
                      <input
                        type="radio"
                        id="askForCatalogJSNo"
                        name="catalogRequestedJS"
                        className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                        value={"no"}
                        checked={newCustomer?.catalogRequestedJS === "no"}
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            catalogRequestedJS: "no",
                          }));
                        }}
                      />
                      <label htmlFor="askForCatalogJSNo" className="">
                        <span className="inline-block p-2 text-sm font-normal text-gray-500">
                          Not Requested
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="w-1/3 space-y-1">
                    <label
                      htmlFor="askForCatalogKGS"
                      className="block text-sm font-medium text-gray-700">
                      Catalog Requested from KGS?
                    </label>
                    <div className="mb-4 flex items-center">
                      <input
                        type="radio"
                        id="askForCatalogKGSYes"
                        name="catalogRequestedKGS"
                        className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                        value={"yes"}
                        checked={newCustomer?.catalogRequestedKGS === "yes"}
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            catalogRequestedKGS: "yes",
                          }));
                        }}
                      />
                      <label htmlFor="askForCatalogKGSYes" className="">
                        <span className="inline-block p-2 text-sm font-normal text-gray-500">
                          Requested
                        </span>
                      </label>
                      <input
                        type="radio"
                        id="askForCatalogKGSNo"
                        name="catalogRequestedKGS"
                        className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                        value={"no"}
                        checked={newCustomer?.catalogRequestedKGS === "no"}
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            catalogRequestedKGS: "no",
                          }));
                        }}
                      />
                      <label htmlFor="askForCatalogKGSNo" className="">
                        <span className="inline-block p-2 text-sm font-normal text-gray-500">
                          Not Requested
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-4 space-y-2">
                <div className="flex flex-col">
                  <div className="flex w-full flex-row items-center">
                    <label
                      htmlFor="Catalog-Suppression"
                      className="!w-[13rem] text-base font-semibold text-primaryAccent">
                      Catalog Suppression
                    </label>
                    <hr className="w-full border border-solid bg-gray-200" />
                  </div>
                  <div className="flex flex-row items-start">
                    <label
                      htmlFor="Catalog-Suppression-sub-txt"
                      className="text-sm font-normal text-gray-400">
                      Determine if a catalog should be sent based on company
                      discreation and customer interaction.
                    </label>
                  </div>
                </div>
                <div className="flex gap-4 space-y-1 py-2">
                  <div className="w-1/3 space-y-1">
                    <label
                      htmlFor="catalogSuppressionVE"
                      className="block text-sm font-medium text-gray-700">
                      Catalog Suppression for VE
                    </label>
                    <div className="mb-4 flex items-center">
                      <AutoCompleteSingleSelect
                        options={catalogSuppressionList}
                        value={newCustomer?.catalogSuppressionVE || ""}
                        onChange={(value) => {
                          if (value) {
                            setNewCustomer((prevCustomer) => ({
                              ...prevCustomer,
                              catalogSuppressionVE: value,
                            }));
                          }
                        }}
                        labelKey="label"
                        valueKey="value"
                        placeholder="Select Suppression Status"
                        labelText=""
                        required={false}
                        shouldDeselect={false}
                        disabled={false}
                        error=""
                        infoText=""
                        showNotSelectedError={false}
                        parentClasses="flex flex-col w-full mb-4"
                        labelClasses="text-base text-gray-500"
                      />
                    </div>
                  </div>
                  <div className="w-1/3 space-y-1">
                    <label
                      htmlFor="catalogSuppressionJS"
                      className="block text-sm font-medium text-gray-700">
                      Catalog Suppression for JS
                    </label>
                    <div className="mb-4 flex items-center">
                      <AutoCompleteSingleSelect
                        options={catalogSuppressionList}
                        value={newCustomer?.catalogSuppressionJS || ""}
                        onChange={(value) => {
                          if (value) {
                            setNewCustomer((prevCustomer) => ({
                              ...prevCustomer,
                              catalogSuppressionJS: value,
                            }));
                          }
                        }}
                        labelKey="label"
                        valueKey="value"
                        placeholder="Select Suppression Status"
                        labelText=""
                        required={false}
                        shouldDeselect={false}
                        disabled={false}
                        error=""
                        infoText=""
                        showNotSelectedError={false}
                        parentClasses="flex flex-col w-full mb-4"
                        labelClasses="text-base text-gray-500"
                      />
                    </div>
                  </div>
                  <div className="w-1/3 space-y-1">
                    <label
                      htmlFor="catalogSuppressionKGS"
                      className="block text-sm font-medium text-gray-700">
                      Catalog Suppression for KGS
                    </label>
                    <div className="mb-4 flex items-center">
                      <AutoCompleteSingleSelect
                        options={catalogSuppressionList}
                        value={newCustomer?.catalogSuppressionKGS || ""}
                        onChange={(value) => {
                          if (value) {
                            setNewCustomer((prevCustomer) => ({
                              ...prevCustomer,
                              catalogSuppressionKGS: value,
                            }));
                          }
                        }}
                        labelKey="label"
                        valueKey="value"
                        placeholder="Select an option"
                        labelText=""
                        required={false}
                        shouldDeselect={false}
                        disabled={false}
                        error=""
                        infoText=""
                        showNotSelectedError={false}
                        parentClasses="flex flex-col w-full mb-4"
                        labelClasses="text-base text-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-4 space-y-2">
                <div className="flex flex-col">
                  <div className="flex w-full flex-row items-center">
                    <label
                      htmlFor="Last-Catalog-Sent-Date"
                      className="!w-[14rem] text-base font-semibold text-primaryAccent">
                      Last Catalog Sent Date
                    </label>
                    <hr className="w-full border border-solid bg-gray-200" />
                  </div>
                  <div className="flex flex-row items-start">
                    <label
                      htmlFor="Last-Catalog-Sent-Date-sub-txt"
                      className="text-sm font-normal text-gray-400">
                      Enter the last date a catalog was sent to each Business
                      Unit.
                    </label>
                  </div>
                </div>
                <div className="flex gap-4 space-y-1 py-2">
                  <div className="w-1/3 space-y-1">
                    <label
                      htmlFor="lastCatalogSentDateVE"
                      className="block text-sm font-medium text-gray-700">
                      VE Last Sent Date
                    </label>
                    <div className="mb-4">
                      <DatePicker
                        label=""
                        infoText=""
                        value={newCustomer?.lastCatalogSentDateVE || ""}
                        onChange={(date) => {
                          if (date) {
                            setNewCustomer((prevCustomer) => ({
                              ...prevCustomer,
                              lastCatalogSentDateVE: date,
                            }));
                          }
                        }}
                        error=""
                        parentClasses="mb-4"
                        labelClasses="text-base text-gray-500"
                        required={false}
                      />
                    </div>
                  </div>
                  <div className="w-1/3 space-y-1">
                    <label
                      htmlFor="lastCatalogSentDateJS"
                      className="block text-sm font-medium text-gray-700">
                      JS Last Sent Date
                    </label>
                    <div className="mb-4">
                      <DatePicker
                        label=""
                        infoText=""
                        value={newCustomer?.lastCatalogSentDateJS || ""}
                        onChange={(date) => {
                          if (date) {
                            setNewCustomer((prevCustomer) => ({
                              ...prevCustomer,
                              lastCatalogSentDateJS: date,
                            }));
                          }
                        }}
                        error=""
                        parentClasses="mb-4"
                        labelClasses="text-base text-gray-500"
                        required={false}
                      />
                    </div>
                  </div>
                  <div className="w-1/3 space-y-1">
                    <label
                      htmlFor="lastCatalogSentDateKGS"
                      className="block text-sm font-medium text-gray-700">
                      KGS Last Sent Date
                    </label>
                    <div className="mb-4">
                      <DatePicker
                        label=""
                        infoText=""
                        value={newCustomer?.lastCatalogSentDateKGS || ""}
                        onChange={(date) => {
                          if (date) {
                            setNewCustomer((prevCustomer) => ({
                              ...prevCustomer,
                              lastCatalogSentDateKGS: date,
                            }));
                          }
                        }}
                        error=""
                        parentClasses="mb-4"
                        labelClasses="text-base text-gray-500"
                        required={false}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-4 space-y-2">
                <div className="flex flex-col">
                  <div className="flex w-full flex-row items-center">
                    <label
                      htmlFor="Privacy-Preference"
                      className="!w-[12rem] text-base font-semibold text-primaryAccent">
                      Privacy Preference
                    </label>
                    <hr className="w-full border border-solid bg-gray-200" />
                  </div>
                  <div className="flex flex-row items-start">
                    <label
                      htmlFor="Privacy-Preference-sub-txt"
                      className="text-sm font-normal text-gray-400">
                      Record if the customer consents to data sharing.
                    </label>
                  </div>
                </div>
                <div className="flex gap-4 space-y-1 py-2">
                  <div className="w-1/3 space-y-1">
                    <label
                      htmlFor="privacy"
                      className="block text-sm font-medium text-gray-700">
                      Consent to Date Sharing?
                    </label>
                    <div className="mb-4 flex items-center">
                      <input
                        type="radio"
                        id="privacyYes"
                        name="privacy"
                        className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                        value={"yes"}
                        checked={newCustomer?.dataSharing === "yes"}
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            dataSharing: "yes",
                          }));
                        }}
                      />
                      <label htmlFor="privacyYes" className="ml-2 mr-4">
                        <span className="inline-block p-2 text-sm font-normal text-gray-500">
                          Yes
                        </span>
                      </label>
                      <input
                        type="radio"
                        id="privacyNo"
                        name="privacy"
                        className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                        value={"no"}
                        checked={newCustomer?.dataSharing === "no"}
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            dataSharing: "no",
                          }));
                        }}
                      />
                      <label htmlFor="privacyNo" className="ml-2">
                        <span className="inline-block p-2 text-sm font-normal text-gray-500">
                          No
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="w-1/3 space-y-1"></div>
                  <div className="w-1/3 space-y-1"></div>
                </div>
              </div>

              <div className="mb-4 space-y-2">
                <div className="flex flex-col">
                  <div className="flex w-full flex-row items-center">
                    <label
                      htmlFor="Payment-Terms-and-Tax-Exemption"
                      className="!w-[25rem] text-base font-semibold text-primaryAccent">
                      Payment Terms and Tax Exemption
                    </label>
                    <hr className="w-full border border-solid bg-gray-200" />
                  </div>
                  <div className="flex flex-row items-start">
                    <label
                      htmlFor="Payment-Terms-and-Tax-Exemption-sub-txt"
                      className="text-sm font-normal text-gray-400">
                      Set the customer's payment terms and indicate if they
                      qualify for tax exemption.
                    </label>
                  </div>
                </div>
                <div className="flex gap-4 space-y-1 py-2">
                  <div className="w-1/3 space-y-1">
                    <label
                      htmlFor="paymentTerms"
                      className="block text-sm font-medium text-gray-700">
                      Payment Terms
                    </label>
                    <div className="mb-4 flex items-center">
                      <AutoCompleteSingleSelect
                        options={paymentTermsList}
                        value={newCustomer?.paymentTerms || ""}
                        onChange={(value) => {
                          if (value) {
                            setNewCustomer((prevCustomer) => ({
                              ...prevCustomer,
                              paymentTerms: value,
                            }));
                          }
                        }}
                        labelKey="label"
                        valueKey="value"
                        placeholder="Select an option"
                        labelText=""
                        required={false}
                        shouldDeselect={false}
                        disabled={false}
                        error=""
                        infoText=""
                        showNotSelectedError={false}
                        parentClasses="flex flex-col w-full mb-4"
                        labelClasses="text-base text-gray-500"
                      />
                    </div>
                  </div>
                  <div className="w-1/3 space-y-1">
                    <label
                      htmlFor="taxExemption"
                      className="block text-sm font-medium text-gray-700">
                      Tax Exemption
                    </label>
                    <div className="mb-4 flex items-center">
                      <input
                        type="radio"
                        id="taxExemptionYes"
                        name="taxExemption"
                        className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                        value={"yes"}
                        checked={newCustomer?.taxExemption === "yes"}
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            taxExemption: "yes",
                          }));
                        }}
                      />
                      <label htmlFor="taxExemptionYes" className="ml-2 mr-4">
                        <span className="inline-block p-2 text-sm font-normal text-gray-500">
                          Yes
                        </span>
                      </label>
                      <input
                        type="radio"
                        id="taxExemptionNo"
                        name="taxExemption"
                        className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                        value={"no"}
                        checked={newCustomer?.taxExemption === "no"}
                        onChange={(e) => {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            taxExemption: "no",
                          }));
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            taxExemptCertificateExpiryDate: null,
                          }));
                        }}
                      />
                      <label htmlFor="taxExemptionNo" className="ml-2">
                        <span className="inline-block p-2 text-sm font-normal text-gray-500">
                          No
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="w-1/3 space-y-1">
                    {newCustomer?.taxExemption === "yes" && (
                      <div className="space-y-2">
                        <label
                          htmlFor="tax-exempt-certification-expiry-date"
                          className="block text-sm font-medium text-gray-700">
                          Tax Exempt Certification Expiry Date
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="mb-4">
                          <DatePicker
                            label=""
                            infoText=""
                            value={
                              newCustomer?.taxExemptCertificateExpiryDate || ""
                            }
                            onChange={(date) => {
                              if (date) {
                                setNewCustomer((prevCustomer) => ({
                                  ...prevCustomer,
                                  taxExemptCertificateExpiryDate: date,
                                }));
                              }
                            }}
                            error=""
                            parentClasses="mb-4"
                            labelClasses="text-base text-gray-500"
                            required={false}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedStep?.tag === "shippingInfo" && (
            <div className="space-y-6">
              {/* Checkbox to use account address as default shipping address */}
              {shouldDisplayAccountAddress() && (
                <div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="use-account-address"
                      name="use-account-address"
                      checked={useAccountAddress}
                      onChange={(e) => {
                        setUseAccountAddress(e.target.checked);
                        if (e.target.checked) {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            shippingAddresses: [
                              prevCustomer?.accountAddress,
                              ...(prevCustomer?.shippingAddresses || []),
                            ],
                          }));
                        } else {
                          setNewCustomer((prevCustomer) => ({
                            ...prevCustomer,
                            shippingAddresses: (
                              prevCustomer?.shippingAddresses || []
                            ).slice(1),
                          }));
                        }
                      }}
                      className="h-4 w-4 rounded border-gray-300 text-[#224E73] focus:ring-[#224E73]"
                    />
                    <label
                      htmlFor="use-account-address"
                      className="ml-2 text-sm font-medium text-gray-700">
                      Add the account address as shipping address
                    </label>
                  </div>
                </div>
              )}
              {newCustomer?.shippingAddresses?.length > 0
                ? newCustomer?.shippingAddresses?.map((address, index) => (
                    <div key={index} className="rounded-md border p-4">
                      <div className="flex items-center justify-between">
                        <div>
                          <h3 className="text-lg font-semibold">
                            {[address?.firstName, address?.lastName]
                              .filter(Boolean)
                              .join(" ")}
                          </h3>
                          <p className="text-sm">
                            {[address?.line1, address?.line2]
                              .filter(Boolean)
                              .join(", ")}
                          </p>
                          <p className="text-sm">
                            {[
                              address?.city,
                              address?.state,
                              address?.country,
                              address?.zip,
                            ]
                              .filter(Boolean)
                              .join(", ")}
                          </p>
                          <p className="text-sm">
                            {[address?.contactNumber, address?.email]
                              .filter(Boolean)
                              .join(", ")}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                : null}

              {!useAccountAddress &&
              newCustomer?.shippingAddresses?.length === 0 ? (
                <div className="rounded border border-blue-800 bg-blue-100 p-2 text-sm text-gray-600">
                  Please provide a shipping address for the customer if it
                  differs from the account address.
                </div>
              ) : null}

              {/* Add New Address Button */}
              {addNewAddress ? null : (
                <button
                  type="button"
                  onClick={() => setAddNewAddress({ country: "US" })}
                  className="py-2 text-sm text-[#224E73] underline hover:text-[#224E73]">
                  + Add New Address
                </button>
              )}

              {/* New Address Form */}
              {addNewAddress && (
                <div className="space-y-1">
                  <div className="space-x-2 border px-2 py-4">
                    <h3 className="py-2 text-lg font-semibold">Add Address</h3>

                    <div className="grid grid-cols-2 gap-4">
                      {/* First Name Field */}
                      <div className="space-y-1">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700">
                          First Name<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          required
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          placeholder="Doe"
                          value={addNewAddress?.firstName}
                          onChange={(e) => {
                            setAddNewAddress((prevAddress) => ({
                              ...prevAddress,
                              firstName: e.target.value,
                            }));
                          }}
                        />
                      </div>

                      {/* Last Name Field */}
                      <div className="space-y-1">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700">
                          Last Name<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          required
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          placeholder="John"
                          value={addNewAddress?.lastName}
                          onChange={(e) => {
                            setAddNewAddress((prevAddress) => ({
                              ...prevAddress,
                              lastName: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>

                    {/* Email Field */}
                    <div className="space-y-1 py-2">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700">
                        Email Address
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="XYZ123@gmail.com"
                        value={addNewAddress?.email}
                        onChange={(e) => {
                          setAddNewAddress((prevAddress) => ({
                            ...prevAddress,
                            email: e.target.value || null,
                          }));
                        }}
                      />
                    </div>

                    {/* Contact Number Field */}
                    <div className="space-y-1 py-2">
                      <label
                        htmlFor="contact-number"
                        className="block text-sm font-medium text-gray-700">
                        Contact Number
                      </label>
                      <input
                        type="tel"
                        name="contact-number"
                        id="contact-number"
                        value={addNewAddress?.contactNumber}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="9876543210"
                        onChange={(e) => {
                          const inputValue = e?.target?.value
                            ? e?.target?.value
                                .replace(/[^0-9]/g, "")
                                .slice(0, 10)
                            : "";
                          setAddNewAddress((prevAddress) => ({
                            ...prevAddress,
                            contactNumber: inputValue,
                          }));
                        }}
                        onKeyDown={(e) => {
                          if (["e", "E", "."].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>

                    {/* Address Fields */}
                    <div className="space-y-1 py-2">
                      {/* Address Line 1 */}
                      <label
                        htmlFor="address-line1"
                        className="block text-sm font-medium text-gray-700">
                        Address Line 1<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="address-line1"
                        id="address-line1"
                        required
                        value={addNewAddress?.line1}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        onChange={(e) => {
                          setAddNewAddress((prevAddress) => ({
                            ...prevAddress,
                            line1: e.target.value,
                          }));
                        }}
                      />
                      {/* Address Line 2 */}
                      <label
                        htmlFor="address-line2"
                        className="block text-sm font-medium text-gray-700">
                        Address Line 2
                      </label>
                      <input
                        type="text"
                        name="address-line2"
                        id="address-line2"
                        value={addNewAddress?.line2}
                        className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        onChange={(e) => {
                          setAddNewAddress((prevAddress) => ({
                            ...prevAddress,
                            line2: e.target.value || null,
                          }));
                        }}
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4 py-2">
                      <div>
                        {/* City */}
                        <label
                          htmlFor="address-city"
                          className="block text-sm font-medium text-gray-700">
                          City<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="address-city"
                          id="address-city"
                          value={addNewAddress?.city}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          onChange={(e) => {
                            setAddNewAddress((prevAddress) => ({
                              ...prevAddress,
                              city: e.target.value,
                            }));
                          }}
                        />
                      </div>

                      <div>
                        {/* Zipcode */}
                        <label
                          htmlFor="zip-code"
                          className="block text-sm font-medium text-gray-700">
                          Zip Code<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="zip-code"
                          id="zip-code"
                          required
                          value={addNewAddress?.zip}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          placeholder="560075"
                          onChange={(e) => {
                            setAddNewAddress((prevAddress) => ({
                              ...prevAddress,
                              zip: e.target.value,
                            }));
                          }}
                          onBlur={() =>
                            getPlantationZoneCode(
                              addNewAddress?.isPlantingZoneAddress,
                              addNewAddress?.zip,
                              true,
                            )
                          }
                        />
                      </div>
                    </div>

                    {/* State & Country */}
                    <div className="grid grid-cols-2 gap-4 py-2">
                      <div>
                        <Tooltip
                          title={
                            !selectedCountry
                              ? "Please select a country first"
                              : ""
                          }
                          placement="top">
                          <div
                            style={{
                              display: "inline-block",
                              width: "100%",
                            }}>
                            <label
                              htmlFor="state"
                              className="block text-sm font-medium text-gray-700">
                              State<span className="text-red-500">*</span>
                            </label>
                            <AutocompleteDropdownV2
                              options={filteredStates}
                              labelKey="name"
                              valueKey="isoCode"
                              onChange={(newState) => {
                                setSelectedState(newState);

                                setAddNewAddress((prevAddress) => ({
                                  ...prevAddress,
                                  state: newState,
                                }));
                              }}
                              value={addNewAddress?.state || ""}
                              placeholder="Select a State"
                              id="name"
                            />
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700">
                          Country<span className="text-red-500">*</span>
                        </label>
                        <AutocompleteDropdownV2
                          options={Country.getAllCountries()}
                          id="name"
                          labelKey="name"
                          valueKey="isoCode"
                          onChange={(newCountry) => {
                            setSelectedCountry(newCountry);
                            setSelectedState("");
                            setFilteredStates(
                              State.getStatesOfCountry(newCountry),
                            );

                            setAddNewAddress((prevAddress) => ({
                              ...prevAddress,
                              country: newCountry,
                            }));
                          }}
                          value={addNewAddress?.country || ""}
                          placeholder="Select a Country"
                        />
                      </div>
                    </div>

                    {/* Planting Zone Checkbox */}
                    <div className="mt-2 flex items-start py-2">
                      <div className="flex h-5 items-center">
                        <input
                          id="planting-zone"
                          name="planting-zone"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-[#224E73] focus:ring-[#224E73]"
                          value={addNewAddress?.isPlantingZoneAddress}
                          onChange={(e) => {
                            setAddNewAddress((prevAddress) => ({
                              ...prevAddress,
                              isPlantingZoneAddress: e.target.checked,
                            }));
                            if (e.target.checked) {
                              getPlantationZoneCode(
                                e.target.checked,
                                addNewAddress?.zip,
                                true,
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="planting-zone"
                          className="font-medium text-gray-700">
                          Mark the address as a planting zone
                        </label>
                      </div>
                    </div>

                    {/* Planting Zone Code */}
                    <div className="space-y-1 py-2">
                      <label
                        htmlFor="planting-zone"
                        className="block text-sm font-medium text-gray-700">
                        Planting Zone Code
                      </label>
                      <input
                        type="text"
                        name="planting-zone"
                        id="planting-zone"
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder=""
                        value={addNewAddress?.plantingZoneCode}
                        onChange={(e) => {
                          setAddNewAddress((prevAddress) => ({
                            ...prevAddress,
                            plantingZoneCode: e.target.value,
                          }));
                        }}
                      />
                    </div>

                    <div className="flex items-center justify-end space-x-4 py-2">
                      <button
                        type="button"
                        onClick={() => setAddNewAddress(null)}
                        className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#224E73] focus:ring-offset-2">
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={handleAddNewAddress}
                        disabled={
                          !addNewAddress.firstName ||
                          !addNewAddress.lastName ||
                          !addNewAddress.line1 ||
                          !addNewAddress.city ||
                          !addNewAddress.zip ||
                          !addNewAddress.state ||
                          !addNewAddress.country
                        }
                        className="rounded-md border border-transparent bg-[#224E73] px-4 py-2 text-sm font-medium text-white hover:bg-[#224E73] focus:outline-none focus:ring-2 focus:ring-[#224E73] focus:ring-offset-2 disabled:cursor-not-allowed disabled:border-mediumGray disabled:bg-mediumGray">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
      <footer className="mt-auto w-full flex-shrink-0">
        <FooterActionForm
          newCustomer={newCustomer}
          setNextStep={setNextStep}
          setPrevStep={setPrevStep}
          selectedStep={selectedStep}
          showSubmit={showSubmit}
          steps={steps}
          onSubmit={createCRMCustomer}
          onCancel={() => {
            appState.showConfirmation(
              "Cancel",
              "Are you sure you want to cancel? All progress will be lost.",
              appState.hideConfirmation,
              () => {
                setNewCustomer(null);
                onCancel(false);
                appState.hideConfirmation();
              },
              "No, continue",
              "Yes, cancel",
            );
          }}
          isShippingSelected={newCustomer?.shippingAddresses?.length > 0}
          notesValidateError={notesValidateError}
        />
      </footer>
    </div>
  );
};
