import AutocompleteMultiSelectDropdown from "#components/utils/AutocompleteMultiSelectDropdown";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";
import { CalendarIcon, XIcon } from "@heroicons/react/outline";
import { useState, useRef, useEffect } from "react";
const MAX_FILTERS_VISIBLE_BY_DEFAULT = 3;
const MAX_APPLIED_FILTERS_VISIBLE_BY_DEFAULT = 4;
const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
import { DatePicker } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
const DATE_FORMAT = "DD-MM-YYYY"; // for future reference if anyone wants this value dynamic to be controlled by config.
import { DATE_RANGES } from "#utils/dateRanges";
const { RangePicker } = DatePicker;
import AutoCompleteMultiSelect from "#newUiComponents/commons/AutoCompleteMultiSelect";
import CustomBadge from "#newUiComponents/commons/CustomBadge";

const TableFilters = ({
  onChangeFilter,
  filters,
  totalResults,
  dataTableFilters,
  clearFilters,
  submitFilters,
  autoSubmitFilters,
  fetchEnhancedSearchProductResults,
  selectedSkus,
  setSelectedSkus,
}) => {
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [showAllAppliedFilters, setShowAllAppliedFilters] = useState(false);
  const filtersWithValues = Object.keys(filters).filter(
    (key) => filters[key]?.length > 0,
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const filtersToShow = showAllFilters
    ? dataTableFilters
    : dataTableFilters?.slice(0, MAX_FILTERS_VISIBLE_BY_DEFAULT) || [];

  const FloatingLabelDateRangePicker = ({ data, filters }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [displayText, setDisplayText] = useState("");
    const pickerRef = useRef(null);

    const DATE_RANGES = {
      Today: [dayjs().startOf("day"), dayjs().endOf("day")],
      Yesterday: [
        dayjs().subtract(1, "day").startOf("day"),
        dayjs().subtract(1, "day").endOf("day"),
      ],
      "Last 7 Days": [
        dayjs().subtract(7, "day").startOf("day"),
        dayjs().endOf("day"),
      ],
      "Last 30 Days": [
        dayjs().subtract(30, "day").startOf("day"),
        dayjs().endOf("day"),
      ],
      "Previous Month": [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    };
    useEffect(() => {
      const dateKey =
        data.key === "startEndDate" ? "startEndDate" : "completionStartEndDate";

      if (filters[dateKey] && filters[dateKey].length > 0) {
        let formattedStart = "";
        let formattedEnd = "";
        if (dateKey === "startEndDate") {
          formattedStart = dayjs
            .utc(filters[dateKey][0])
            .startOf("day")
            .format(DATE_FORMAT);
          formattedEnd = dayjs
            .utc(filters[dateKey][1])
            .endOf("day")
            .format(DATE_FORMAT);
          setDisplayText(`${formattedStart} ~ ${formattedEnd}`);
        } else {
          setDisplayText(`${filters[dateKey][0]} ~ ${filters[dateKey][1]}`);
        }
      }
    }, [data, filters]);

    const handleOpen = () => {
      setIsFocused(true);
    };

    const handleClear = () => {
      setIsFocused(false);
      onChangeFilter(data.key, [], false);
    };

    const value =
      filters[data.key] && filters[data.key].length > 0
        ? data.key === "startEndDate"
          ? [dayjs(filters[data.key][0]), dayjs(filters[data.key][1])]
          : data.key === "completionStartEndDate"
            ? [
                dayjs(filters[data.key][0], "DD-MM-YYYY"),
                dayjs(filters[data.key][1], "DD-MM-YYYY"),
              ]
            : []
        : [];

    return (
      <div className="datePickerFieldWrapper relative">
        <label
          className={`absolute text-black transition-all duration-200 ${
            isFocused || displayText
              ? "-top-3 left-3 text-lg"
              : "left-1 top-2 text-xl"
          }`}
          style={{
            pointerEvents: "none",
            background: "white",
            padding: "0 0.2rem",
          }}>
          {data.displayLabel}
        </label>
        <div className="relative h-full w-full cursor-pointer rounded-md">
          <input
            type="text"
            readOnly
            value={displayText}
            className="h-full w-full border-none bg-transparent pl-4 pt-4 text-lg focus:outline-none focus:ring-0"
            onClick={handleOpen}
          />
          {displayText && (
            <button
              type="button"
              onClick={handleClear}
              className="absolute right-2 top-1/2 -translate-y-1/2 transform text-3xl text-gray-500 hover:text-gray-700">
              &times;
            </button>
          )}
        </div>
        <DatePicker.RangePicker
          ref={pickerRef}
          value={value}
          onChange={(dates, dateStrings) => {
            onChangeFilter(data.key, dateStrings, false);
            setIsFocused(false);
          }}
          open={isFocused}
          onOpenChange={(open) => {
            setIsFocused(open);
          }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            opacity: 0,
          }}
          format="DD-MM-YYYY"
          ranges={DATE_RANGES}
        />
      </div>
    );
  };
  const transformData = (response) => {
    if (
      response &&
      response.data &&
      response.data.searchProducts &&
      Array.isArray(response.data.searchProducts.entities)
    ) {
      return response.data.searchProducts.entities.map((product) => {
        const { sku, id, source } = product.productData || {};

        return {
          name: (
            <div className="flex w-full items-center justify-between">
              <span className={`text-left`}>{sku}</span>
              <span className={`text-right`}>
                <CustomBadge
                  label={source}
                  bgColor="#BFDBFE"
                  textColor="#1E40AF"
                  textSize="text-sm"
                />
              </span>
            </div>
          ),
          id: id,
          uniqueIdentifier: sku,
          source: source,
        };
      });
    }
    return [];
  };

  const getFilterDivs = () => {
    const returnDivs = [];
    if (filtersWithValues && filtersWithValues.length > 0) {
      for (const filter of filtersWithValues) {
        const dataSource = dataTableFilters.find((i) => i.key === filter);

        if (!dataSource) {
          continue;
        }

        if (dataSource.typeOfField === "date-range") {
          let formattedStart = "";
          let formattedEnd = "";
          if (filter === "startEndDate") {
            formattedStart = dayjs
              .utc(filters[filter][0])
              .startOf("day")
              .format(DATE_FORMAT);
            formattedEnd = dayjs
              .utc(filters[filter][1])
              .endOf("day")
              .format(DATE_FORMAT);
          } else {
            formattedStart = filters[filter][0];
            formattedEnd = filters[filter][1];
          }
          returnDivs.push(
            <div
              className="appliedFilterWrapper"
              key={Math.random()}
              title={dataSource.displayLabel}>
              <span className="appliedFilterValue">
                {dataSource.displayLabel} :
                {` ${[formattedStart, formattedEnd].join(" & ")}`}
              </span>
              <span
                className="appliedFilterClose"
                onClick={() => {
                  onChangeFilter(filter, null, false);
                }}>
                <XIcon className="h-6 w-6" />
              </span>
            </div>,
          );
          continue;
        }

        if (dataSource.typeOfField === "single-select") {
          returnDivs.push(
            <div
              className="appliedFilterWrapper"
              key={Math.random()}
              title={dataSource.displayLabel}>
              <span className="appliedFilterValue">
                {dataSource.displayLabel}: {filters[filter]?.toUpperCase()}
              </span>
              <span
                className="appliedFilterClose"
                onClick={() => {
                  onChangeFilter(filter, null, false);
                }}>
                <XIcon className="h-6 w-6" />
              </span>
            </div>,
          );
          continue;
        }
        if (dataSource.typeOfField === "dynamic-multi-select") {
          dataSource.data.forEach((data) => {
            returnDivs.push(
              <div
                className="appliedFilterWrapper"
                key={data.id}
                title={dataSource.displayLabel}>
                <span className="appliedFilterValue">
                  {data.uniqueIdentifier}
                </span>
                <span
                  className="appliedFilterClose"
                  onClick={() => {
                    const updatedSkus = selectedSkus.filter(
                      (sku) => sku.id !== data.id,
                    );
                    setSelectedSkus(updatedSkus);
                    onChangeFilter(
                      filter,
                      updatedSkus.length > 0 ? updatedSkus : null,
                      false,
                    );
                  }}>
                  <XIcon className="h-6 w-6" />
                </span>
              </div>,
            );
          });

          continue;
        }

        const filterValues =
          (dataSource?.data &&
            dataSource?.data?.filter((item) =>
              filters[filter].includes(item.id),
            )) ||
          [];
        filterValues.map((filterValue) => {
          return returnDivs.push(
            <div
              className="appliedFilterWrapper"
              key={Math.random()}
              title={dataSource.displayLabel}>
              <span className="appliedFilterValue">
                {filterValue?.name || null}
              </span>
              <span
                className="appliedFilterClose"
                onClick={() => {
                  onChangeFilter(
                    filter,
                    filters[filter].filter(
                      (item) =>
                        item !== filterValue[dataSource.valueKey || "id"],
                    ).length > 0
                      ? filters[filter].filter(
                          (item) =>
                            item !== filterValue[dataSource.valueKey || "id"],
                        )
                      : null,
                    false,
                  );
                }}>
                <XIcon className="h-6 w-6" />
              </span>
            </div>,
          );
        });
      }
    }
    return returnDivs;
  };

  const allFilters = getFilterDivs();

  const filtersToRender = showAllAppliedFilters
    ? allFilters
    : allFilters?.slice(0, MAX_APPLIED_FILTERS_VISIBLE_BY_DEFAULT) || [];
  const moreCount = allFilters.length - filtersToRender.length;

  return (
    <div className="filterWrapper">
      <div className="filterSection">
        <div className="filterOuterWrapper">
          <span className="filterTitle font-bold">Filter</span>
        </div>
        <div className="mb-2 mt-2 text-lg">
          <span>
            Multi-select filters to create your desired filter set. View and
            deselect options as needed. Click "Apply Filters" to see the results
            below.
          </span>
        </div>
        <div className="allFilterDropdownsWrapper">
          {filtersToShow &&
            filtersToShow.map((data) => {
              if (data.typeOfField === "date-range") {
                return (
                  <FloatingLabelDateRangePicker data={data} filters={filters} />
                );
                // <Datepicker data={data}/>
              } else if (data.typeOfField === "single-select") {
                return (
                  <AutocompleteSingleSelectDropdown
                    key={data.key}
                    options={data.data}
                    labelKey={data.labelKey || "name"}
                    valueKey={data.valueKey || "id"}
                    onChange={(value) => {
                      onChangeFilter(data.key, value, autoSubmitFilters);
                    }}
                    value={filters[data.key]}
                    placeholder={data.displayLabel}
                  />
                );
              } else if (data.typeOfField === "dynamic-multi-select") {
                return (
                  <AutoCompleteMultiSelect
                    options={[]}
                    selectedValues={selectedSkus}
                    setSelectedValues={setSelectedSkus}
                    placeholder="SKU"
                    dropdownOpen={dropdownOpen}
                    setDropdownOpen={setDropdownOpen}
                    fetchOptions={fetchEnhancedSearchProductResults}
                    transformData={transformData}
                    optionClasses="w-full"
                    multiSelect="count"
                    maxTagCount={2}
                    placeholderDropdown="comboDropdown undefined"
                    placeholderBackground="bg-white"
                    placeholderText="text-black"
                  />
                );
              } else {
                return (
                  <AutocompleteMultiSelectDropdown
                    key={data.key}
                    options={data.data}
                    labelKey={data.labelKey || "name"}
                    valueKey={data.valueKey || "id"}
                    onChange={(values) => {
                      onChangeFilter(
                        data.key,
                        values && values.length ? values : null,
                        autoSubmitFilters,
                      );
                    }}
                    values={filters[data.key]}
                    placeholder={data.displayLabel}
                    multiSelect={data.typeOfField !== "single-select"}
                  />
                );
              }
            })}
          {dataTableFilters.length > MAX_FILTERS_VISIBLE_BY_DEFAULT && (
            <div
              className="showAllFilters"
              onClick={() => setShowAllFilters(!showAllFilters)}>
              {showAllFilters ? "Less Filters" : "More Filters"}
            </div>
          )}
        </div>
      </div>
      <div className="appliedFiltersInnerWrapper mb-4 mt-4">
        {filtersToRender}
        {allFilters.length > MAX_APPLIED_FILTERS_VISIBLE_BY_DEFAULT && (
          <div
            className="appliedFilterWrapper cursor-pointer rounded-md border-2 border-primaryAccent"
            onClick={() => setShowAllAppliedFilters(!showAllAppliedFilters)}>
            <span className="appliedFilterValue">
              {!showAllAppliedFilters ? `+${moreCount}` : `See Less`}
            </span>
          </div>
        )}
        {filtersWithValues?.filter((i) => i !== "keyword").length > 0 && (
          <div className="clearAllFilters" onClick={clearFilters}>
            Clear Filters
          </div>
        )}
      </div>
      <div className="applyFilters" onClick={submitFilters}>
        Apply Filters
      </div>
      <div className="appliedFiltersSection">
        <div className="appliedFiltersOuterWrapper">
          <div className="appliedFiltersTitle">
            <span className="appliedFiltersTitleTextHeader">
              {totalResults}
            </span>
            <span className="appliedFiltersTitleText"> results found</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableFilters;
