import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_CURRENT_PACKER_BATCH,
  GET_STATIONS,
  FETCH_SPECIFIC_ORDER,
  GET_ORDERS,
  GET_WORKFLOW_BY_ATTRIBUTES,
  GET_NEW_PACKER_BATCH,
  GET_BOX_TYPES,
  GET_SKU,
  GET_FBA_LABEL_GENERATION_STATUS,
  GET_INTEGRATIONS,
} from "#queries";
import {
  SCAN_PACKER_BARCODE,
  CONFIRM_PACK_ITEM,
  CONFIRM_PACKER_DROPOFF,
  LOGOUT_USER,
  SET_STATION,
  SET_PACKER_BOXES,
  UPDATE_ORDER_LINE_ITEM,
  IMMEDIATE_SCAN,
  CANCEL_PACK_ITEM,
  RESTART_PACK,
  CONFIRM_TRANSPORT_INFORMATION,
  CONFIRM_TYPE_OF_SHIPMENT,
  SWITCH_TYPE_OF_SHIPMENT,
  CONFIRM_PALLET_INFORMATION,
  MARK_HAZMAT_CONFIRMED,
  SUBMIT_TRACKING_INFORMATION,
  MARK_ORDER_EXCEPTION,
  PAUSE_PACK,
  UNPACK_BOX,
  UNPACK_ITEM,
  PACK_ITEMS,
  DUPLICATE_BOX,
  LABEL_EVENT,
  UPDATE_ORDER_CARRIER_INFO,
} from "#mutations";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { EntityContext } from "#contexts/entity";
const config = require("config");
import io from "socket.io-client";
import { printShippingLabel } from "#utils/printShippingLabel";
import axios from "axios";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { MasterDataContext } from "#contexts/masterData";
import {
  TENANT_SHIPPING_LABEL_PRINTER_ENUM,
  PAPER_FORMAT_ENUM,
} from "../../constants/printer-settings";
import defaultBoxTypes from "#constants/default-box-types";
import { downloadPackingLabels } from "../../utils/printPackingLabel";
import { print2DBarcodeLabel } from "../../utils/print2DBarcodeLabel";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "../../constants/feature-flags";
import { isDelmarTenant } from "#utils/tenantCheck";
import { saveAs } from "file-saver";
import { handleFnSkuPrinting } from "./withPrepperLogic";

const MINIMUM_FREIGHT_WEIGHT_AMAZON = 150;
const FBA_BOX_WEIGHT_LIMIT = 50;
const FBA_BOX_MECHANICAL_LIFT_WARNING_WEIGHT_LIMIT = 100;
const ALERT_TIMEOUT_MS = 5000;

const NUMBER_OF_BOXES_TO_FETCH = 100;

const isWaseTenant = (subdomain) => {
  return ["wase"].includes(subdomain);
};

const withPackerLogic = (WrappedComponent) => {
  return (props) => {
    const ldClient = useLDClient();
    const [groupedCasesBySku, setGroupedCasesBySku] = useState(null);
    const [orderNote, setOrderNote] = useState(null);
    const masterData = useContext(MasterDataContext);
    const [customer, setCustomer] = useState(undefined);
    const [workflow, setWorkflow] = useState(undefined);
    const [addBox, setAddBox] = useState(false);
    const [boxTypes, setBoxTypes] = useState(null);
    const [currentBatch, setCurrentBatch] = useState(null);
    const [weight, setWeight] = useState(0);
    const entity = useContext(EntityContext);
    const [order, setOrder] = useState(null);
    const [boxDetails, setBoxDetails] = useState([]);
    const [transportMode, setTransportMode] = useState(null);
    const fetchSpecificOrder = useQuery(FETCH_SPECIFIC_ORDER);
    const fetchSpecificOrderForPrinting = useQuery(FETCH_SPECIFIC_ORDER);
    const [stations, setStations] = useState([]);
    const [selectedStation, setSelectedStation] = useState(null);
    const logoutQuery = useQuery(LOGOUT_USER);
    const setStationQuery = useQuery(SET_STATION);
    const [currentItemMismatch, setCurrentItemMismatch] = useState(null);
    const confirmDropoffQuery = useQuery(CONFIRM_PACKER_DROPOFF);
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const stationsQuery = useQuery(GET_STATIONS);
    const currentBatchQuery = useQuery(GET_CURRENT_PACKER_BATCH);
    const initialCurrentBatchQuery = useQuery(GET_CURRENT_PACKER_BATCH);
    const ordersQuery = useQuery(GET_ORDERS);
    const setBoxesQuery = useQuery(SET_PACKER_BOXES);
    const setBoxesBeforeDuplicationQuery = useQuery(SET_PACKER_BOXES);
    const getWorkflowByAttributesQuery = useQuery(GET_WORKFLOW_BY_ATTRIBUTES);
    const updateOrderLineItemQuery = useQuery(UPDATE_ORDER_LINE_ITEM);
    const immediateScanQuery = useQuery(IMMEDIATE_SCAN);
    const cancelItemQuery = useQuery(CANCEL_PACK_ITEM);
    const restartActivityQuery = useQuery(RESTART_PACK);
    const restartActivityInAsync = useQuery(RESTART_PACK);
    const labelEventQuery = useQuery(LABEL_EVENT);
    const confirmTransportInformationQuery = useQuery(
      CONFIRM_TRANSPORT_INFORMATION,
    );
    const confirmPalletInformationQuery = useQuery(CONFIRM_PALLET_INFORMATION);
    const confirmTypeOfShipmentQuery = useQuery(CONFIRM_TYPE_OF_SHIPMENT);
    const switchTypeOfShipmentQuery = useQuery(SWITCH_TYPE_OF_SHIPMENT);
    const markHazmatConfirmedQuery = useQuery(MARK_HAZMAT_CONFIRMED);
    const submitTrackingInformationQuery = useQuery(
      SUBMIT_TRACKING_INFORMATION,
    );
    const [ordersData, setOrdersData] = useState({});
    const markOrderExceptionQuery = useQuery(MARK_ORDER_EXCEPTION);
    const pauseActivityQuery = useQuery(PAUSE_PACK);
    const unpackBoxQuery = useQuery(UNPACK_BOX);
    const unpackItemQuery = useQuery(UNPACK_ITEM);
    const packItemsQuery = useQuery(PACK_ITEMS);
    const duplicateBoxQuery = useQuery(DUPLICATE_BOX);
    const getFbaLabelGenerationStatusQuery = useQuery(
      GET_FBA_LABEL_GENERATION_STATUS,
    );

    const [itemsToPack, setItemsToPack] = useState({});
    const [isAddItemQuantityExceed, isSetAddItemQuantityExceed] =
      useState(false);
    const [itemsToUnPack, setItemsToUnPack] = useState({});
    const boxTypesQuery = useQuery(GET_BOX_TYPES);

    const getCurrentProductQuery = useQuery(GET_SKU);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [username, setUsername] = useState(
      localStorage.getItem("username") ?? "",
    );
    const fetchOrderForDownloadingBoxLabels = useQuery(FETCH_SPECIFIC_ORDER);
    const fetchOrderForPrintingBoxLabels = useQuery(FETCH_SPECIFIC_ORDER);
    const [showAssociateUsernameInput, setShowAssociateUsernameInput] =
      useState(true);
    const [numberOfBoxesToDuplicate, setNumberOfBoxesToDuplicate] =
      useState(null);
    const [fbaLabelGenerationStatus, setFbaLabelGenerationStatus] =
      useState(null);
    const updateCarrierInfoQuery = useQuery(UPDATE_ORDER_CARRIER_INFO);

    useEffect(() => {
      if (getCurrentProductQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getCurrentProductQuery.data) {
        setCurrentProduct(getCurrentProductQuery.data.specificInventory);
      }

      if (getCurrentProductQuery.error) {
        setCurrentProduct(null);
      }
    }, [
      getCurrentProductQuery.loading,
      getCurrentProductQuery.data,
      getCurrentProductQuery.error,
    ]);

    useEffect(() => {
      if (currentBatch && currentBatch.boxes) {
        setWeightOfBox(currentBatch.boxes.length - 1, weight);
      }
    }, [weight]);

    useEffect(() => {
      if (cancelItemQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (cancelItemQuery.error) {
        appState.setAlert(
          cancelItemQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
        currentBatchQuery.fetchData();
      }

      if (cancelItemQuery.data) {
        currentBatchQuery.fetchData();
      }
    }, [cancelItemQuery.loading, cancelItemQuery.data, cancelItemQuery.error]);

    useEffect(() => {
      if (auth?.user?.station && stations.length > 0) {
        const filtersSet = {
          orderStatus: ["IN-PROCESS"],
          subStatus: [
            "PREPPING_COMPLETED",
            "PICKING_COMPLETED",
            "PACKING_STARTED",
          ],
        };
        const station = stations?.find(
          (station) => station.id === auth?.user?.station,
        );
        if (station && station.warehouses?.length) {
          filtersSet.warehouse = station.warehouses;
        }
        ordersData.filters = filtersSet;
        setOrdersData({
          ...ordersData,
          pageNumber: 1,
          perPage: 25,
          sort: "orderDate",
        });
        appState.setAdminPageBgColor("bg-white");

        ordersQuery.fetchData({
          perPage: 25,
          filters: filtersSet,
          paginated: false,
          pageNumber: 1,
          sort: "orderDate",
        });
      }

      return () => {
        entity.resetEntities();
      };
    }, [auth?.user?.station, stations]);

    useEffect(() => {
      if (currentBatchQuery.error) {
        setCurrentBatch(null);
        setShowAssociateUsernameInput(true);
      }

      if (currentBatchQuery.data) {
        const currentBatchData = currentBatchQuery.data.getCurrentPackerBatch;
        if (currentBatchData.currentItem) {
          currentBatchData.currentItem.quantity = 1;
        }
        if (
          currentBatch?.boxes?.length > 0 &&
          currentBatchData?.boxes?.length > 0 &&
          currentBatch.boxes.length === currentBatchData.boxes.length
        ) {
          if (hasBoxDimensionsChanged(currentBatch, currentBatchData)) {
            setCurrentBatch(currentBatchData);
          } else {
            setCurrentBatch({ ...currentBatchData, boxes: currentBatch.boxes });
          }
          setShowAssociateUsernameInput(true);
        } else {
          setCurrentBatch(currentBatchData);
          setShowAssociateUsernameInput(true);
        }
        if (currentBatchData.currentItem?.productId) {
          getCurrentProductQuery.fetchData({
            id: currentBatchData.currentItem.productId,
          });
        }
        if (currentBatchData?.boxes?.length > 0) {
          setGroupedCasesBySku(_.groupBy(currentBatchData.boxes, "sku"));
        } else {
          setGroupedCasesBySku(null);
        }
      }
    }, [
      currentBatchQuery.loading,
      currentBatchQuery.error,
      currentBatchQuery.data,
    ]);

    useEffect(() => {
      if (setBoxesQuery.error) {
        // setCurrentBatch(null);
        currentBatchQuery.fetchData();
        appState.setAlert(
          setBoxesQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }

      if (setBoxesQuery.data) {
        currentBatchQuery.fetchData();
      }

      if (setBoxesQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [setBoxesQuery.loading, setBoxesQuery.error, setBoxesQuery.data]);

    useEffect(() => {
      if (ordersQuery.data) {
        entity.setEntities({
          ...ordersQuery.data.orders,
          ...ordersQuery.variables,
        });
        appState.removeLoading();
      }
    }, [ordersQuery.loading, ordersQuery.error, ordersQuery.data]);

    useEffect(() => {
      if (ordersQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (ordersQuery.data) {
        // console.log(ordersQuery.data);
        setOrdersData({
          ...ordersData,
          orders: ordersQuery.data.orders.entities,
          total: ordersQuery.data.orders.total,
        });
      }

      if (ordersQuery.error) {
        setOrdersData({});
      }
    }, [ordersQuery.data, ordersQuery.loading, ordersQuery.error]);

    useEffect(() => {
      if (auth && auth.user && auth.user.id) {
        const socket = io(localStorage.socketService, {
          path: "/socket-service/socket.io",
        });

        socket.on("observeMessages", (payload) => {
          if (payload.error === true) {
            // dispatch(setAlert(payload.message, "error", ALERT_TIMEOUT_MS));
            appState.setAlert(payload.message, "error", ALERT_TIMEOUT_MS);
          } else if (payload.message) {
            appState.setAlert(payload.message, "success", ALERT_TIMEOUT_MS);
            currentBatchQuery.fetchData();
          }
        });

        // socket.on("observeBatch", (payload) => {
        //   console.log("2", payload);
        //   if (payload && payload.prepackingId) {
        //     dispatch(fetchCurrentBatch());
        //   }
        // });

        // socket.on("authQrCode", (payload) => {
        //   dispatch({
        //     type: prepackerActions.FETCH_ASSOCIATED_SESSION.SUCCESS,
        //     payload,
        //   });
        //   dispatch(setAlert("Successfully validated scanner", "success", ALERT_TIMEOUT_MS));
        // });

        socket.on("connect", function () {
          console.log("connected!");
        });

        socket.on("message", (message) => {
          console.log(message);
        });
        socket.emit("subscribe", { roomId: auth.user.id });
      }
    }, [auth]);

    useEffect(() => {
      if (immediateScanQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (immediateScanQuery.data) {
        const barcode = immediateScanQuery.data.immediateScan.barcode;
        if (appState.subdomain === "kingly") {
          let currItem = currentBatch?.currentItem;
          if (currItem) {
            // SCAN SERIAL NUMBER
            try {
              let orderLineItems = JSON.parse(
                JSON.stringify(order.orderLineItems),
              );
              const orderLineItem = orderLineItems.findIndex(
                (item) => item.sku === currentBatch.currentItem.sku,
              );
              return updateOrderLineItemQuery.fetchData({
                orderLineItemInput: {
                  ...orderLineItems[orderLineItem],
                  serialNumber: barcode,
                },
              });
            } catch (err) {}
          }
        }
        return scanBarcodeQuery.fetchData({ code: barcode });
      }
    }, [
      immediateScanQuery.loading,
      immediateScanQuery.error,
      immediateScanQuery.data,
    ]);

    useEffect(() => {
      const client = new W3CWebSocket("ws://127.0.0.1:8456");
      client.onopen = () => {
        console.log("Connected to electron app");
      };
      client.onmessage = (message) => {
        console.log("message received", message);
        const parsed = JSON.parse(message.data);
        let relevant = parsed;
        if (relevant && relevant.data) {
          relevant = relevant.data;
        }

        switch (relevant.key) {
          case "scanner-data":
            if (relevant.message) {
              return immediateScanQuery.fetchData({
                barcode: relevant.message,
              });
            }
          case "dymo-m10-attach":
            if (relevant.message === "attached") {
            } else if (relevant.message === "detached") {
            }
            break;
          case "dymo-m10-data":
            try {
              const checkWeight = relevant.message.split(" ");
              let weightVar = parseInt(checkWeight[0]);
              if (isNaN(weightVar) === false) {
                setWeight(weightVar / 10);
              }
            } catch (err) {
              console.log(err);
            }

            break;
          case "dymo-error":
            break;
          case "dymo-attach":
            if (relevant.message === "attached") {
            } else if (relevant.message === "detached") {
            }
            break;
          case "dymo-data":
            try {
              const checkWeight = relevant.message.split(" ");
              let weightVar = parseInt(checkWeight[0]);
              // set the weight into the last box of the current batch
              if (isNaN(weightVar) === false) {
                setWeight(weightVar / 10);
              }
            } catch (err) {
              console.log(err);
            }

            break;
        }
      };
    }, []);

    useEffect(() => {
      if (setStationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (setStationQuery.error) {
        if (
          setStationQuery.error?.message &&
          setStationQuery.error.message.indexOf("already") !== -1
        ) {
          appState.showConfirmation(
            "Error logging in",
            setStationQuery.error.message,
            () => {
              setStationQuery.fetchData({
                station: selectedStation,
                logoutAll: true,
              });
              appState.hideConfirmation();
            },
            appState.hideConfirmation,
          );
        } else {
          appState.setAlert(
            setStationQuery.error.message,
            "error",
            ALERT_TIMEOUT_MS,
          );
        }
      }

      if (setStationQuery.data && setStationQuery.data) {
        // appState.setAlert(setStationQuery.data.message, "success", ALERT_TIMEOUT_MS);
        window.location = "/packer";
      }
    }, [setStationQuery.data, setStationQuery.error, setStationQuery.loading]);

    useEffect(() => {
      getWorkflowByAttributesQuery.fetchData({
        attributes: { name: "Packing" },
      });
      // currentBatchQuery.fetchData();
      stationsQuery.fetchData({
        filters: {
          hopstackModule: ["Packing"],
          all: true,
        },
      });
    }, []);

    useEffect(() => {
      boxTypesQuery.fetchData({
        perPage: NUMBER_OF_BOXES_TO_FETCH,
        filters: {},
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
    }, []);

    useEffect(() => {
      let customBoxTypes = [];
      if (
        boxTypesQuery.data &&
        boxTypesQuery.data.boxTypes?.entities?.length > 0
      ) {
        // FILTER ONLY THOSE THAT HAVE LENGTH WIDTH AND HEIGHT
        const filteredBoxTypes = boxTypesQuery.data.boxTypes.entities.filter(
          (item) => item.height && item.width && item.length,
        );

        customBoxTypes = customBoxTypes.concat(
          filteredBoxTypes.map((item) => ({
            name: item.name,
            height: item.height,
            width: item.width,
            length: item.length,
          })),
        );
      }

      if (customBoxTypes.length === 0) {
        customBoxTypes = customBoxTypes.concat(defaultBoxTypes);
      }
      // push the custom box
      customBoxTypes.push({
        name: "Add Custom",
        height: "?",
        width: "?",
        length: "?",
      });

      setBoxTypes(customBoxTypes);
    }, [boxTypesQuery.loading, boxTypesQuery.error, boxTypesQuery.data]);

    useEffect(() => {
      if (logoutQuery.data) {
        appState.setAlert(logoutQuery.data.logout.message);
        auth.logout();
      }

      if (logoutQuery.error) {
        auth.logout();
      }
    }, [logoutQuery.data, logoutQuery.loading, logoutQuery.error]);

    useEffect(() => {
      if (stationsQuery.error) {
        setStations([]);
      }

      if (stationsQuery.data && stationsQuery.data.stations) {
        const foundStations = stationsQuery.data.stations.entities.filter(
          (item) => item.hopstackModule.toString().toLowerCase() === "packing",
        );
        setStations(foundStations);
      }
    }, [stationsQuery.data, stationsQuery.loading, stationsQuery.error]);

    const scanBarcodeQuery = useQuery(SCAN_PACKER_BARCODE);
    const confirmPackItemQuery = useQuery(CONFIRM_PACK_ITEM);

    useEffect(() => {
      if (fetchSpecificOrderForPrinting.data) {
        // DELMAR SPECIFIC PRINTING LOGIC
        generatePrintListAndTriggerPrint(
          fetchSpecificOrderForPrinting.data.order,
        );
      }
    }, [fetchSpecificOrderForPrinting.data]);

    const generatePrintListAndTriggerPrint = (currentOrder) => {
      const printingList = getPrintFiles(currentOrder, appState);
      if (printingList?.length > 0) {
        appState.setAlert(`Printing labels`, "success", ALERT_TIMEOUT_MS);
      }
      for (const shippingLabel of printingList) {
        printShippingLabel(
          shippingLabel.file,
          appState,
          shippingLabel.printerName,
          shippingLabel.duplex,
        );
        labelEventQuery.fetchData({
          warehouse: currentOrder.warehouse,
          customer: currentOrder.customer,
          order: currentOrder.id,
          quantity: 1,
          labelType: "SHIPPING_LABEL",
          dropship: false,
        });
      }
    };

    useEffect(() => {
      if (currentBatchQuery.loading) {
        appState.setLoading();
      } else {
        if (
          currentBatchQuery.data &&
          currentBatchQuery.data.getCurrentPackerBatch
        ) {
          setCurrentItemMismatch(null);
          const orderIds = _.uniq(
            currentBatchQuery.data.getCurrentPackerBatch.workingList.map(
              (i) => i.order,
            ),
          );
          if (orderIds.length === 1) {
            setCustomer(currentBatchQuery.data.getCurrentPackerBatch.customer);
            fetchSpecificOrder.fetchData({
              orderId:
                currentBatchQuery.data.getCurrentPackerBatch.workingList[0]
                  .order,
              printBoxLabels: currentBatchQuery?.variables?.printBoxLabels,
            });
          } else if (
            currentBatchQuery.data.getCurrentPackerBatch.currentItem?.order
          ) {
            setCustomer(
              currentBatchQuery.data.getCurrentPackerBatch?.currentItem
                ?.customer,
            );
            fetchSpecificOrder.fetchData({
              orderId:
                currentBatchQuery.data.getCurrentPackerBatch.currentItem?.order,
            });
          }
        }
        appState.removeLoading();
      }
    }, [
      currentBatchQuery.loading,
      currentBatchQuery.data,
      currentBatchQuery.error,
    ]);

    useEffect(() => {
      setBoxDetails(handleBoxDetails(order, currentBatch));
    }, [order, currentBatch]);

    useEffect(() => {
      if (fetchSpecificOrder.data) {
        setOrder(fetchSpecificOrder.data.order);
        if (
          currentBatch &&
          currentBatch.status === "COMPLETED" &&
          isDelmarTenant(appState.subdomain) === false
        ) {
          if (
            (fetchSpecificOrder.data.order.source === "FBA" &&
              fetchSpecificOrder.data.order.selectedCarrierServiceId !== null &&
              fetchSpecificOrder.data.order.selectedCarrierSource !==
                "Amazon" &&
              currentBatch.status === "COMPLETED") ||
            fetchSpecificOrder?.variables?.printBoxLabels === true
          ) {
            printBoxLabels(fetchSpecificOrder.data.order);
          }
          if (
            fetchSpecificOrder.data.order.boxInformationNeeded !== false &&
            fetchSpecificOrder.data.order.source === "FBA" &&
            !currentBatch.confirmedBoxContents &&
            currentBatch.status === "COMPLETED"
          ) {
          } else if (
            fetchSpecificOrder.data.order.shippingLabels?.length > 0 &&
            currentBatch.status === "COMPLETED" &&
            (!isWaseTenant(appState.subdomain) ||
              fetchSpecificOrder?.data?.order?.source === "FBA")
          ) {
            appState.setAlert(
              "Printing labels...",
              "success",
              ALERT_TIMEOUT_MS,
            );
            for (const shippingLabel of fetchSpecificOrder.data.order
              .shippingLabels) {
              printShippingLabel(
                shippingLabel,
                appState,
                TENANT_SHIPPING_LABEL_PRINTER_ENUM[appState.subdomain] ||
                  TENANT_SHIPPING_LABEL_PRINTER_ENUM.default,
              );
              labelEventQuery.fetchData({
                warehouse: fetchSpecificOrder.data.order.warehouse,
                customer: fetchSpecificOrder.data.order.customer,
                order: fetchSpecificOrder.data.order.id,
                quantity: 1,
                labelType: "SHIPPING_LABEL",
                dropship: false,
              });
            }
          } else if (
            fetchSpecificOrder.data.order.shippingLabel &&
            currentBatch.status === "COMPLETED" &&
            (!isWaseTenant(appState.subdomain) ||
              fetchSpecificOrder?.data?.order?.source === "FBA")
          ) {
            appState.setAlert(
              "Printing labels...",
              "success",
              ALERT_TIMEOUT_MS,
            );
            printShippingLabel(
              fetchSpecificOrder.data.order.shippingLabel,
              appState,
              TENANT_SHIPPING_LABEL_PRINTER_ENUM[appState.subdomain] ||
                TENANT_SHIPPING_LABEL_PRINTER_ENUM.default,
            );
            labelEventQuery.fetchData({
              warehouse: fetchSpecificOrder.data.order.warehouse,
              customer: fetchSpecificOrder.data.order.customer,
              order: fetchSpecificOrder.data.order.id,
              quantity: 1,
              labelType: "SHIPPING_LABEL",
              dropship: false,
            });
          }
        }
      }

      if (fetchSpecificOrder.error) {
        appState.setAlert(
          fetchSpecificOrder.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
        setOrder(null);
      }

      if (fetchSpecificOrder.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      fetchSpecificOrder.loading,
      fetchSpecificOrder.error,
      fetchSpecificOrder.data,
    ]);

    useEffect(() => {
      if (scanBarcodeQuery.error) {
        appState.setAlert(
          scanBarcodeQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
        currentBatchQuery.fetchData();
        appState.removeLoading();
      }
      if (scanBarcodeQuery.loading) {
        appState.setLoading();
        setTimeout(() => {
          appState.removeLoading();
        }, 500);
      } else {
        if (
          scanBarcodeQuery.data &&
          scanBarcodeQuery.data.scanPackerBarcode &&
          scanBarcodeQuery.data.scanPackerBarcode.message
        ) {
          appState.setAlert(
            scanBarcodeQuery.data.scanPackerBarcode.message,
            "success",
            ALERT_TIMEOUT_MS,
          );
          currentBatchQuery.fetchData();
          appState.removeLoading();
        }
      }
    }, [
      scanBarcodeQuery.loading,
      scanBarcodeQuery.data,
      scanBarcodeQuery.error,
    ]);
    useEffect(() => {
      if (confirmPackItemQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmPackItemQuery.data &&
          confirmPackItemQuery.data.confirmPackItem &&
          confirmPackItemQuery.data.confirmPackItem.message
        ) {
          setCurrentItemMismatch(null);
          currentBatchQuery.fetchData();
        } else if (confirmPackItemQuery.error) {
          appState.setAlert(
            confirmPackItemQuery.error.message,
            "error",
            ALERT_TIMEOUT_MS,
          );
          currentBatchQuery.fetchData();
        }
        appState.removeLoading();
      }
    }, [
      confirmPackItemQuery.loading,
      confirmPackItemQuery.data,
      confirmPackItemQuery.error,
    ]);

    useEffect(() => {
      if (confirmTransportInformationQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmTransportInformationQuery.data &&
          confirmTransportInformationQuery.data.confirmTransportInformation &&
          confirmTransportInformationQuery.data.confirmTransportInformation
            .message
        ) {
          currentBatchQuery.fetchData();
          if (order?.source === "FBA") {
            getFbaLabelGenerationStatusQuery.fetchData({
              shipmentId: order.orderId,
            });
          }
        } else if (confirmTransportInformationQuery.error) {
          appState.setAlert(
            confirmTransportInformationQuery.error?.errors
              ?.map((e) => e.message)
              ?.join(", ") ?? confirmTransportInformationQuery.error.message,
            "error",
            ALERT_TIMEOUT_MS,
          );
          currentBatchQuery.fetchData({ printBoxLabels: true });
        }
        appState.removeLoading();
      }
    }, [
      confirmTransportInformationQuery.loading,
      confirmTransportInformationQuery.data,
      confirmTransportInformationQuery.error,
    ]);

    useEffect(() => {
      if (markOrderExceptionQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (markOrderExceptionQuery.data) {
        appState.setAlert(
          markOrderExceptionQuery.data.markOrderException.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        currentBatchQuery.fetchData();
        ordersQuery.fetchData({
          perPage: 25,
          pageNumber: 1,
          filters: ordersData.filters,
          paginated: false,
          sort: "orderDate",
        });
      }

      if (markOrderExceptionQuery.error) {
        appState.setAlert(
          markOrderExceptionQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      markOrderExceptionQuery.loading,
      markOrderExceptionQuery.error,
      markOrderExceptionQuery.data,
    ]);

    useEffect(() => {
      if (confirmDropoffQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmDropoffQuery.data &&
          confirmDropoffQuery.data.confirmPackerDropoff.message
        ) {
          appState.setAlert(
            confirmDropoffQuery.data.confirmPackerDropoff.message,
            "success",
            ALERT_TIMEOUT_MS,
          );
          currentBatchQuery.fetchData();
          ordersQuery.fetchData({
            perPage: 25,
            pageNumber: 1,
            filters: ordersData.filters,
            paginated: false,
            sort: "orderDate",
          });
        }
        if (confirmDropoffQuery.error) {
          currentBatchQuery.fetchData();
        }
        appState.removeLoading();
      }
    }, [
      confirmDropoffQuery.loading,
      confirmDropoffQuery.data,
      confirmDropoffQuery.error,
    ]);

    const triggerConfirmPackItem = (currentItem, status) => {
      appState.showConfirmation(
        "Confirmation",
        `Are you sure you want to mark this item as ${status}?`,
        () => {
          confirmPackItemQuery.fetchData({
            itemId: currentItem,
            id: currentBatchQuery.data?.getCurrentPackerBatch.id,
            status,
          });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const hasBoxDimensionsChanged = (currentBatch, currentBatchData) => {
      if (!currentBatch?.boxes || !currentBatchData?.boxes) return false;

      return (currentBatchData.boxes || []).some((newBox) => {
        // Find matching box in current batch
        const existingBox = (currentBatch.boxes || []).find(
          (currentBox) => currentBox.name === newBox.name,
        );

        if (!existingBox) return true;

        // Check if dimensions are different
        return (
          existingBox.width !== newBox.width ||
          existingBox.length !== newBox.length ||
          existingBox.height !== newBox.height
        );
      });
    };

    const simulateTote = async () => {
      if (entity.entities.length === 0) {
        appState.setAlert("No open orders found", "error", ALERT_TIMEOUT_MS);
        return;
      }

      const findOrder = entity.entities.find(
        (item) => item.orderStatus === "IN-PROCESS" && item.tote !== null,
      );
      if (!findOrder) {
        appState.setAlert("No open order found", "error", ALERT_TIMEOUT_MS);
        return;
      }
      // return await axios.post(`${config.BASE_URL}/api/scan`, {
      //   hostname: "Pack-1",
      //   datalabel: findOrder.tote,
      //   simulate: true,
      // });

      return scanBarcodeQuery.fetchData({
        code: findOrder.tote,
      });
    };

    useEffect(() => {
      if (isAddItemQuantityExceed) {
        appState.setAlert(
          "Please enter quantity less than or equal to available quantity",
          "error",
          ALERT_TIMEOUT_MS,
        );
        isSetAddItemQuantityExceed(false);
      }
    }, [isAddItemQuantityExceed]);

    const simulateItemScan = (item) => {
      let code = null;

      if (item.fnSku) {
        code = item.fnSku;
      } else if (item.upc && item.upc[0]) {
        code = item.upc[0];
      } else if (item.lpn && item.lpn[0]) {
        code = item.lpn[0];
      } else {
        code = item.sku;
      }

      if (appState.subdomain === "kingly") {
        let currItem = currentBatch?.currentItem;
        if (currItem) {
          // SCAN SERIAL NUMBER
          try {
            let orderLineItems = JSON.parse(
              JSON.stringify(order.orderLineItems),
            );
            const orderLineItem = orderLineItems.findIndex(
              (item) => item.sku === currentBatch.currentItem.sku,
            );
            return updateOrderLineItemQuery.fetchData({
              orderLineItemInput: {
                ...orderLineItems[orderLineItem],
                serialNumber: code,
              },
            });
          } catch (err) {}
        }
      }
      return scanBarcodeQuery.fetchData({
        code,
      });
    };

    const mergeProductsByUniqueIdentifier = (items) => {
      const mergedItems = {};

      items.forEach((item) => {
        const { sku, quantity } = item;
        if (mergedItems[sku]) {
          mergedItems[sku].quantity += quantity;
        } else {
          mergedItems[sku] = { ...item };
        }
      });
      return Object.values(mergedItems);
    };
    const handleBoxDetails = (order = {}, currentBatch = {}) => {
      if (order && currentBatch) {
        const productsAfterMerging = currentBatch?.workingList?.map(
          (product) => {
            const foundProductInOrder = order?.orderLineItems?.find(
              (orderProduct) => orderProduct.sku === product.sku,
            );

            // Find existing commodityCode in boxDetails state if it exists

            const existingProductDetail = boxDetails
              ?.flatMap((box) => box.items || [])
              .find((item) => item.sku === foundProductInOrder?.sku);

            return {
              ...product,
              unitCost: foundProductInOrder?.price || 0,
              commodityCode:
                existingProductDetail?.commodityCode ||
                foundProductInOrder?.attributes?.commodityCode ||
                "",
              productId: foundProductInOrder?.productId,
            };
          },
        );

        const temBoxDetails = currentBatch?.boxes?.map((box) => {
          const items = productsAfterMerging?.filter(
            (product) => product.boxName === box.name,
          );
          return {
            ...box,
            items,
          };
        });

        return temBoxDetails?.map((box) => ({
          ...box,
          items: mergeProductsByUniqueIdentifier(box?.items),
        }));
      } else return [];
    };

    const print2DBarcodeBoxLabel = (boxName, download = false) => {
      const box = currentBatch.boxes.find((item) => item.name === boxName);
      if (box) {
        const boxItems = currentBatch.workingList.filter(
          (item) => item.boxName === boxName,
        );
        let barcodeString = `AMZN,PO:${order.orderId},`;
        let barcodeStringSplitByLine = `AMZN,PO:${order.orderId},`;
        const groupedItemsByFnSku = boxItems.reduce((acc, item) => {
          if (!acc[item.fnSku]) {
            acc[item.fnSku] = 0;
          }
          acc[item.fnSku] += item.availableQuantity;
          return acc;
        }, {});
        for (const fnSku in groupedItemsByFnSku) {
          const actualItem = order.orderProducts.find((i) => i.fnSku === fnSku);
          const barcodeStringRaw = `ASIN:${actualItem.asin},QTY:${
            groupedItemsByFnSku[fnSku]
          },EXP:${
            boxItems.find(
              (i) => !!i.bestByDate && i.bestByDate !== "" && i.fnSku === fnSku,
            )
              ? boxItems.find(
                  (i) =>
                    !!i.bestByDate && i.bestByDate !== "" && i.fnSku === fnSku,
                ).ExpirationDate
              : ""
          },`;
          barcodeString += barcodeStringRaw;
          barcodeStringSplitByLine += `\n${barcodeStringRaw}`;
        }
        print2DBarcodeLabel(
          barcodeString,
          barcodeStringSplitByLine,
          currentBatch,
          order,
          boxName,
          1,
          TENANT_SHIPPING_LABEL_PRINTER_ENUM[appState.subdomain] ||
            TENANT_SHIPPING_LABEL_PRINTER_ENUM.default,
          download,
          appState,
        );
      }
    };

    useEffect(() => {
      if (fetchOrderForDownloadingBoxLabels.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchOrderForDownloadingBoxLabels.data) {
        const labelsToDownload = [];
        if (fetchOrderForDownloadingBoxLabels.data.order.boxLabels) {
          labelsToDownload.push(
            fetchOrderForDownloadingBoxLabels.data.order.boxLabels,
          );
        }

        for (const label of labelsToDownload) {
          saveAs(label);
        }
      }

      if (fetchOrderForDownloadingBoxLabels.error) {
        appState.setAlert(
          fetchOrderForDownloadingBoxLabels.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      fetchOrderForDownloadingBoxLabels.data,
      fetchOrderForDownloadingBoxLabels.loading,
    ]);

    useEffect(() => {
      if (fetchOrderForPrintingBoxLabels.data) {
        const orderData = fetchOrderForPrintingBoxLabels.data.order;
        appState.setAlert(
          "Printing box labels...",
          "success",
          ALERT_TIMEOUT_MS,
        );
        printShippingLabel(
          orderData.boxLabels,
          appState,
          TENANT_SHIPPING_LABEL_PRINTER_ENUM[appState.subdomain] ||
            TENANT_SHIPPING_LABEL_PRINTER_ENUM.default,
        );
        labelEventQuery.fetchData({
          warehouse: orderData.warehouse,
          customer: orderData.customer,
          order: orderData.id,
          quantity: 1,
          labelType: "SHIPPING_LABEL",
          dropship: false,
        });
      }

      if (fetchOrderForPrintingBoxLabels.error) {
        appState.setAlert(
          `Error occurred while fetching box labels for printing. Please try again.`,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      fetchOrderForPrintingBoxLabels.data,
      fetchOrderForPrintingBoxLabels.error,
    ]);

    const downloadBoxLabels = () => {
      fetchOrderForDownloadingBoxLabels.fetchData({ orderId: order.id });
    };

    const printBoxLabels = () => {
      fetchOrderForPrintingBoxLabels.fetchData({ orderId: order.id });
    };

    const print2DBarcodes = () => {
      const boxes = currentBatch.boxes;
      for (const box of boxes) {
        print2DBarcodeBoxLabel(box.name, false);
      }
    };
    const download2DBarcodes = () => {
      const boxes = currentBatch.boxes;
      for (const box of boxes) {
        print2DBarcodeBoxLabel(box.name, true);
      }
    };

    const submitBoxes = (boxName) => {
      let box = boxTypes.find((item) => item.name === boxName);
      let boxes = currentBatch.boxes;
      if (!boxes) {
        boxes = [];
      }

      if (boxes.length > 0) {
        // PRINT PREVIOUS BOX 2D BARCODE
        print2DBarcodeBoxLabel(boxes[boxes.length - 1]);
      }

      let lengthOfBox = boxes.length + 1;
      let nameOfBox = `Box-${lengthOfBox}`;
      while (
        boxes.length > 0 &&
        boxes.findIndex((i) => i.name === nameOfBox) !== -1
      ) {
        lengthOfBox += 1;
        nameOfBox = `Box-${lengthOfBox}`;
      }

      boxes.push({
        ...box,
        name: nameOfBox,
      });

      setBoxesQuery.fetchData({
        id: currentBatch.id,
        boxes,
        orderId: order.id,
        commodityContent: getCommodityContentDetails(),
      });

      setAddBox(false);
    };

    const onSubmitCustomer = (selectedCustomer) => {
      setCustomer(selectedCustomer.value);
    };

    const onSkipCustomer = () => {
      setCustomer(null);
    };

    const changeQuantity = (e) => {
      if (e >= 0) {
        setCurrentBatch({
          ...currentBatch,
          currentItem: {
            ...currentBatch.currentItem,
            quantity: e,
          },
        });
      }

      if (isNaN(e)) {
        setCurrentBatch({
          ...currentBatch,
          currentItem: {
            ...currentBatch.currentItem,
            quantity: null,
          },
        });
      }
    };

    const onChangeValue = (field, value) => {
      try {
        let orderLineItems = JSON.parse(JSON.stringify(order.orderLineItems));
        const orderLineItem = orderLineItems.findIndex(
          (item) => item.sku === currentBatch.currentItem.sku,
        );
        if (orderLineItems[orderLineItem] !== -1) {
          orderLineItems[orderLineItem][field] = value;
        }
        setOrder({
          ...order,
          orderLineItems,
        });
      } catch (err) {}
    };

    useEffect(() => {
      if (updateOrderLineItemQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (updateOrderLineItemQuery.data) {
        currentBatchQuery.fetchData();
        appState.setAlert(
          updateOrderLineItemQuery.data.updateOrderLineItem.message,
          `success`,
          ALERT_TIMEOUT_MS,
        );
      }

      if (updateOrderLineItemQuery.error) {
        appState.setAlert(
          updateOrderLineItemQuery.error.message,
          `error`,
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      updateOrderLineItemQuery.data,
      updateOrderLineItemQuery.error,
      updateOrderLineItemQuery.loading,
    ]);

    const submitOrderLineItem = () => {
      try {
        let orderLineItems = JSON.parse(JSON.stringify(order.orderLineItems));
        const orderLineItem = orderLineItems.findIndex(
          (item) => item.sku === currentBatch.currentItem.sku,
        );
        updateOrderLineItemQuery.fetchData({
          orderLineItemInput: {
            ...orderLineItems[orderLineItem],
          },
        });
      } catch (err) {}
    };

    useEffect(() => {
      if (confirmTypeOfShipmentQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (confirmTypeOfShipmentQuery.error) {
        appState.setAlert(
          confirmTypeOfShipmentQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
        currentBatchQuery.fetchData();
      }

      if (confirmTypeOfShipmentQuery.data) {
        appState.setAlert(
          confirmTypeOfShipmentQuery.data.confirmTypeOfShipment.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        currentBatchQuery.fetchData();
      }
    }, [
      confirmTypeOfShipmentQuery.data,
      confirmTypeOfShipmentQuery.loading,
      confirmTypeOfShipmentQuery.error,
    ]);

    useEffect(() => {
      if (switchTypeOfShipmentQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (switchTypeOfShipmentQuery.error) {
        appState.setAlert(
          switchTypeOfShipmentQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
        currentBatchQuery.fetchData();
      }

      if (switchTypeOfShipmentQuery.data) {
        appState.setAlert(
          switchTypeOfShipmentQuery.data.switchTypeOfShipment.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        currentBatchQuery.fetchData();
      }
    }, [
      switchTypeOfShipmentQuery.data,
      switchTypeOfShipmentQuery.loading,
      switchTypeOfShipmentQuery.error,
    ]);

    const confirmTypeOfShipment = () => {
      confirmTypeOfShipmentQuery.fetchData({ id: order.id });
    };

    const switchTypeOfShipment = (typeOfShipment) => {
      appState.showConfirmation(
        "Confirmation",
        `Are you sure you want to switch the type of shipment?`,
        () => {
          switchTypeOfShipmentQuery.fetchData({
            id: order.id,
            typeOfShipment,
          });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const printPackingLabels = () => {
      appState.setAlert(
        "Printing packing labels...",
        "success",
        ALERT_TIMEOUT_MS,
      );
      downloadPackingLabels(
        currentBatch,
        1,
        TENANT_SHIPPING_LABEL_PRINTER_ENUM[appState.subdomain] ||
          TENANT_SHIPPING_LABEL_PRINTER_ENUM.default,
        false,
        appState,
      );
    };

    useEffect(() => {
      if (confirmPalletInformationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (confirmPalletInformationQuery.data) {
        appState.setAlert(
          confirmPalletInformationQuery.data.confirmPalletInformation.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        currentBatchQuery.fetchData();
      }

      if (confirmPalletInformationQuery.error) {
        appState.setAlert(
          confirmPalletInformationQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      confirmPalletInformationQuery.loading,
      confirmPalletInformationQuery.error,
      confirmPalletInformationQuery.data,
    ]);

    const submitPalletInformation = (pallets) => {
      if (!pallets || pallets.length === 0) {
        return appState.setAlert(
          `Please provide pallet information`,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }

      const invalidLengthPalletIndex = pallets.findIndex(
        (pallet) =>
          !pallet.length ||
          isNaN(pallet.length) ||
          parseInt(pallet.length) <= 0,
      );
      if (invalidLengthPalletIndex !== -1) {
        return appState.setAlert(
          `Please provide a valid length for pallet ${
            invalidLengthPalletIndex + 1
          }`,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }

      const invalidWidthPalletIndex = pallets.findIndex(
        (pallet) =>
          !pallet.width || isNaN(pallet.width) || parseInt(pallet.width) <= 0,
      );
      if (invalidWidthPalletIndex !== -1) {
        return appState.setAlert(
          `Please provide a valid width for pallet ${
            invalidLengthPalletIndex + 1
          }`,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }

      const invalidHeightPalletIndex = pallets.findIndex(
        (pallet) =>
          !pallet.height ||
          isNaN(pallet.height) ||
          parseInt(pallet.height) <= 0,
      );
      if (invalidHeightPalletIndex !== -1) {
        return appState.setAlert(
          `Please provide a valid height for pallet ${
            invalidHeightPalletIndex + 1
          }`,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }

      const invalidWeightPalletIndex = pallets.findIndex(
        (pallet) =>
          !pallet.weight ||
          isNaN(pallet.weight) ||
          parseInt(pallet.weight) <= 0,
      );

      if (invalidWeightPalletIndex !== -1) {
        return appState.setAlert(
          `Please provide a valid weight for pallet ${
            invalidWeightPalletIndex + 1
          }`,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }

      if (order.source === "FBA") {
        if (_.sumBy(pallets, "weight") < MINIMUM_FREIGHT_WEIGHT_AMAZON) {
          return appState.setAlert(
            `Minimum freight weight for LTL shipments in Amazon is ${MINIMUM_FREIGHT_WEIGHT_AMAZON} pounds. Please add more weight to the pallets.`,
            "error",
            ALERT_TIMEOUT_MS,
          );
        }
      }

      confirmPalletInformationQuery.fetchData({
        id: currentBatchQuery.data.getCurrentPackerBatch.id,
        pallets,
      });
    };

    useEffect(() => {
      if (markHazmatConfirmedQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (markHazmatConfirmedQuery.data) {
        appState.setAlert(
          markHazmatConfirmedQuery.data.markHazmatConfirmed.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        currentBatchQuery.fetchData();
      }

      if (markHazmatConfirmedQuery.error) {
        appState.setAlert(
          markHazmatConfirmedQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      markHazmatConfirmedQuery.data,
      markHazmatConfirmedQuery.loading,
      markHazmatConfirmedQuery.error,
    ]);

    const markHazmatConfirmed = (hazmatConfirmed) => {
      markHazmatConfirmedQuery.fetchData({
        id: order.id,
        hazmatConfirmed,
      });
    };

    useEffect(() => {
      if (submitTrackingInformationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (submitTrackingInformationQuery.data) {
        appState.setAlert(
          submitTrackingInformationQuery.data.submitTrackingInformation.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        currentBatchQuery.fetchData();
      }

      if (submitTrackingInformationQuery.error) {
        appState.setAlert(
          submitTrackingInformationQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      submitTrackingInformationQuery.loading,
      submitTrackingInformationQuery.error,
      submitTrackingInformationQuery.data,
    ]);

    const submitTrackingInformation = (trackingInformation) => {
      if (!trackingInformation) {
        return appState.setAlert(
          `Please provide tracking information`,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
      if (!trackingInformation.carrier) {
        return appState.setAlert(
          `Please provide the carrier`,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
      if (!trackingInformation.trackingNumber) {
        return appState.setAlert(
          `Please provide a tracking number`,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }

      submitTrackingInformationQuery.fetchData({
        id: currentBatch.id,
        trackingInformation,
      });
    };

    const checkPagination = (pageNum, pageSize) => {
      let vars = {
        filters: ordersData.filters,
        sort: "orderDate",
        perPage: ordersData.perPage || pageSize,
        pageNumber: pageNum,
      };
      setOrdersData({
        ...ordersData,
        pageNumber: vars["pageNumber"],
      });
      return ordersQuery.fetchData(vars);
    };

    const markOrderException = () => {
      setOrderNote("");
    };

    const submitNotes = () => {
      markOrderExceptionQuery.fetchData({
        id: currentBatchQuery.data.getCurrentPackerBatch.id,
        notes: orderNote,
      });

      setOrderNote(null);
    };

    const restartActivity = (
      cancelInboundPlan = () => {},
      callWithoutConfirmation = false,
    ) => {
      if (callWithoutConfirmation) {
        restartActivityInAsync.fetchData({
          id: currentBatch.id,
        });
        return;
      }
      appState.showConfirmation(
        "Confirmation",
        `Are you sure you want to restart this activity? All changes will be lost.`,
        async () => {
          const restartActivityResponse = await restartActivityQuery.fetchData({
            id: currentBatch.id,
          });
          if (restartActivityResponse.data?.restartPack?.message) {
            setTimeout(() => {
              cancelInboundPlan();
            }, 1000);
          }
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const pauseActivity = (pauseRightAway = "") => {
      if (pauseRightAway === "PAUSE_RIGHT_AWAY") {
        pauseActivityQuery.fetchData({ id: currentBatch.id });
        return;
      }
      appState.showConfirmation(
        "Confirmation",
        `Are you sure you want to pause this activity?`,
        () => {
          pauseActivityQuery.fetchData({ id: currentBatch.id });
          appState.hideConfirmation();
        },
        () => {
          appState.hideConfirmation();
        },
      );
    };

    useEffect(() => {
      if (restartActivityQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (restartActivityQuery.data) {
        appState.setAlert(
          restartActivityQuery.data.restartPack.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        appState.hideConfirmation();
        currentBatchQuery.fetchData();
      }

      if (restartActivityQuery.error) {
        appState.setAlert(
          restartActivityQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
        appState.hideConfirmation();
        currentBatchQuery.fetchData();
      }
    }, [
      restartActivityQuery.loading,
      restartActivityQuery.data,
      restartActivityQuery.error,
    ]);

    useEffect(() => {
      if (pauseActivityQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (pauseActivityQuery.data) {
        appState.setAlert(
          pauseActivityQuery.data.pausePack.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        appState.hideConfirmation();
        currentBatchQuery.fetchData();
      }

      if (pauseActivityQuery.error) {
        appState.setAlert(
          pauseActivityQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
        appState.hideConfirmation();
        currentBatchQuery.fetchData();
      }
    }, [
      pauseActivityQuery.loading,
      pauseActivityQuery.data,
      pauseActivityQuery.error,
    ]);

    useEffect(() => {
      if (unpackItemQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (unpackItemQuery.data) {
        appState.setAlert(
          unpackItemQuery.data.unpackItem.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        appState.hideConfirmation();
        currentBatchQuery.fetchData();
      }

      if (unpackItemQuery.error) {
        appState.setAlert(
          unpackItemQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
        appState.hideConfirmation();
        currentBatchQuery.fetchData();
      }
    }, [unpackItemQuery.loading, unpackItemQuery.data, unpackItemQuery.error]);

    useEffect(() => {
      if (unpackBoxQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (unpackBoxQuery.data) {
        appState.setAlert(
          unpackBoxQuery.data.unpackBox.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        currentBatchQuery.fetchData();
        setItemsToUnPack({});
        appState.hideConfirmation();
      }

      if (unpackBoxQuery.error) {
        appState.setAlert(
          unpackBoxQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
        appState.hideConfirmation();
        currentBatchQuery.fetchData();
      }
    }, [unpackBoxQuery.loading, unpackBoxQuery.data, unpackBoxQuery.error]);

    const removeBox = (boxName) => {
      appState.showConfirmation(
        "Confirmation",
        `Are you sure you want to remove this box? All items in the box would be unpacked`,
        () => {
          unpackBoxQuery.fetchData({ id: currentBatch.id, boxName });
        },
        appState.hideConfirmation,
      );
    };
    /**
     * Finds items matching the provided SKU and prepares to unpack them if the selected quantity is available.
     * If the selected quantity is more than available, sets an alert.
     * @param {DataArray[]} items
     * @param {string} sku - The SKU identifier of the items to find and unpack.
     * @param {number} selectedQuantity - The quantity of the SKU items to unpack.
     * @param {string} boxName - Name of the box
     *
     * @example
     *  handleFindAndUnpackItem([
     *    { id: "1", sku: "123" },
     *    { id: "2", sku: "123" },
     *    { id: "3", sku: "456" },
     *  ], "123", 2, "Box-1");
     *  // The setItemsToUnPack() function is called with the result: [{ id: "1" }, { id: "2" }]
     */
    function handleFindAndUnpackItem(items, sku, selectedQuantity, boxName) {
      if (!selectedQuantity) {
        setItemsToUnPack((prevItems) => {
          delete prevItems[`${sku}-${boxName}`];
          return { ...prevItems };
        });
      } else {
        const itemsForSku = items.filter(
          (item) => item.sku === sku && item.boxName === boxName,
        );

        if (selectedQuantity <= itemsForSku.length) {
          const result = itemsForSku
            .slice(0, selectedQuantity)
            .map((item) => ({ id: item.id }));
          setItemsToUnPack((prevItems) => {
            prevItems[`${sku}-${boxName}`] = result;
            return { ...prevItems };
          });
        } else {
          appState.setAlert(
            "Please enter quantity less than or equal to available quantity",
            "error",
            ALERT_TIMEOUT_MS,
          );
        }
      }
    }

    const unpackItem = () => {
      const itemsToUnPackModified = [].concat.apply(
        [],
        Object.values(itemsToUnPack),
      );
      if (!itemsToUnPackModified || itemsToUnPackModified.length === 0) {
        appState.setAlert(
          `Please select right quantity`,
          "error",
          ALERT_TIMEOUT_MS,
        );
        return;
      }
      appState.showConfirmation(
        "Confirmation",
        `Are you sure you want to re-do these item(s)? This will mark the item(s) as unprocessed`,
        () => {
          unpackItemQuery.fetchData({
            id: currentBatch.id,
            unPackItems: itemsToUnPackModified,
          });
        },
        appState.hideConfirmation,
      );
    };

    useEffect(() => {
      if (packItemsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (packItemsQuery.data) {
        appState.setAlert(
          packItemsQuery.data.packItems.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        currentBatchQuery.fetchData();
        setItemsToPack({});
        appState.hideConfirmation();
      }

      if (packItemsQuery.error) {
        appState.setAlert(
          packItemsQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
        currentBatchQuery.fetchData();
      }
    }, [packItemsQuery.loading, packItemsQuery.error, packItemsQuery.data]);
    /**
     * @typedef DataArray
     * @property {string} id
     * @property {string} sku
     */
    /**
     * Finds items matching the provided SKU and prepares to pack them if the selected quantity is available.
     * If the selected quantity is more than available, sets an alert.
     * @param {DataArray[]} items
     * @param {string} sku - The SKU identifier of the items to find and pack.
     * @param {number} selectedQuantity - The quantity of the SKU items to pack.
     *
     * @example
     *  handleFindAndPack([
     *    { id: "1", sku: "123" },
     *    { id: "2", sku: "123" },
     *    { id: "3", sku: "456" },
     *  ], "123", 2);
     *  // The setItemsToPack() function is called with the result: [{ id: "1" }, { id: "2" }]
     */
    function handleFindAndPack(items, sku, selectedQuantity) {
      const itemsForSku = items.filter((item) => item.sku === sku);

      if (selectedQuantity <= itemsForSku.length) {
        const result = itemsForSku
          .slice(0, selectedQuantity)
          .map((item) => ({ id: item.id }));

        setItemsToPack((prevItems) => {
          prevItems[sku] = result;
          return prevItems;
        });
      } else {
        appState.setAlert(
          "Please enter quantity less than or equal to available quantity",
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }

    const packItems = (boxName) => {
      const itemsToPackModified = [].concat.apply(
        [],
        Object.values(itemsToPack),
      );
      if (!itemsToPackModified || itemsToPackModified.length === 0) {
        appState.setAlert(
          `Please select right quantity`,
          "error",
          ALERT_TIMEOUT_MS,
        );
        return;
      }
      if (!boxName || boxName.trim() === "") {
        appState.setAlert(`Please select a box`, "error", ALERT_TIMEOUT_MS);
        return;
      }
      appState.showConfirmation(
        "Confirmation",
        `Are you sure you want to pack ${itemsToPackModified.length} items into ${boxName}`,
        () => {
          packItemsQuery.fetchData({
            id: currentBatch.id,
            items: itemsToPackModified,
            boxName,
          });
        },
        appState.hideConfirmation,
      );
    };

    const getCommodityContentDetails = () => {
      const commodityContent = [];
      const productMap = new Map();
      boxDetails?.forEach((box) => {
        box?.items?.forEach((item) => {
          if (!productMap?.has(item.productId)) {
            productMap?.set(item.productId, item.commodityCode);
          }
        });
      });
      productMap.forEach((commodityCode, productId) => {
        commodityContent.push({ productId, commodityCode });
      });
      return commodityContent;
    };

    const confirmBoxes = () => {
      if (order.isCasePack) {
        const cases = _.flatten(Object.values(groupedCasesBySku));
        const findIndex = cases.findIndex((item) => {
          // WEIGHT, LENGTH, WIDTH AND HEIGHT NEED TO BE PRESENT AND POSITIVE VALUES
          return (
            !item.weight ||
            isNaN(parseFloat(item.weight)) ||
            parseFloat(item.weight) <= 0 ||
            !item.length ||
            isNaN(parseFloat(item.length)) ||
            parseFloat(item.length) <= 0 ||
            !item.width ||
            isNaN(parseFloat(item.width)) ||
            parseFloat(item.width) <= 0 ||
            !item.height ||
            isNaN(parseFloat(item.height)) ||
            parseFloat(item.height) <= 0
          );
        });
        if (findIndex !== -1) {
          return appState.setAlert(
            `Please check the details for SKU: ${cases[findIndex].sku}.`,
            "error",
            ALERT_TIMEOUT_MS,
          );
        }
        const boxes = _.flatten(Object.values(groupedCasesBySku)).map(
          (item) => ({
            name: item.name,
            weight: parseFloat(item.weight),
            length: parseFloat(item.length),
            width: parseFloat(item.width),
            height: parseFloat(item.height),
            sku: item.sku,
          }),
        );
        setBoxesQuery.fetchData({
          id: currentBatch.id,
          boxes,
          confirmedBoxContents: true,
          orderId: order.id,
          commodityContent: getCommodityContentDetails(),
        });
      } else {
        const boxes = currentBatch.boxes;

        if (!boxes || boxes.length === 0) {
          appState.setAlert(
            `Please provide box information`,
            "error",
            ALERT_TIMEOUT_MS,
          );
          return false;
        }

        const nonWeightedBox = boxes.findIndex(
          (i) =>
            i.weight === null ||
            isNaN(parseFloat(i.weight)) ||
            parseFloat(i.weight) <= 0,
        );
        if (nonWeightedBox !== -1) {
          appState.setAlert(
            `Check the weight of ${boxes[nonWeightedBox].name}.`,
            "error",
            ALERT_TIMEOUT_MS,
          );
          return false;
        }

        setBoxesQuery.fetchData({
          id: currentBatch.id,
          boxes,
          confirmedBoxContents: true,
          orderId: order.id,
          commodityContent: getCommodityContentDetails(),
        });
        return true;
      }
    };

    const setWeightOfBox = (index, boxWeight) => {
      if (
        !currentBatch ||
        !currentBatch.boxes ||
        currentBatch.boxes.length === 0
      ) {
        console.log(`No current batch/boxes for incoming weight`);
        return;
      }
      const boxes = JSON.parse(JSON.stringify(currentBatch.boxes));

      let box = boxes[index];

      if (!box) {
        appState.setAlert(`Invalid Box`, "error", ALERT_TIMEOUT_MS);
        return;
      }

      if (
        !boxWeight ||
        isNaN(parseFloat(boxWeight)) ||
        parseFloat(boxWeight) <= 0
      ) {
        box.weight = null;
      } else {
        box.weight = parseFloat(boxWeight);
      }

      setCurrentBatch({
        ...currentBatch,
        boxes,
      });
    };

    useEffect(() => {
      if (duplicateBoxQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (duplicateBoxQuery.data) {
        appState.setAlert(
          duplicateBoxQuery.data.duplicateBox.message,
          "success",
          ALERT_TIMEOUT_MS,
        );
        appState.hideConfirmation();
        currentBatchQuery.fetchData();
        setNumberOfBoxesToDuplicate(null);
      }

      if (duplicateBoxQuery.error) {
        appState.setAlert(
          duplicateBoxQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
        currentBatchQuery.fetchData();
      }
    }, [
      duplicateBoxQuery.data,
      duplicateBoxQuery.loading,
      duplicateBoxQuery.error,
    ]);

    useEffect(() => {
      if (setBoxesBeforeDuplicationQuery.data) {
        duplicateBoxQuery.fetchData(
          setBoxesBeforeDuplicationQuery.variables.boxDataToDuplicate,
        );
      }
      if (setBoxesBeforeDuplicationQuery.error) {
        currentBatchQuery.fetchData();
        appState.setAlert(
          setBoxesBeforeDuplicationQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }
    }, [
      setBoxesBeforeDuplicationQuery.data,
      setBoxesBeforeDuplicationQuery.error,
    ]);

    const duplicateBox = (boxName, countOfBoxes = 1, order = null) => {
      appState.setLoading();
      let boxes = currentBatch.boxes;
      if (!boxes || !boxes.length) {
        appState.removeLoading();
        return appState.setAlert(`No boxes found`, "error", ALERT_TIMEOUT_MS);
      }

      if (boxes.findIndex((item) => item.name === boxName) === -1) {
        appState.removeLoading();
        return appState.setAlert(`Box not found`, "error", ALERT_TIMEOUT_MS);
      }

      if (!countOfBoxes || isNaN(countOfBoxes) || countOfBoxes <= 0) {
        appState.removeLoading();
        return appState.setAlert(
          `Please provide a valid number of boxes to duplicate`,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }

      const duplicateBoxes = [];
      let lengthOfBox = boxes.length + 1;
      for (let i = 0; i < countOfBoxes; i++) {
        let nameOfNewBox = `Box-${lengthOfBox}`;
        while (
          boxes.length > 0 &&
          boxes.findIndex((i) => i.name === nameOfNewBox) !== -1
        ) {
          lengthOfBox += 1;
          nameOfNewBox = `Box-${lengthOfBox}`;
        }
        duplicateBoxes.push(nameOfNewBox);
        lengthOfBox += 1;

        let filteredBoxes = currentBatch?.workingList?.filter(
          (i) => i?.boxName === boxName,
        );

        if (
          appState?.tenant?.features?.combinedPackAndPrep === true &&
          filteredBoxes?.length &&
          ["FBA"].includes(order?.source) &&
          !appState.tenant?.settings?.activities?.packing
            ?.disableAutomaticPrinting
        ) {
          appState.setAlert(`Printing FN SKU`, "success", 3000);

          filteredBoxes.forEach((boxItem) => {
            handleFnSkuPrinting(
              appState,
              boxItem?.fnSku,
              boxItem?.quantity,
              boxItem?.name || boxItem?.productName,
              boxItem?.bestByDate,
            );
          });
        }
      }

      setBoxesBeforeDuplicationQuery.fetchData({
        id: currentBatch.id,
        boxes,
        orderId: order.id,
        boxDataToDuplicate: {
          id: currentBatch.id,
          sourceBoxName: boxName,
          destinationBoxes: duplicateBoxes,
        },
        commodityContent: getCommodityContentDetails(),
      });
    };

    const submitUsername = () => {
      if (!username || username.trim() === "") {
        return appState.setAlert(
          `Please provide a username`,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }

      setShowAssociateUsernameInput(false);
    };

    const print2DBarcode = (boxName, download = false) => {
      print2DBarcodeBoxLabel(boxName, download);
    };

    useEffect(() => {
      if (auth?.user?.station) {
        currentBatchQuery.fetchData();
      }
    }, []);

    const navigateToBoxContentsConfirmation = () => {
      const carrierSelectionData = {
        orderInfo: {
          id: order.id,
          bypassCarrierSelection: false,
        },
      };
      updateCarrierInfoQuery.fetchData(carrierSelectionData);
      setBoxesQuery.fetchData({
        id: currentBatch.id,
        boxes: currentBatch.boxes,
        confirmedBoxContents: false,
        orderId: order.id,
        commodityContent: getCommodityContentDetails(),
      });
    };

    useEffect(() => {
      if (updateCarrierInfoQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (updateCarrierInfoQuery.error) {
        appState.setAlert(
          updateCarrierInfoQuery.error.message,
          "error",
          ALERT_TIMEOUT_MS,
        );
      }

      if (updateCarrierInfoQuery.data) {
        currentBatchQuery.fetchData();
      }
    }, [
      updateCarrierInfoQuery.loading,
      updateCarrierInfoQuery.error,
      updateCarrierInfoQuery.data,
    ]);

    /**
     * Checks the weight limits for an FBA box
     * @param {object} box - The box to check
     * @param {array} workingList - The working list of items
     * @returns {object} - An object containing isValid and message properties
     */
    const checkFbaBoxWeightLimits = (box, workingList) => {
      if (!box || !workingList) {
        return { isValid: true };
      }
      const boxWeight = parseFloat(box.weight);
      const itemsInBox = workingList.filter(
        (item) => item.boxName === box.name,
      );

      if (boxWeight > FBA_BOX_WEIGHT_LIMIT) {
        if (itemsInBox.length > 1) {
          // Multiple items in box exceeding 50 lbs - not allowed
          return {
            isValid: false,
            message: "Boxes with multiple items cannot exceed 50 pounds.",
          };
        } else {
          // Single item exceeding weight limit - show appropriate warning
          if (boxWeight > FBA_BOX_MECHANICAL_LIFT_WARNING_WEIGHT_LIMIT) {
            return {
              isValid: true,
              warning:
                "Mechanical Lift labels required on top and sides of box",
            };
          } else {
            return {
              isValid: true,
              warning: "Team Lift labels required on top and sides of box",
            };
          }
        }
      }

      return { isValid: true };
    };

    return (
      <WrappedComponent
        currentBatch={currentBatch}
        getCurrentBatch={() => currentBatchQuery.fetchData()}
        scanBarcode={(code) => {
          if (appState.subdomain === "kingly") {
            let currItem = currentBatch?.currentItem;
            if (currItem) {
              // SCAN SERIAL NUMBER
              try {
                let orderLineItems = JSON.parse(
                  JSON.stringify(order.orderLineItems),
                );
                const orderLineItem = orderLineItems.findIndex(
                  (item) => item.sku === currentBatch.currentItem.sku,
                );
                return updateOrderLineItemQuery.fetchData({
                  orderLineItemInput: {
                    ...orderLineItems[orderLineItem],
                    serialNumber: code,
                  },
                });
              } catch (err) {}
            }
          }
          const payload = {
            code,
          };
          if (
            username &&
            ldClient?.variation(
              featureFlags?.ASSOCIATE_USERNAME_INPUT_ENABLED,
              false,
            )
          ) {
            payload.username = username;
          }
          return scanBarcodeQuery.fetchData(payload);
        }}
        currentItemMismatch={currentItemMismatch}
        setCurrentItemMismatch={(status) => {
          setCurrentItemMismatch(status);
          if (status === "CONFIRMED") {
            if (
              (isDelmarTenant(appState.subdomain) ||
                isWaseTenant(appState.subdomain)) &&
              _.uniqBy(
                currentBatch.workingList.filter(
                  (i) =>
                    i.order === order.id &&
                    ["CONFIRMED", "MISMATCH"].includes(i.status) === false,
                ),
                "sku",
              ).length === 1 &&
              _.sumBy(
                currentBatch.workingList.filter(
                  (i) => i.order === order.id && i.status !== "CONFIRMED",
                ),
                "quantity",
              ) <= currentBatch.currentItem.quantity
            ) {
              generatePrintListAndTriggerPrint(order);
            }
            // CHECK IF PREP ENABLED AND TRIGGER FNSKU PRINT
            if (
              appState?.tenant?.features?.combinedPackAndPrep === true &&
              order.source === "FBA" &&
              currentBatch?.currentItem?.fnSku
            ) {
              handleFnSkuPrinting(
                appState,
                currentBatch.currentItem.fnSku,
                currentBatch.currentItem.quantity,
                currentBatch.currentItem.name ||
                  currentBatch.currentItem.productName,
                currentBatch.currentItem.bestByDate,
              );
            }
          }
        }}
        confirmPackItem={() => {
          confirmPackItemQuery.fetchData({
            itemId:
              currentBatchQuery.data.getCurrentPackerBatch.currentItem
                .uniqueIdentifier,
            id: currentBatchQuery.data.getCurrentPackerBatch.id,
            status: currentItemMismatch || "CONFIRMED",
            boxName:
              currentBatch.boxes &&
              currentBatch.boxes[currentBatch.boxes.length - 1]
                ? currentBatch.boxes[currentBatch.boxes.length - 1].name
                : "DEFAULT",
            boxWeight:
              (currentBatch.boxes &&
                currentBatch.boxes[currentBatch.boxes.length - 1]?.weight) ||
              1,
            quantity: currentBatch.currentItem.quantity,
          });
        }}
        confirmDropoff={() =>
          confirmDropoffQuery.fetchData({
            id: currentBatchQuery.data?.getCurrentPackerBatch?.id,
          })
        }
        loading={currentBatchQuery.loading}
        currentUser={auth.user}
        logout={() => logoutQuery.fetchData()}
        onSelectStation={(e) => setSelectedStation(e)}
        onSubmitStation={() => {
          if (selectedStation) {
            setStationQuery.fetchData({ station: selectedStation });
          } else {
            appState.setAlert(
              "Please select a station",
              "error",
              ALERT_TIMEOUT_MS,
            );
          }
        }}
        selectedStation={selectedStation}
        stations={stations}
        simulateTote={simulateTote}
        simulateItemScan={simulateItemScan}
        weight={currentBatch?.boxes?.length ? currentBatch.boxes[0].weight : 1}
        boxTypes={boxTypes}
        submitBoxes={submitBoxes}
        printShippingLabel={(forcePrint = false) => {
          if (
            !appState.tenant?.settings?.activities?.packing
              ?.disableAutomaticPrinting ||
            forcePrint
          ) {
            appState.setAlert(
              "Printing labels...",
              "success",
              ALERT_TIMEOUT_MS,
            );
            if (fetchSpecificOrder.data.order.typeOfShipment === "LTL") {
              printShippingLabel(
                fetchSpecificOrder.data.order.palletLabels,
                appState,
                TENANT_SHIPPING_LABEL_PRINTER_ENUM[appState.subdomain] ||
                  TENANT_SHIPPING_LABEL_PRINTER_ENUM.default,
              );
              labelEventQuery.fetchData({
                warehouse: fetchSpecificOrder.data.order.warehouse,
                customer: fetchSpecificOrder.data.order.customer,
                order: fetchSpecificOrder.data.order.id,
                quantity: 1,
                labelType: "SHIPPING_LABEL",
                dropship: false,
              });
            } else if (
              fetchSpecificOrder.data.order.shippingLabels?.length > 0
            ) {
              appState.setAlert(
                "Printing labels...",
                "success",
                ALERT_TIMEOUT_MS,
              );
              for (const shippingLabel of fetchSpecificOrder.data.order
                .shippingLabels) {
                printShippingLabel(
                  shippingLabel,
                  appState,
                  TENANT_SHIPPING_LABEL_PRINTER_ENUM[appState.subdomain] ||
                    TENANT_SHIPPING_LABEL_PRINTER_ENUM.default,
                );
                labelEventQuery.fetchData({
                  warehouse: fetchSpecificOrder.data.order.warehouse,
                  customer: fetchSpecificOrder.data.order.customer,
                  order: fetchSpecificOrder.data.order.id,
                  quantity: 1,
                  labelType: "SHIPPING_LABEL",
                  dropship: false,
                });
              }
            } else {
              printShippingLabel(
                fetchSpecificOrder.data.order.shippingLabel,
                appState,
                TENANT_SHIPPING_LABEL_PRINTER_ENUM[appState.subdomain] ||
                  TENANT_SHIPPING_LABEL_PRINTER_ENUM.default,
              );
              labelEventQuery.fetchData({
                warehouse: fetchSpecificOrder.data.order.warehouse,
                customer: fetchSpecificOrder.data.order.customer,
                order: fetchSpecificOrder.data.order.id,
                quantity: 1,
                labelType: "SHIPPING_LABEL",
                dropship: false,
              });
            }
          }
        }}
        reprintShippingLabel={(orderId) => {
          fetchSpecificOrderForPrinting.fetchData({ orderId });
        }}
        addBox={addBox}
        setAddBox={setAddBox}
        workflow={workflow}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        customer={customer}
        setCustomer={setCustomer}
        onSubmitCustomer={onSubmitCustomer}
        onSkipCustomer={onSkipCustomer}
        confirmDropoffWithConfirmation={() => console.log("")}
        order={order}
        setWeight={setWeight}
        setWeightOfBox={setWeightOfBox}
        changeQuantity={changeQuantity}
        subdomain={appState.subdomain}
        setBoxTypes={setBoxTypes}
        setBoxDetails={setBoxDetails}
        setTransportMode={setTransportMode}
        transportMode={transportMode}
        boxDetails={boxDetails}
        onChangeValue={onChangeValue}
        submitOrderLineItem={submitOrderLineItem}
        cancelItem={() => cancelItemQuery.fetchData({ id: currentBatch.id })}
        restartActivity={restartActivity}
        confirmTransportInformation={async () => {
          const confirmTransportInformationResponse =
            await confirmTransportInformationQuery.fetchData({
              id: currentBatch.id,
            });
          return confirmTransportInformationResponse;
        }}
        getFbaLabelGenerationStatus={() =>
          order?.orderId &&
          order?.source === "FBA" &&
          getFbaLabelGenerationStatusQuery.fetchData({
            shipmentId: order.orderId,
          })
        }
        transportLoading={confirmTransportInformationQuery.loading}
        confirmTypeOfShipment={confirmTypeOfShipment}
        switchTypeOfShipment={switchTypeOfShipment}
        printBoxLabels={printBoxLabels}
        submitPalletInformation={submitPalletInformation}
        markHazmatConfirmed={markHazmatConfirmed}
        masterData={masterData}
        submitTrackingInformation={submitTrackingInformation}
        orders={ordersData.orders}
        total={ordersData.total}
        pageNumber={ordersData.pageNumber}
        checkPagination={checkPagination}
        perPage={ordersData.perPage}
        filters={ordersData.filters}
        ordersData={ordersData}
        setFilters={(
          filters,
          perPage = 25,
          pageNumber = 1,
          autoSubmit = false,
        ) => {
          console.log(filters, perPage, pageNumber, "packerFilters");
          setOrdersData({
            ...ordersData,
            filters,
            perPage,
            pageNumber,
          });
          if (autoSubmit) {
            ordersQuery.fetchData({
              perPage,
              pageNumber,
              filters,
              sort: "orderDate",
            });
          }
        }}
        submitFilters={() =>
          ordersQuery.fetchData({
            perPage: 25,
            pageNumber: 1,
            filters: ordersData.filters,
            sort: "orderDate",
          })
        }
        checkOrderById={(data) => {
          scanBarcodeQuery.fetchData({ code: data });
        }}
        markOrderException={markOrderException}
        orderNote={orderNote}
        setOrderNote={setOrderNote}
        submitNotes={submitNotes}
        pauseActivity={pauseActivity}
        removeBox={removeBox}
        unpackItem={unpackItem}
        packItems={packItems}
        itemsToPack={itemsToPack}
        setItemsToPack={setItemsToPack}
        confirmBoxes={confirmBoxes}
        duplicateBox={duplicateBox}
        handleFindAndPack={handleFindAndPack}
        isAddItemQuantityExceed={isAddItemQuantityExceed}
        isSetAddItemQuantityExceed={isSetAddItemQuantityExceed}
        itemsToUnPack={itemsToUnPack}
        setItemsToUnPack={setItemsToUnPack}
        handleFindAndUnpackItem={handleFindAndUnpackItem}
        fetchSpecificOrder={fetchSpecificOrder}
        currentProduct={currentProduct}
        groupedCasesBySku={groupedCasesBySku}
        setGroupedCasesBySku={setGroupedCasesBySku}
        tenant={appState.tenant}
        printPackingLabels={printPackingLabels}
        packingLabelsEnabled={ldClient?.variation(
          featureFlags?.FBA_SKU_LABEL_GENERATION,
          false,
        )}
        associateUsernameInputEnabled={ldClient?.variation(
          featureFlags?.ASSOCIATE_USERNAME_INPUT_ENABLED,
          false,
        )}
        username={username}
        setUsername={setUsername}
        showAssociateUsernameInput={showAssociateUsernameInput}
        setShowAssociateUsernameInput={setShowAssociateUsernameInput}
        submitUsername={submitUsername}
        print2DBarcode={
          ldClient?.variation(featureFlags?.FBA_2D_BARCODE_ENABLED, false) &&
          print2DBarcode
        }
        downloadBoxLabels={downloadBoxLabels}
        print2DBarcodes={
          ldClient?.variation(featureFlags?.FBA_2D_BARCODE_ENABLED, false) &&
          print2DBarcodes
        }
        download2DBarcodes={
          ldClient?.variation(featureFlags?.FBA_2D_BARCODE_ENABLED, false) &&
          download2DBarcodes
        }
        numberOfBoxesToDuplicate={numberOfBoxesToDuplicate}
        setNumberOfBoxesToDuplicate={setNumberOfBoxesToDuplicate}
        prepEnabled={appState?.tenant?.features?.combinedPackAndPrep === true}
        fbaLabelGenerationStatus={fbaLabelGenerationStatus}
        setFbaLabelGenerationStatus={setFbaLabelGenerationStatus}
        navigateToBoxContentsConfirmation={navigateToBoxContentsConfirmation}
        confirmPrepItem={() => {
          if (
            appState?.tenant?.features?.combinedPackAndPrep === true &&
            order?.source === "FBA" &&
            currentBatch?.currentItem
          ) {
            handleFnSkuPrinting(
              appState,
              currentProduct.fnSku,
              currentBatch.currentItem.quantity,
              currentProduct?.name || currentProduct?.productName,
              currentBatch.currentItem.bestByDate,
            );
          }
        }}
        checkFbaBoxWeightLimits={checkFbaBoxWeightLimits}
      />
    );
  };
};

export default withPackerLogic;

// DELMAR SPECIFIC LOGIC

const DELMAR_EXTRA_MATERIAL_FILE_PAPER_FORMAT = 5; // DELMAR USES A SPECIAL PRINTER CODE FOR EXTRA MATERIALS

export const getPrintFiles = (order, appState = null) => {
  const printingList = [];

  if (order?.shippingLabel && order?.source !== "FBA") {
    printingList.push({
      file: order?.shippingLabel,
      printerName:
        TENANT_SHIPPING_LABEL_PRINTER_ENUM[appState?.subdomain] ||
        TENANT_SHIPPING_LABEL_PRINTER_ENUM.default,
    });
  }

  if (
    order?.customAttributes?.paperFormat === 0 ||
    order?.customAttributes?.paperFormat === null ||
    order?.customAttributes?.paperFormat === 8
  ) {
    if (order?.customAttributes?.printPslip === 1) {
      if (order?.customAttributes?.labelFile) {
        printingList.push({
          file: order?.customAttributes?.labelFile,
          printerName:
            PAPER_FORMAT_ENUM[order?.customAttributes?.paperFormat] ||
            PAPER_FORMAT_ENUM[null],
          duplex:
            PAPER_FORMAT_ENUM[order?.customAttributes?.paperFormat] ===
            "Laser-Default2",
        });
      }
    } else {
      if (order?.customAttributes?.zplFile) {
        printingList.push({
          file: order?.customAttributes?.zplFile,
          printerName: "zebra",
        });
      }
    }
  } else {
    if (order?.customAttributes?.zplFile) {
      printingList.push({
        file: order?.customAttributes?.zplFile,
        printerName: "zebra",
      });
    }
    if (order?.customAttributes?.pdfFile) {
      printingList.push({
        file: order?.customAttributes?.pdfFile,
        printerName:
          PAPER_FORMAT_ENUM[order?.customAttributes?.paperFormat] ||
          PAPER_FORMAT_ENUM[null],
        duplex:
          PAPER_FORMAT_ENUM[order?.customAttributes?.paperFormat] ===
          "Laser-Default2",
      });
    }
  }

  if (
    order?.customAttributes?.printExtra === true &&
    order?.customAttributes?.ship2store === true
  ) {
    if (order?.customAttributes?.extraMaterialFile) {
      printingList.push({
        file: order?.customAttributes?.extraMaterialFile,
        printerName:
          PAPER_FORMAT_ENUM[order?.customAttributes?.paperFormat] ||
          PAPER_FORMAT_ENUM[null],
        duplex:
          PAPER_FORMAT_ENUM[order?.customAttributes?.paperFormat] ===
          "Laser-Default2",
      });
    }
    if (order?.customAttributes?.extraMaterialFile2) {
      printingList.push({
        file: order?.customAttributes?.extraMaterialFile2,
        printerName:
          PAPER_FORMAT_ENUM[DELMAR_EXTRA_MATERIAL_FILE_PAPER_FORMAT] ||
          PAPER_FORMAT_ENUM[null],
        duplex:
          PAPER_FORMAT_ENUM[DELMAR_EXTRA_MATERIAL_FILE_PAPER_FORMAT] ===
          "Laser-Default2",
      });
    }
  }
  return printingList;
};
