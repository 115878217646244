import withSkuBinMappingsLogic from "#components/HOC/withSkuBinMappingsLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import { UserAddIcon } from "@heroicons/react/outline";
import SkuBinMappingsList from "#components/skuBinMappings/SkuBinMappingsList";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import Modal from "#components/utils/Modal";
import ModalButton from "#components/utils/ModalButton";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import Pagination from "#components/common/Pagination";
import ExpandedSku from "#components/inventory/ExpandedSku";
import Toggle from "#components/utils/Toggle";
import SkuBinMappingFilters from "#components/skuBinMappings/SkuBinMappingFilters";
import EditReceivedSkuForm from "../components/receivedSkus/EditReceivedSkuForm";
import moment from "moment-timezone";
import UploadBulk from "../components/bulkUpload/UploadBulk";
import { getFilteredCustomerList } from "../utils/getFilteredCustomerList";
import UomBreakdownForm from "#components/receivedSkus/UomBreakdownForm";
import BinTransferEligibilityModal from "#components/skuBinMappings/BinTransferEligibilityModal";
import Autocomplete from "#components/utils/Autocomplete";

const SkuBinMappings = ({
  displaySkuBinMappings,
  total,
  pageNumber,
  checkPagination,
  perPage,
  setSkuQrCode,
  setBinBarcode,
  binBarcode,
  skuQrCode,
  printBarcode,
  printQrCode,
  setSku,
  setBinLocation,
  getSpecificInventory,
  getUomBreakdown,
  fetchedInventory,
  fetchedUomBreakdown,
  setFetchedUomBreakdown,
  stockChangeType,
  submitFetchedInventory,
  submitUomBreakdown,
  updateUomBreakdown,
  setStockChangeType,
  setFetchedInventory,
  setSkuBinMap,
  skuDetails,
  binLocationDetails,
  skuBinMapDetails,
  submitSku,
  submitBinLocation,
  submitSkuBinMap,
  unmappedSkus,
  binLocations,
  setPerPage,
  deleteButtonClicked,
  sort,
  setSort,
  filters,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  clearFilters,
  getSku,
  fetchedSku,
  setFetchedSku,
  warehouses,
  customers,
  exportToCsv,
  showExportSkuBinMappingFieldSelector,
  setShowExportSkuBinMappingFieldSelector,
  skuBinMappingExportFields,
  onSubmitSkuBinMappingExportFields,
  onChangeSkuBinMappingExportField,
  onChangeFilter,
  showFilters,
  writable,
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  validate,
  validationResult,
  multiAccountSupportEnabled,
  marketplaces,
  sellerIds,
  appState,
  checkTransferSkuBinMapping,
  binTransferEligibility,
  setBinTransferEligibility,
}) => {
  return (
    <div className="p-5">
      <div className="flex items-center">
        <div className="flex-1">
          <PageTitle>SKU-Bin Management</PageTitle>
        </div>
        {writable && (
          <div className="flex items-center justify-end space-x-1">
            <AddButton
              text="Add SKU-Bin Map"
              onClick={() =>
                setSkuBinMap({
                  sku: "",
                  binLocation: "",
                  quantity: 0,
                })
              }
              icon={UserAddIcon}
              disabled={!writable}
            />
            <AddButton
              text="Download Template"
              onClick={() =>
                (window.location =
                  "https://templates-onboarding.s3.amazonaws.com/prod/Sku+Bin+Mappings.xlsx")
              }
            />

            <UploadBulk
              dashboardFields={dashboardFields}
              saveBulkUpload={saveBulkUpload}
              errorMessage={errorMessage}
              successMessage={successMessage}
              validate={validate}
              validationResult={validationResult}
            />

            <AddButton
              text="Export Report"
              onClick={exportToCsv}
              disabled={!writable}
            />
          </div>
        )}
      </div>

      <SkuBinMappingsList
        skuBinMappings={displaySkuBinMappings}
        headers={[
          "SKU",
          "Pallet ID",
          "Tote",
          "Bin Location",
          "Allocated Qty",
          "Unallocated Qty",
          "Created At",
          "Client",
          "Action",
        ]}
        noValuesText="No SKU-Bin Mappings"
        getSpecificInventory={getSpecificInventory}
        getUomBreakdown={getUomBreakdown}
        binBarcodeClicked={setBinBarcode}
        skuQrCodeClicked={setSkuQrCode}
        editButtonClicked={setSkuBinMap}
        deleteButtonClicked={deleteButtonClicked}
        onChangeSearchKeyword={onChangeSearchKeyword}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        sort={sort}
        setSort={setSort}
        filters={filters}
        getSku={getSku}
        customers={customers}
        warehouses={warehouses}
        writable={writable}
        onChangeFilter={onChangeFilter}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
        checkTransferSkuBinMapping={checkTransferSkuBinMapping}
        tenant={appState.tenant}
      />
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
      {fetchedSku && !fetchedUomBreakdown && (
        <ExpandedSku
          fetchedSku={fetchedSku}
          negativeAction={() => setFetchedSku(null)}
        />
      )}
      {skuDetails && (
        <SkuForm
          setSku={setSku}
          submitSku={submitSku}
          skuDetails={skuDetails}
        />
      )}
      {binLocationDetails && (
        <BinLocationForm
          setBinLocation={setBinLocation}
          submitBinLocation={submitBinLocation}
          binLocationDetails={binLocationDetails}
        />
      )}
      {skuBinMapDetails && (
        <SkuBinMapForm
          setSkuBinMap={setSkuBinMap}
          submitSkuBinMap={submitSkuBinMap}
          skuBinMapDetails={skuBinMapDetails}
          unmappedSkus={unmappedSkus}
          binLocations={binLocations}
          customers={customers}
          warehouses={warehouses}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          marketplaces={marketplaces}
          sellerIds={sellerIds}
          appState={appState}
        />
      )}
      {showFilters && (
        <SkuBinMappingFilters
          negativeAction={() => setShowFilters(false)}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          customers={customers}
          warehouses={warehouses}
        />
      )}

      {binBarcode && (
        <Modal
          negativeAction={() => setBinBarcode(null)}
          title={`Generate Barcode`}>
          <div className="space-y-4">
            <Autocomplete
              options={[{ name: "1D Barcode" }, { name: "2D Barcode" }]}
              labelKey="name"
              valueKey="name"
              onChange={(e) => {
                setBinBarcode({
                  ...binBarcode,
                  barcodeType: e,
                });
              }}
              value={binBarcode.barcodeType}
              placeholder={"Select Barcode Type"}
              id="barcode"
            />
            <TextField
              type="number"
              id="noOfCopies"
              label="Number of Copies"
              placeholder=" "
              onChange={(e) =>
                setBinBarcode({
                  ...binBarcode,
                  noOfCopies: e.target.value,
                })
              }
              value={binBarcode.noOfCopies}
              min="0"
            />
          </div>
          <div className="mt-2 flex items-center justify-center">
            {binBarcode.barcodeType === "1D Barcode" && (
              <Barcode value={binBarcode.binLocation} />
            )}
            {binBarcode.barcodeType === "2D Barcode" && (
              <QRCode value={binBarcode.binLocation} />
            )}
          </div>

          <div className="mt-5 flex items-center justify-center">
            <ModalButton onClick={printBarcode} text={"Print"} />
          </div>
        </Modal>
      )}
      {skuQrCode && (
        <Modal
          negativeAction={() => setSkuQrCode(null)}
          title={`Generate Barcode`}>
          <div className="space-y-4">
            <Autocomplete
              options={[{ name: "1D Barcode" }, { name: "2D Barcode" }]}
              labelKey="name"
              valueKey="name"
              onChange={(e) => {
                setSkuQrCode({
                  ...skuQrCode,
                  barcodeType: e,
                });
              }}
              value={skuQrCode.barcodeType}
              placeholder={"Select Barcode Type"}
              id="barcode_sku"
            />
            <TextField
              type="number"
              id="noOfCopies"
              label="Number of Copies"
              placeholder=" "
              onChange={(e) =>
                setSkuQrCode({
                  ...skuQrCode,
                  noOfCopies: e.target.value,
                })
              }
              value={skuQrCode.noOfCopies}
              min="0"
            />
          </div>
          <div className="mt-2 flex items-center justify-center">
            {skuQrCode.barcodeType === "1D Barcode" && (
              <Barcode value={skuQrCode.sku} />
            )}
            {skuQrCode.barcodeType === "2D Barcode" && (
              <QRCode value={skuQrCode.sku} />
            )}
          </div>
          <div className="mt-10 flex items-center justify-center">
            <ModalButton onClick={printQrCode} text={"Print"} />
          </div>
        </Modal>
      )}
      {showExportSkuBinMappingFieldSelector && (
        <Modal
          title={"Select Fields"}
          negativeAction={() => setShowExportSkuBinMappingFieldSelector(false)}
          positiveAction={onSubmitSkuBinMappingExportFields}>
          <div className="w-96">
            {skuBinMappingExportFields.map((item) => (
              <span className="flex flex-row justify-between">
                <label className="mt-4 w-1/2 text-left">{item.name}</label>
                <span className="w-1/2 text-center">
                  <Toggle
                    enabled={item.enabled}
                    disabled={!item.editable}
                    setEnabled={() =>
                      onChangeSkuBinMappingExportField(item.id, !item.enabled)
                    }
                  />
                </span>
              </span>
            ))}
          </div>
        </Modal>
      )}
      {fetchedInventory && (
        <EditReceivedSkuForm
          fetchedReceivedSku={fetchedInventory}
          stockChangeType={stockChangeType}
          onClose={() => setFetchedInventory(null)}
          onSubmit={submitFetchedInventory}
          onChange={(e) =>
            setFetchedInventory({
              ...fetchedInventory,
              [e.target.name]:
                e.target.type === "number"
                  ? Math.abs(parseInt(e.target.value))
                  : e.target.value,
            })
          }
          onChangeRadio={(e) => {
            setStockChangeType(e.target.value);
          }}
          onChangeDropdown={(field, value) => {
            setFetchedInventory({
              ...fetchedInventory,
              [field]: value,
            });
          }}
          title={`Stock Adjustment for ${fetchedInventory.sku}`}
        />
      )}

      {fetchedUomBreakdown && fetchedSku && (
        <UomBreakdownForm
          fetchedReceivedSku={fetchedUomBreakdown}
          onClose={() => {
            setFetchedUomBreakdown(null);
            setFetchedSku(null);
          }}
          onSubmit={submitUomBreakdown}
          updateUomBreakdown={updateUomBreakdown}
          onChange={(e) =>
            setFetchedUomBreakdown({
              ...fetchedUomBreakdown,
              [e.target.name]:
                e.target.type === "number"
                  ? parseInt(e.target.value)
                  : e.target.value,
            })
          }
          onChangeDropdown={(field, value) => {
            setFetchedUomBreakdown({
              ...fetchedUomBreakdown,
              [field]: value,
            });
          }}
          title="UOM Breakdown"
          fetchedSku={fetchedSku}
        />
      )}
      {binTransferEligibility && (
        <BinTransferEligibilityModal
          binTransferEligibility={binTransferEligibility}
          onClose={() => setBinTransferEligibility(null)}
          onSuccess={() => {
            setBinTransferEligibility(null);
            // RELOAD THE DATA
            submitFilters();
          }}
          checkTransferSkuBinMapping={checkTransferSkuBinMapping}
        />
      )}
    </div>
  );
};

const SkuForm = ({ skuDetails, setSku, submitSku }) => {
  return (
    <Modal
      negativeAction={() => setSku(null)}
      title={`Add SKU`}
      positiveAction={submitSku}>
      <div>
        <TextField
          type="text"
          id="sku"
          label="SKU"
          placeholder="SKU"
          onChange={(e) =>
            setSku({
              ...skuDetails,
              sku: e.target.value,
            })
          }
          value={skuDetails.sku}
        />
      </div>
    </Modal>
  );
};

const BinLocationForm = ({
  setBinLocation,
  submitBinLocation,
  binLocationDetails,
}) => {
  return (
    <Modal
      negativeAction={() => setBinLocation(null)}
      title={`Add Bin Location`}
      positiveAction={submitBinLocation}>
      <div>
        <TextField
          type="text"
          id="sku"
          label="Bin Location"
          placeholder=" "
          onChange={(e) =>
            setBinLocation({
              ...binLocationDetails,
              binLocation: e.target.value,
            })
          }
          value={binLocationDetails.binLocation}
        />
      </div>
    </Modal>
  );
};

const SkuBinMapForm = ({
  setSkuBinMap,
  submitSkuBinMap,
  skuBinMapDetails,
  customers,
  warehouses,
  multiAccountSupportEnabled,
  marketplaces,
  sellerIds,
  appState,
}) => {
  return (
    <Modal
      negativeAction={() => setSkuBinMap(null)}
      title={`Add SKU-Location Map`}
      positiveAction={submitSkuBinMap}>
      <div className="mt-3 flex-1">
        <div>
          <div id="selectingStations">
            <Autocomplete
              options={warehouses}
              labelKey="name"
              valueKey="id"
              onChange={(selectedOption) => {
                setSkuBinMap({
                  ...skuBinMapDetails,
                  warehouse: selectedOption,
                });
              }}
              value={skuBinMapDetails.warehouse}
              disabled={skuBinMapDetails.id ? true : false}
              placeholder={"Select Warehouse"}
              id="warehouse"
            />
          </div>
        </div>
        <div className="mt-3 flex-1">
          <div id="selectingStations">
            <Autocomplete
              options={getFilteredCustomerList(
                skuBinMapDetails.warehouse,
                customers,
              )}
              labelKey="name"
              valueKey="id"
              onChange={(selectedOption) => {
                setSkuBinMap({
                  ...skuBinMapDetails,
                  customer: selectedOption,
                });
              }}
              value={skuBinMapDetails.customer}
              placeholder={"Select Client"}
              id="customer"
              disabled={skuBinMapDetails.id ? true : false}
            />
          </div>
        </div>
        {multiAccountSupportEnabled && marketplaces && !skuBinMapDetails.id && (
          <div className="mt-3 flex-1">
            <div id="selectingStations">
              <Autocomplete
                options={marketplaces.map((marketplace) => ({
                  label: marketplace,
                  value: marketplace,
                }))}
                labelKey="label"
                valueKey="value"
                onChange={(selectedOption) => {
                  setSkuBinMap({
                    ...skuBinMapDetails,
                    marketplace: selectedOption,
                  });
                }}
                value={skuBinMapDetails.marketplace}
                placeholder={"Select Marketplace"}
                id="marketplace"
              />
            </div>
          </div>
        )}
        {multiAccountSupportEnabled && sellerIds && !skuBinMapDetails.id && (
          <div className="mt-3 flex-1">
            <div id="selectingStations">
              <Autocomplete
                options={sellerIds.map((sellerId) => ({
                  label: sellerId,
                  value: sellerId,
                }))}
                labelKey="label"
                valueKey="value"
                onChange={(selectedOption) => {
                  setSkuBinMap({
                    ...skuBinMapDetails,
                    sellerId: selectedOption,
                  });
                }}
                value={skuBinMapDetails.sellerId}
                placeholder={"Select Seller"}
                id="sellerId"
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-3 flex-1">
        <TextField
          type="text"
          id="sku"
          label="SKU"
          placeholder=" "
          onChange={(e) =>
            setSkuBinMap({
              ...skuBinMapDetails,
              sku: e.target.value,
            })
          }
          value={skuBinMapDetails.sku}
          disabled={skuBinMapDetails.id}
        />
      </div>
      {!skuBinMapDetails.id && (
        <div className="mt-3 flex-1">
          <div>
            <TextField
              type="number"
              id="quantity"
              label="Quantity"
              placeholder=" "
              onChange={(e) =>
                setSkuBinMap({
                  ...skuBinMapDetails,
                  unallocatedQuantity:
                    isNaN(e.target.value) === false &&
                    parseInt(e.target.value) > 0
                      ? parseInt(e.target.value)
                      : null,
                })
              }
              value={skuBinMapDetails.unallocatedQuantity}
              min="0"
            />
          </div>
        </div>
      )}
      <div className="mt-3 flex-1">
        <div>
          <Autocomplete
            options={appState.masterData.uomLabels?.filter(
              (label) => label.isActive,
            )}
            labelKey="name"
            valueKey="name"
            onChange={(e) =>
              setSkuBinMap({
                ...skuBinMapDetails,
                formFactor: e,
              })
            }
            value={skuBinMapDetails.formFactor}
            placeholder={"Form Factor"}
            id="formFactor"
          />
        </div>
      </div>
      <div className="mt-3 flex-1">
        <div>
          <TextField
            type="text"
            id="tote"
            label="Tote/Carton ID"
            placeholder=" "
            onChange={(e) =>
              setSkuBinMap({
                ...skuBinMapDetails,
                tote: e.target.value,
              })
            }
            value={skuBinMapDetails.tote}
          />
        </div>
      </div>
      <div className="mt-3 flex-1">
        <div>
          <TextField
            type="text"
            id="palletId"
            label="Pallet ID"
            placeholder="Pallet ID"
            onChange={(e) =>
              setSkuBinMap({
                ...skuBinMapDetails,
                palletId: e.target.value,
              })
            }
            value={skuBinMapDetails.palletId}
          />
        </div>
      </div>
      <div className="mt-3 flex-1">
        <div>
          <TextField
            type="text"
            id="binLocation"
            label="Bin Location"
            placeholder="Bin Location"
            onChange={(e) =>
              setSkuBinMap({
                ...skuBinMapDetails,
                binLocation: e.target.value,
              })
            }
            value={skuBinMapDetails.binLocation}
            disabled={skuBinMapDetails.id}
          />
        </div>
        <div className="mt-3 flex-1">
          <div>
            <TextField
              type="text"
              id="lotId"
              label="Lot ID/Batch ID"
              placeholder=" "
              onChange={(e) =>
                setSkuBinMap({
                  ...skuBinMapDetails,
                  lotId: e.target.value,
                })
              }
              value={skuBinMapDetails.lotId}
            />
          </div>
        </div>
        <div className="mt-3 flex-1">
          <div>
            <TextField
              type="text"
              id="serialNumber"
              label="Serial Number"
              placeholder=" "
              onChange={(e) =>
                setSkuBinMap({
                  ...skuBinMapDetails,
                  serialNumber: e.target.value,
                })
              }
              value={skuBinMapDetails.serialNumber}
            />
          </div>
        </div>
      </div>

      <div className="mt-3 flex-1">
        <label className="mb-2 block text-left">
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            LPN Details
          </span>
        </label>
        <div>
          <TextField
            type="text"
            id="lpn"
            placeholder={"LPN"}
            label="LPN"
            onChange={(e) =>
              setSkuBinMap({
                ...skuBinMapDetails,
                nestedFormFactorId: e.target.value,
              })
            }
            value={skuBinMapDetails.nestedFormFactorId}
          />
        </div>
        <div>
          <Autocomplete
            options={appState.masterData.uomLabels.filter(
              (label) => label.isActive,
            )}
            labelKey="name"
            valueKey="name"
            onChange={(e) =>
              setSkuBinMap({
                ...skuBinMapDetails,
                nestedFormFactor: e,
              })
            }
            value={skuBinMapDetails.nestedFormFactor}
            placeholder={"LPN Form Factor"}
            id="nestedFormFactor"
          />
        </div>
      </div>

      <div className="mt-3 flex flex-col">
        <label className="mb-2 block text-left">
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Expiry Date
          </span>
        </label>
        <div>
          <input
            type="date"
            onChange={(e) =>
              setSkuBinMap({
                ...skuBinMapDetails,
                bestByDate: e.target.value,
              })
            }
            placeholder="Select Expiry date"
            value={skuBinMapDetails.bestByDate}
            className="rounded-md p-4 text-lg"
            min={moment().format("YYYY-MM-DD")}
          />
        </div>
      </div>
    </Modal>
  );
};

export default withSkuBinMappingsLogic(SkuBinMappings);
