import OrderPicker from "./orderPicker";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";
import { Link } from "react-router-dom";
import { useFeatureFlags } from "#contexts/featureFlags";

const Picker = () => {
  const { isBundlingWorkflowEnabled } = useFeatureFlags();

  if (isBundlingWorkflowEnabled) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="flex flex-col items-center justify-center py-10">
          <Link to="/picker/order">
            <div className="w-72 rounded-md bg-2C7695 py-4 text-center text-2xl text-white">
              Pick for Order
            </div>
          </Link>
          <Link to="/picker/bundle">
            <div className="mt-10 w-72 rounded-md bg-2C7695 py-4 text-center text-2xl text-white">
              Pick for Bundle
            </div>
          </Link>
        </div>
      </div>
    );
  }

  // If bundling workflow is not enable just return order picking as the default.
  return <OrderPicker />;
};

export default Picker;
